import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import BuyingJourneyStart from './BuyingJourneyStart';
import BuyingJourneyStartTest from './BuyingJourneyTest';
import BuyingJourneyTata from './BuyingJourneyTata';
import BuyingJourneyPlans from './BuyingJourneyPlans';
import BuyingJourneyOrderSummary from './BuyingJourneyOrderSummary';
import BuyingJourneyPreOrderSummary from './PreOrderSummary';
import BuyingJourneySuccess from './BuyingJourneySuccess';
const BuyingJourneyNavigator = ({ loginState, authToken, history }) => {
  // if (loginState !== 'LOGGED_IN' || !authToken) {
  //   history.push('/');
  //   return false;
  // }
  return (
    <Switch>
      <Route exact path="/buying-journey" component={BuyingJourneyStart} />
      <Route
        exact
        path="/buying-journey/tata-capital"
        component={BuyingJourneyTata}
      />
      <Route
        exact
        path="/buying-journey/test"
        component={BuyingJourneyStartTest}
      />
      <Route
        exact
        path="/buying-journey/plans"
        component={BuyingJourneyPlans}
      />
      <Route
        exact
        path="/buying-journey/preorder-summary"
        component={BuyingJourneyPreOrderSummary}
      />
      <Route
        exact
        path="/buying-journey/order-summary"
        component={BuyingJourneyOrderSummary}
      />
      <Route
        exact
        path="/buyingjourney-test"
        component={BuyingJourneyStartTest}
      />
      <Route
        exact
        path="/buying-journey/success"
        component={BuyingJourneySuccess}
      />

      <Redirect to="/buying-journey" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(BuyingJourneyNavigator);

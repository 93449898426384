export default function randomString(length) {
  let result = null;
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = length; i > 0; i -= 1) {
    if (!result) {
      result = chars[Math.floor(Math.random() * chars.length)];
    } else {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
  }
  return result;
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Carousel from '../common/Carousel';

import StyledTappable from '../common/StyledTappable';
import CardContainer from '../common/CardContainer';

const OuterTappable = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  margin: 0.4rem 0.5rem;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  align-self: stretch;
  overflow-x: hidden;
`;

const BannerImageContainer = styled(CardContainer)`
  width: ${props => props.width};
  flex-shrink: 0;
  margin: 0 0.25rem;
  padding: 0.5rem;
`;

const BannerImage = styled.img`
  width: 100%;
`;

const SubscriptionCard = ({ onTap, alternatives }) => (
  <OuterTappable onTap={onTap}>
    <OuterContainer>
      <CarouselContainer>
        <Carousel autoplay delay={5000} showDots>
          {alternatives.map((ele, idx) => (
            <BannerImageContainer key={idx} width="90%">
              <BannerImage src={ele} />
            </BannerImageContainer>
          ))}
        </Carousel>
      </CarouselContainer>
    </OuterContainer>
  </OuterTappable>
);

SubscriptionCard.propTypes = {
  onTap: PropTypes.func,
};

export default SubscriptionCard;

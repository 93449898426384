import axios from 'axios';
import { showError } from '../utils';

export function uploadFileToBotConversation(
  url,
  requestData,
  onUploadProgress,
  authToken,
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(url, requestData, {
      headers,
      onUploadProgress,
    })
    .then(resp => {
      const responseBody = resp.data;
      if (responseBody.message === 'Success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function getAutocompleteSuggestions(url, val, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${url}${val}`, {
      headers,
    })
    .then(res => {
      const responseBody = res.data;
      if (responseBody.message === 'noResultsFound') {
        return {
          suggestions: [],
        };
      }
      if (responseBody.suggestions && Array.isArray(responseBody.suggestions)) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

export function dashboardApi(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/console/pwa`, {
      headers,
      params: {
        v: config.version,
      },
    })
    .then(resp => {
      const responseBody = resp.data;
      const responseCode = resp.status;
      if (responseCode === 200) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function consultationTab(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/console/care`, {
      headers,
      params: {
        v: config.version,
      },
    })
    .then(resp => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function homeTab(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/console/home`, {
      headers,
      params: {
        v: config.version,
      },
    })
    .then(resp => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}


export function endChat(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/users/chat/close?init=0`, {
      headers,
    })
    .then(resp => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      throw err;
    });
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text } from '../../components/common/Text';

import visitLogo from '../../images/common/visitlogo.png';
import { loginWithToken } from '../../actions';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Logo = styled.img`
  width: 60px;
  margin-bottom: 12px;
`;

class AuthTokenContainer extends Component {
  componentDidMount() {
    const { location, history, dispatch } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('authToken')) {
      dispatch(
        loginWithToken(searchParams.get('authToken'), location.search, history)
      );
    } else {
      history.push({
        pathname: '/login',
        search: location.search,
      });
    }
  }

  render() {
    return (
      <OuterContainer>
        <Logo src={visitLogo} />
        <Text>Loading, Please wait...</Text>
      </OuterContainer>
    );
  }
}

export default connect()(AuthTokenContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

import { FadeInTransition } from '../../components/transitions/transitions';
import arrowLeft from '../../images/common/arrow-left.svg';
import StyledTappable from '../../components/common/StyledTappable';
import GeneralPhysician from '../../images/home/General_Physician.png';
import { getCitiesListForIsolationCenters as getCitiesListForIsolationCentersService } from '../../services/lab-test';
import LoadingComponent from '../../components/common/LoadingComponent';
import LocationIcon from '../../images/home/Location.svg';
import ClockIcon from '../../images/consultations/new-flow/Check cr-fr.svg';
import UserIcon from '../../images/consultations/new-flow/UserIcon.svg';
import { getAllPreviousBookings as getAllPreviousBookingsService } from '../../services';

const IssueContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  padding: 17px 9px 17px 12px;
  min-height: 46px;
  border-bottom: 1px solid RGBA(244, 246, 249, 1);
`;

const IssueTextContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  padding:4px;
`;

const IssueIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 14px;
`;

const SuggestionIcon = styled(IssueIcon)`
  width: 36px;
  height: 36px;
  border-radius: 18px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-bottom: 0.25rem;
  margin-right: 20px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: visible;
  background: white;
`;

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: visible;
  background: #ffffff;
`;

const DoctorArrow = styled(FaChevronRight)`
  color: #0f0b28;
  float: right;
  height: 12px;
  margin-bottom: 12px;
`;

const FeesIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

class CitiesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centers: [],
            selectedCity: null,
            loading: false,
            previousBookings: null
        }
    }

    componentDidMount = () => {
        const { authToken } = this.props;
        this.getAllPreviousBookings(authToken)
        this.getCitiesListForIsolationCenters(authToken)
    }

    fetchLocation = () => {
        this.setState({ loading: true })
        const options = {
            timeout: 15000,
        };
        const failureCallback = (err) => {
            const { history } = this.props;
            const { selectedCity } = this.state;
            history.push('/lab-test/available-center', {
                selectedCity
            });
            console.log("failure", err);
        };
        const successCallback = (pos) => {
            const { history } = this.props;
            const { selectedCity } = this.state;
            const { latitude, longitude } = pos.coords;

            console.log("success", latitude, longitude)
            history.push('/lab-test/available-center', {
                latitude,
                longitude,
                selectedCity
            });
        };
        navigator.geolocation.getCurrentPosition(
            successCallback,
            failureCallback,
            options
        );
    }

    getCitiesListForIsolationCenters = (authToken) => {
        getCitiesListForIsolationCentersService(authToken)
            .then((res) => {
                this.setState({ centers: res.data })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    getAllPreviousBookings = (authToken) => {
        getAllPreviousBookingsService(authToken)
            .then((res) => {
                this.setState({ previousBookings: res.data })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    openFinalStatusPage = (ele) => {
        // const selectedCenter = ele;
        const { history } = this.props;
        if (ele.bookingStatus === 'request-received') {
            history.push('/lab-test/booking-status', {
                selectedCenter: ele,
                requestReceiveDate: ele.bookingTimeDate
            })
        }
        if (ele.bookingStatus === 'booking-confirmed') {
            history.push('/lab-test/center-confirm', {
                confirmationDetails: ele
            })
        }
    }

    selectVertical(ele) {
        this.setState({ selectedCity: ele.center_city })
        //this.fetchLocation()
        const { history } = this.props;

        history.push('/lab-test/available-center', {
            selectedCity :ele.center_city
        });
    
    }

    render() {
        const { previousBookings, centers } = this.state;
        console.log("states", previousBookings, centers)

        const previousBookingsCard = (ele) => {
            return (
                <div className="ml-16px mr-16px">
                    <div   
                        onClick={() => { this.openFinalStatusPage(ele) }}
                        className="order-status profile-padding"
                        style={{
                            backgroundColor: '#ffffff',
                            width: '300px',
                            alignSelf: 'stretch',
                            marginTop: '20px',
                            border: '1px solid #E3E3E3',
                        }}
                        key={ele.requestId}
                    >
                        <div className="flex-center pb-5 justify-between"
                            style={{ borderBottom: '1px solid #F0F3F6' }}>
                            <div className='flex flex-row'>
                                <ProfileImage src={LocationIcon} className="profile-image" />
                                <div className="txt-black-600-16">{ele.center_name}</div>
                            </div>
                            <div>
                                <DoctorArrow />
                            </div>
                        </div>
                        <div className='flex flex-row pt-4 pb-3'>
                            <FeesIcon src={UserIcon} />
                            <div className='txt-black-600-12'>{ele.userName}</div>
                        </div>
                        <div className='flex flex-row mt-4'>
                            <FeesIcon src={ClockIcon} />
                            <div className='txt-black-600-12'>{ele.bookedFrom} - {ele.bookedTill}</div>
                        </div>


                    </div>
                </div>
            )
        }

        const renderDefaults = (ele) => (
            <IssueContainer
                //key={ele.verticalId}
                onTap={() => this.selectVertical(ele)}
            >
                <SuggestionIcon src={ele.city_images} />
                <IssueTextContainer>
                    {ele.center_city && (
                        <div className="txt-black-600-14">{ele.center_city}</div>
                    )}
                </IssueTextContainer>
            </IssueContainer>
        );

        return (
            <>
                {this.state.loading ? (
                    <LoadingComponent />
                ) : (
                    <FadeInTransition
                        in={true}
                        timeout={200}
                        mountOnEnter
                        unmountOnExit
                        appear
                    >
                        <OuterContainer>
                            <div className="header p-16px pt-0 mt-48px border-b border-txtsecondary-shades4 flex flex-row items-center justify-between">
                                <div className="flex flex-row items-center">
                                    <img
                                        src={arrowLeft}
                                        alt="back"
                                        onClick={() => {
                                            this.props.history.goBack();
                                        }}
                                    />
                                    <p className="text-title-m font-semibold text-txtlight">
                                        Book Isolation Center
                                    </p>
                                </div>
                            </div>
                            <BodyContainer>
                                {this.state.previousBookings && this.state.previousBookings.length > 0 && (
                                    <>
                                        <div className='txt-grey-500-12 ml-16px mt-16px'>YOUR PREVIOUS REQUESTS</div>
                                        <div className="flex overflow-x-auto justify-start">
                                            {this.state.previousBookings.map(previousBookingsCard)}
                                        </div>
                                    </>
                                )}
                                    <div className='txt-grey-500-12 ml-16px mt-16px'>AVAILABLE CITIES</div>
                                {this.state.centers.map(renderDefaults)}

                            </BodyContainer>
                        </OuterContainer>
                    </FadeInTransition>
                )}
            </>

        )
    }
}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    userId: state.user.userId,
    sponsorId: state.user.sponsorId,
});

export default connect(mapStateToProps)(CitiesList);


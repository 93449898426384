export const defaultState = {
  consultationId: null,
  chatMessages: [],
  doctorTyping: false,
  doctorTypingId: null,
  chatChannel: null,
  chatName: null,
  chatIcon: null,
  chatSubtitle: null,
  receiverId: null,
  consultationSummary: null,
};

export default function chat(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CHAT_INFO':
      return Object.assign({}, state, {
        consultationId: action.payload.consultationId || state.consultationId,
        chatChannel: action.payload.chatChannel || state.chatChannel,
        chatName: action.payload.chatName || state.chatName,
        chatIcon: action.payload.chatIcon || state.chatIcon,
        chatSubtitle: action.payload.chatSubtitle || state.chatSubtitle,
        receiverId: action.payload.receiverId || state.receiverId,
      });
    case 'SET_CHAT_MESSAGES':
      let chatMessages = [...action.payload.chatMessages];
      chatMessages = chatMessages.filter(
        (message, index, self) =>
          index === self.findIndex((t) => t.messageId === message.messageId)
      );
      return Object.assign({}, state, {
        chatMessages: chatMessages,
      });
    case 'ADD_CHAT_MESSAGE':
      let chatMessages1 = [
        ...state.chatMessages.concat([action.payload.chatMessage]),
      ];
      chatMessages1 = chatMessages1.filter(
        (message, index, self) =>
          index === self.findIndex((t) => t.messageId === message.messageId)
      );

      return Object.assign({}, state, {
        chatMessages: chatMessages1,
      });
    case 'RESET_CHAT':
      return Object.assign({}, state, defaultState);
    case 'SET_CONSULTATION_SUMMARY':
      return Object.assign({}, state, {
        consultationSummary: action.payload,
      });
    case 'SET_DOCTOR_TYPING':
      return Object.assign({}, state, {
        doctorTyping: action.payload.typing,
        doctorTypingId: action.payload.doctorId,
      });
    default:
      return state;
  }
}

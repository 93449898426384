import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft, FaChevronRight } from 'react-icons/fa';

import Tappable from 'react-tappable/lib/Tappable';

import { fetchClaims, getAllRelatives } from '../../services';
import { FadeInTransition } from '../../components/transitions/transitions';
import ConsultationUser from '../../components/consultations/ConsultationUser';
import ClaimsStatusCard from '../../components/claims/ClaimsStatusCard';
import { Text } from '../../components/common/Text';
import RelativeSelect from '../../components/consultations/RelativeSelect';
import { logEvent } from '../../utils/logEvent';
import HospitalizationModal from '../../containers/common/HospitalizationModal';
import Modal from '../../containers/common/Modal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
`;

const BackContainer = styled(Tappable)`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 43px;
`;

const HospitalizationModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;


class ClaimsStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verticals: [],
      showVerticalsLeftScroll: false,
      showVerticalsRightScroll: true,
      ongoingClaims: [],
      completedClaims: [],
      cards: [],
      relatives: [],
      selectedRelative: null,
      showRelativeModal: false,
      showHospitalizationModal: false,
      showPendingHospilationBlocker: false,
    };
    this.verticalScrollContainerRef = React.createRef();
  }

  componentDidMount() {
    this.getClaims('all');
    this.getAllRelatives();
    logEvent('Claims Home Screen');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.hash === '#profile' &&
      this.props.location.hash !== '#profile'
    ) {
      this.closeProfile();
      return;
    }

    if (
      prevState.selectedRelative &&
      !this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative
    ) {
      this.getClaims('all');
    }
    if (
      this.state.selectedRelative &&
      this.state.selectedRelative !== prevState.selectedRelative &&
      !this.state.loading
    ) {
      this.getClaims();
    }
  }

  openOrder(order) {
    console.log(order);
    const { claimId } = order.claimInfo;
    const { history } = this.props;
    history.push('/claims/summary', {
      claimId,
      goBack: true,
    });
  }

  getAllRelatives = () => {
    const { authToken, user } = this.props;
    getAllRelatives(authToken)
      .then((res) => {
        const self = {
          age: user.userAge,
          dob: user.dateOfBirth,
          gender: user.userGender,
          id: 'self',
          name: user.userName,
          phone: user.userPhone,
          relation: user.userType,
        };
        const relatives = [self, ...res.relatives];
        this.setState({
          relatives,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getClaims(id = null) {
    const { authToken, location, history } = this.props;

    this.setState({
      loading: true,
    });
    if (!id) {
      id =
        this.state.selectedRelative && this.state.selectedRelative.id
          ? this.state.selectedRelative.id
          : 'all';
    }
    fetchClaims(id, authToken)
      .then((res) => {
        const cards = res.cards;
        const ongoingClaims = res.claims.filter((claim) => {
          return claim.claimInfo.status.ongoing === true;
        });
        const completedClaims = res.claims.filter((claim) => {
          return claim.claimInfo.status.ongoing === false;
        });

        if (res.claims.length === 0 && this.state.selectedRelative) {
          this.setState({ selectedRelative: null });
        }
        this.setState({
          completedClaims,
          ongoingClaims,
          cards,
        });

        if (location && location.search) {
          console.log(location.search);
          const params = new URLSearchParams(location.search);
          console.log(params.get('requestId'));
          if (params && params.get('requestId')) {
            const requestId = params.get('requestId');
            const claimInfo = res.claims.filter((claim) => {
              return claim.claimInfo.claimId == requestId;
            });
            if (claimInfo && claimInfo.length > 0) {
              history.push('/claims/summary', {
                claimId: requestId,
                goBack: false,
              });
            } else {
              history.push('/home');
            }
          }
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  hideModal = () => {
    this.setState({
      showRelativeModal: false,
      showHospitalizationModal: false,
    });
  };

  selectRelative = (ele) => {
    this.setState({
      selectedRelative: ele,
    });
  };

  onCardClick = (card) => {
    const { history } = this.props;
    if (card && card.cardType === 'hospitalisation') {
      this.showHospitalization();
    } else {
      if (card.fulfillmentDetailsAvailable) {
        history.push('/reimbursement/preview');
      } else {
        history.push('/reimbursement/fulfillment-details', {
          fulfillmentDetailsAvailable: card.fulfillmentDetailsAvailable,
        });
      }
    }
  };

  showHospitalization(ele) {
    this.setState({
      showHospitalizationModal: true,
      showPendingHospilationBlocker: true,
    });
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return null;
    }
    this.setState({ showPendingHospilationBlocker: false });
  };

  openGmcOrder(order) {
    console.log(order);
    const { claimId } = order.claimInfo;
    const { history } = this.props;
    history.push('/hospitalization/claim-reimbursement-status', {
      claimId,
      from: 'claims'
    });
  }

  render() {
    const {
      selectedRelative,
      cards,
      ongoingClaims,
      completedClaims,
      showRelativeModal,
      relatives,
      showHospitalizationModal,
      showPendingHospilationBlocker,
    } = this.state;
    const { history } = this.props;

    const ConsultationCard = (ele) => {
      const type = ele.cardType;
      switch (type) {
        case 'reimbursement-claim':
          return (
            <ClaimsStatusCard
              key={ele.requestId}
              orderRequest={ele}
              onTap={() => this.openOrder(ele)}
            />
          );
        case 'gmc-claim':
          return (
            <ClaimsStatusCard
              key={ele.requestId}
              orderRequest={ele}
              onTap={() => this.openGmcOrder(ele)}
            />
          );
        default:
          return '';
      }
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <BackContainer onTap={() => history.goBack()}>
              <StyledArrow />
            </BackContainer>
            <HeaderTitleContainer>
              <HeaderText>My Claims </HeaderText>
              <ConsultationUser
                // icon={selectedRelative.relativeIcon}
                text={selectedRelative ? selectedRelative.relation : 'All'}
                onTap={() => {
                  this.setState({
                    showRelativeModal: true,
                  });
                }}
              />
            </HeaderTitleContainer>
          </HeaderContainer>
          <BodyContainer>
            {cards.map((card, idx) => {
              return (
                <div
                  className="white-box flex-row-center-end mb-5"
                  key={idx}
                  onClick={() => {
                    this.onCardClick(card);
                  }}
                >
                  <div>
                    <span className="txt-black-600-14">{card.label}</span>
                    <br />
                    <span className="txt-dgrey-500-11">{card.description}</span>
                  </div>
                  <div className="h-100">
                    <span
                      className="vertical-line"
                      style={{ borderColor: '#4f34d2', opacity: '0.5' }}
                    />
                    <FaChevronRight />
                  </div>
                </div>
              );
            })}
            {ongoingClaims.length > 0 && (
              <div>
                <div className="text-title-s font-semibold p-64px text-txtlight mb-8 mt-8">
                  Ongoing
                </div>
                {ongoingClaims.map(ConsultationCard)}
              </div>
            )}

            {completedClaims.length > 0 && (
              <div>
                <div className="text-title-s font-semibold p-64px text-txtlight mb-8 mt-8">
                  Completed
                </div>
                {completedClaims.map(ConsultationCard)}
              </div>
            )}
            {showRelativeModal && (
              <RelativeSelect
                relatives={relatives}
                hideModal={this.hideModal}
                selectedRelative={selectedRelative}
                selectRelative={this.selectRelative}
              />
            )}
          </BodyContainer>
          {showHospitalizationModal && (
            <Modal>
              <HospitalizationModalContainer onClick={this.handleOutsideClick}>
                <FadeInTransition
                  in={showPendingHospilationBlocker}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <div
                    ref={(node) => (this.node = node)}
                    className="bg-textbox flex flex-col items-center justify-center rounded-12"
                    style={{ width: '90%', maxWidth: '400px' }}
                  >
                    <HospitalizationModal
                      title={'Hospitalization'}
                      method1={'Claim Reimbursement'}
                      tag1={'Get reimbursed for your previous hospitalisation'}
                      method2={'Cashless Initmation Request'}
                      tag2={'Go cashless for your treatment'}
                      history={this.props.history}
                    />
                  </div>
                </FadeInTransition>
              </HospitalizationModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  sponsorId: state.user.sponsorId,
  user: state.user,
});

export default connect(mapStateToProps)(ClaimsStart);

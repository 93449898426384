const initializeFreshDesk = (user) => {
  if (!user) {
    return;
  }
  console.log('Open Freshchat');
  const initFreshChat = () => {
    window.fcWidget.init({
      config: {
        cssNames: {
          widget: 'fc_frame_custom_widget',
        },
        showFAQOnOpen: false,
        hideFAQ: true,
      },
      token: 'a3138f46-af50-4eca-a681-90c747950123',
      host: 'https://wchat.freshchat.com',
    });
    if (user.name) {
      window.fcWidget.user.setProperties({
        name: user.userName,
        phoneNo: user.userPhone,
        sponsorId: user.sponsorId,
        sponsorName: user.sponsorName,
      });
    }
    window.fcWidget.open();
  };
  return initFreshChat();
};
export default initializeFreshDesk;

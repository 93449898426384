import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { verifyCorporate, confirmEmail } from '../../services/index';
import StyledTappable from '../../components/common/StyledTappable';
import {
  BoldText,
  ButtonText,
  RegularText,
} from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import Modal from '../../containers/common/Modal';

import corporateIcon from '../../images/onboarding/corporate-icon.svg';
import { updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
`;

const InputContainer = styled.div`
  padding: 0.33rem 0;
  border-bottom: 1px solid black;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.875rem 0 1.25rem 0;
`;

const SecondaryButton = styled(StyledTappable)`
  margin-top: 1rem;
`;

const SecondaryText = styled(BoldText)`
  color: #999;
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

class OnboardingCorporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identifierText: '',
      loading: false,
      modalVisible: false,
      showContinueButton: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    logEvent('Onboarding Enter Email Screen');
    logEvent('Onboarding Sponsored User Policy Details Screen');
  }

  submitCorporateIdentity = () => {
    this.setState({
      loading: true,
    });
    const { identifierText } = this.state;
    const { history, authToken, dispatch } = this.props;
    verifyCorporate(identifierText, authToken)
      .then((res) => {
        dispatch(updateLoginState('ONE_LAST_STEP'));
        if (res.message === 'ssoAuthentication') {
          // Redirect the user to the SSO flow to complete authentication
          window.location.href = res.ssoLoginUrl;
        } else {
          history.push('/corporate-activated', {
            sponsorImage: res.sponsorImage,
            sponsorName: res.sponsorName,
          });
        }
      })
      .catch((err) => {
        const errorResponse = err.data;
        console.log(err, errorResponse);
        switch (errorResponse.message) {
          case 'emailNotFound':
            this.setState({
              modalVisible: true,
              showContinueButton: true,
              errorMessage:
                'We didn’t find any organization offering visit benefits with that email ID.  Continue signing up?',
            });
            break;
          case 'emailTaken':
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage:
                'This email is already registered with us. Please login to your existing account, or use another email.',
            });
            break;
          case 'invalidEmail':
            this.setState({
              modalVisible: true,
              showContinueButton: true,
              errorMessage:
                'We didn’t find any organization offering visit benefits with that email ID.  Continue signing up?',
            });
            break;
          default:
            break;
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  continueSignup = () => {
    const { identifierText } = this.state;
    const { authToken, history, dispatch } = this.props;
    this.setState({
      modalVisible: false,
      loading: true,
    });
    confirmEmail(identifierText, authToken)
      .then(() => {
        logEvent('Onboarding Signup Screen');
        dispatch(updateLoginState('ONE_LAST_STEP'));
        history.push('/one-last-step');
      })
      .catch((err) => {
        const errorResponse = err.data;
        console.log(err, errorResponse);
        switch (errorResponse.message) {
          case 'invalidEmail':
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage: 'Please enter a valid email address',
            });
            break;
          default:
            this.setState({
              modalVisible: true,
              showContinueButton: false,
              errorMessage: 'Please enter a valid email address',
            });
            break;
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const identifierTextLink = Link.state(this, 'identifierText');
    const { modalVisible, errorMessage, showContinueButton } = this.state;
    const { history } = this.props;
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <StyledTappable onTap={() => history.goBack()}>
              <FaArrowLeft />
            </StyledTappable>
            <ScreenIcon src={corporateIcon} alt="Organization" />
          </HeaderContainer>
          <TitleText>
            Enter your organization email or identification number
          </TitleText>
          <InputContainer>
            <StyledInputBox
              type="text"
              className="visit-input"
              valueLink={identifierTextLink}
              placeholder="Your Email/Membership Number"
            />
          </InputContainer>
          <PrimaryButton
            onTap={this.submitCorporateIdentity}
            disabled={this.state.identifierText.length < 1}
            loading={this.state.loading}
          >
            <StyledButtonText>continue</StyledButtonText>
          </PrimaryButton>
          {modalVisible && (
            <Modal>
              <ModalContainer>
                <ModalBody>
                  <BoldText>Invalid Email ID</BoldText>
                  <ModalText>{errorMessage}</ModalText>
                  {showContinueButton && (
                    <PrimaryButton onTap={this.continueSignup}>
                      <ButtonText>CONTINUE SIGNUP</ButtonText>
                    </PrimaryButton>
                  )}
                  <SecondaryButton onTap={() => history.goBack()}>
                    <SecondaryText>GO BACK</SecondaryText>
                  </SecondaryButton>
                </ModalBody>
              </ModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(OnboardingCorporate);

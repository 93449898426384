import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import { FadeInTransition } from '../../components/transitions/transitions';
import styled from 'styled-components';
import checkedIcon from '../../images/buying-journey/success-transaction.png';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export class PaymentSuccessModal extends Component {
  state = {
    showUploadOption: true,
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };
  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  render() {
    const { showUploadOption } = this.state;
    return (
      <Modal>
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={showUploadOption}
            mountOnEnter
            timeout={200}
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox rounded-12 pt-100px pl-52px pr-52px"
              ref={(node) => (this.node = node)}
            >
              <div className="flex flex-col items-center text-center">
                <img src={checkedIcon} className="w-200px" alt={'upload'} />
                <p className="mt-24px mb-100px text-title-m text-txtlight font-semibold">
                  Payment successful
                </p>
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}

export default PaymentSuccessModal;

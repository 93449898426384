import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Header from '../../components/common/Header';
import LoadingComponent from '../../components/common/LoadingComponent';
import { RegularText } from '../../components/common/Text';
import { getRewardsHistory } from '../../services';
import RewardCard from '../../components/common/RewardCard';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.875rem;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

class RewardsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewards: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getRewardsHistory();
  };

  getRewardsHistory() {
    const { authToken } = this.props;
    getRewardsHistory(authToken)
      .then((res) => {
        this.setState({
          rewards: res.purchases,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  onTap = (ele) => {
    const { history } = this.props;
    console.log(ele)
    ele.couponCode = ele.couponCode ? ele.couponCode : 'NA'
    history.replace('/rewards/confirmation', {
      product: ele,
    });
  };
  render() {
    const { rewards, loading } = this.state;
    const renderReward = (ele, idx) => (
      <RewardCard
        key={idx}
        index={idx}
        productId={ele.productId}
        bgColor={ele.bgColor}
        logoUrl={ele.logoUrl}
        productName={ele.productName}
        offerLabel={ele.offerLabel}
        serviceImage={ele.serviceImage}
        redeemed
        couponCode={ele.couponCode}
        cost={ele.cost}
        purchaseDate={ele.purchaseDate}
        imageUrl={ele.imageLinks}
        layout={ele.layout}
        title={ele.title}
        onTap={() => this.onTap(ele)}
      />
    );
    return (
      <OuterContainer>
        <Header title="My Purchases" />
        <BodyContainer>
          {loading ? (
            <LoadingComponent />
          ) : rewards.length === 0 ? (
            <RegularText>You have not redeemed any rewards yet</RegularText>
          ) : (
            <div className="reward-cards-container">
              {rewards.map(renderReward)}
            </div>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(RewardsHistory);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setVideoFeedbackInfo } from '../../actions';
import { withRouter } from 'react-router-dom';
import VideoRoom from '../../components/consultations/VideoRoom';

class VideoCallContainer extends Component {
  constructor(props) {
    super(props);

    const { state } = props.location;
    const {
      sessionId,
      token,
      roomName,
      authToken,
      userId,
      userName,
      profileImg,
      doctorName,
      doctorId,
      disableGoBack,
    } = state;
    console.log(state);
    this.state = {
      sessionId,
      token,
      roomName,
      doctorName,
      authToken,
      userId,
      userName,
      profileImg,
      doctorId,
      disableGoBack: disableGoBack,
    };
  }
  handleFeedback = () => {
    this.props.setVideoFeedbackInfo({ sessionId: this.state.sessionId });
  };

  render() {
    const {
      roomName,
      token,
      sessionId,
      authToken,
      doctorId,
      userId,
      userName,
      profileImg,
      doctorName,
      disableGoBack,
    } = this.state;
    return (
      <VideoRoom
        roomName={roomName}
        token={token}
        sessionId={sessionId}
        userName={userName}
        profileImg={profileImg}
        doctorName={doctorName}
        authToken={authToken}
        history={this.props.history}
        handleFeedback={this.handleFeedback}
        disableGoBack={disableGoBack}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setVideoFeedbackInfo: (data) => {
    dispatch(setVideoFeedbackInfo(data));
  },
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(VideoCallContainer));

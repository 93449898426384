export * from './debounce';
export * from './errorHandler';
export * from './placeholder';
export * from './font-size';
export * from './tabFormatter';

export const openLinkInNewTab = link => {
  var anchorTag = document.createElement('a');
  // anchorTag.setAttribute('target', '_blank');
  // anchorTag.setAttribute('rel', 'noopener noreferrer');
  anchorTag.setAttribute('href', link);
  anchorTag.setAttribute('style', 'display:none;');
  document.body.appendChild(anchorTag);
  anchorTag.click();
};

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen((location, action) => {
  if (action === 'POP') {
    if (location) {
      if (
        location.pathname === '/consultation/preview' ||
        location.pathname === '/consultation/online/preview' ||
        location.pathname === '/home/consult' ||
        location.pathname === '/pharmacy'
      ) {
        if (location.state) {
          location.state.openConsultId = null;
          location.state.uploadSuccess = null;
        }
        return history.replace(location.pathname);
      }
    }
  }
});

export default history;

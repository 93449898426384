import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';

const StyledTappable = styled(Tappable)`
  display: flex;
  flex-shrink: 0;
  flex-basis: auto;
  flex-direction: column;
  align-items: stretch;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  &.visit-tappable-active {
    opacity: ${props => (props.disabled || props.loading ? '1' : '0.2')};
  }
`;

const TappableCard = ({ children, className, onTap }) => (
  <StyledTappable
    stopPropagation
    onTap={onTap}
    classBase="visit-tappable"
    className={className}
    moveThreshold={10}>
    {children}
  </StyledTappable>
);

TappableCard.propTypes = {
  onTap: PropTypes.func,
};

export default TappableCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config';

class Redirect extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   loading: true,
    // };

    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    let redirectPath = searchParams.get('service');
    let orderId;
    if (redirectPath.includes('/')) {
      const splitArray = redirectPath.split('/');
      redirectPath = splitArray[0];
      orderId = splitArray[1];
    }
    if (!props.authToken) {
      //   this.state.loading = false;
      this.handleRedirectionToLogin(redirectPath, orderId);
    }
    this.handleRedirection(redirectPath, orderId);
  }

  handleRedirectionToLogin = (redirectPath, orderId) => {
    const { history } = this.props;
    switch (redirectPath) {
      case 'hra':
        return window.location.assign(`${config.rootUrl}login?rt=hra`);
      case 'log-meal':
        return window.location.assign(`${config.rootUrl}login?rt=logmeal`);
      case 'online-consults':
        return window.location.assign(
          `${config.rootUrl}login?rt=consultation/online/preview`
        );
      case 'consults-gp':
        const searchText = 'General Physician';
        return window.location.assign(
          `${config.rootUrl}login?rt=consultation/doctor-select?searchType=find&verticalId=3&showSearch=${searchText}`
        );
      case 'consult':
        return window.location.assign(
          `${config.rootUrl}login?rt=home/consult?consultationId=${orderId}`
        );
      case 'offline-consults':
        if (orderId) {
          return window.location.assign(
            `${config.rootUrl}login?rt=consultation/appointment/appointment-status?requestId=${orderId}`
          );
        }
        return window.location.assign(
          `${config.rootUrl}login?rt=consultation/preview`
        );
      case 'labs':
        if (orderId) {
          return window.location.assign(
            `${config.rootUrl}login?rt=lab-test/track?orderId=${orderId}`
          );
        }
        return window.location.assign(`${config.rootUrl}login?rt=lab-test`);
      case 'pharmacy':
        return window.location.assign(`${config.rootUrl}login?rt=pharmacy`);
      case 'ahc':
        return window.location.assign(
          `${config.rootUrl}login?rt=lab-test/ahc-package?showCloseButton=false`
        );
      case 'reimbursment':
        return window.location.assign(
          `${config.rootUrl}login?rt=hospitalization/claim-reimbursement-status?claimId=${orderId}`
        );
      default:
        return window.location.assign(`${config.rootUrl}login?rt=home`);
    }
  };

  handleRedirection = (redirectPath, orderId) => {
    const { history } = this.props;
    switch (redirectPath) {
      case 'hra':
        return history.replace('/hra');
      case 'log-meal':
        return history.replace('/logmeal');
      case 'online-consults':
        return history.replace('/consultation/online/preview');
      case 'consults-gp':
        return history.replace('/consultation/doctor-select', {
          searchType: 'find',
          verticalId: 3,
          showSearch: 'General Physician',
        });
      case 'consult':
        return history.replace(`home/consult?consultationId=${orderId}`);
      case 'offline-consults':
        if (orderId) {
          return history.replace(
            '/consultation/appointment/appointment-status',
            {
              requestId: orderId,
            }
          );
        }
        return history.replace('/consultation/preview');
      case 'labs':
        if (orderId) {
          return history.replace('/lab-test/track', {
            orderId,
          });
        }
        return history.replace('/lab-test');
      case 'ahc':
        return history.replace('/lab-test/ahc-package?showCloseButton=false');
      case 'reimbursment':
        return history.replace('/hospitalization/claim-reimbursement-status', {
          claimId: orderId,
        });
      default:
        return history.replace('/home');
    }
  };

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(Redirect);

/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaFileDownload } from 'react-icons/fa';
import styled from 'styled-components';

import config from '../../config';
import { BoldText, Text } from '../../components/common/Text';
import { insuredApi, userInfo, getCOI } from '../../services';
import LoadingComponent from '../../components/common/LoadingComponent';

import PolicyInfoCard from '../../components/policy/PolicyInfoCard';
import InsuranceInfoCard from '../../components/policy/InsuranceInfoCard';
import SavingsJourney from '../../components/policy/SavingsJourney';
import ReimbursementRequests from '../../components/policy/ReimbursementRequests';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import logo from '../../images/common/amhi-logo.png';

import { setPlaceholderImageForNode } from '../../utils/placeholder';
import { fontSize, openLinkInNewTab } from '../../utils';
import MembershipInfoCard from '../../components/policy/MembershipInfoCard';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  background: white;
`;

const Body = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 2rem;
  overflow-x: hidden;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.2 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  // align-self: flex-start;
  text-align: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  margin-left: 0.625rem;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3d0086;
  padding-top: 2rem;
  padding-bottom: ${props => (props.bottomMargin ? '3.5rem' : '0.625rem')};
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled(BoldText)`
  color: #ffffff;
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const SubscriptionText = styled(Text)`
  color: #fff;
  font-size: ${fontSize(1)};
  opacity: 0.6;
  margin-bottom: 0.875rem;
`;

const VisitText = styled(TitleText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #ffffff;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SponsorIcon = styled.img`
  max-height: 2rem;
  align-self: center;
  margin-bottom: 1.25rem;
`;

const SavingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
`;

const LeftBoldText = styled(TitleText)`
  text-align: left;
  font-size: ${fontSize(1)};
  margin-top: 0;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
`;

const PriceContainer = styled.div`
  display: flex;
  /* flex: 1; */
  align-self: stretch;
  flex-direction: row;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  justify-content: space-between;
`;

const PriceText = styled(TitleText)`
  text-align: left;
  align-self: center;
  font-size: ${() => `${1.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #57c757;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const FooterTitleText = styled(BoldText)`
  font-size: ${fontSize(1)};
  text-align: center;
  margin-top: 1.5rem;
`;

const ResendCOIButton = styled(PrimaryButton)`
  display: flex;
  margin-right: 0.5rem;
  margin-top: 0.625rem;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
`;

const DownloadIcon = styled(FaFileDownload)`
  color: white;
  font-size: 0.75rem;
  margin-right: 0.5rem;
`;

const ResendCoiButtonText = styled(BoldText)`
  font-size: ${fontSize(1)};
  color: white;
`;

const setPlaceholderImageForVerticals = () => {
  Array.from(document.getElementsByClassName('profile-image')).forEach(
    (ele, idx) => {
      console.log({ idx, ele });
      setPlaceholderImageForNode(ele);
    }
  );
};

class PolicyPurchased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      insured: false,
      policyDetails: {},
      policySavings: {},
      reimbursementRequests: null,
      coiUrl: null,
    };

    const { search } = props.location;
    if (search) {
      const params = new URLSearchParams(search);
      if (params.get('auth')) {
        this.authToken = `JWT ${params.get('auth')}`;
      }
    }

    console.log({ props });
  }

  componentDidUpdate() {
    setPlaceholderImageForVerticals();
  }

  openPWA = () => {};

  onCardTap = info => {
    const { showSubscription, subscribed } = this.props;
    switch (info) {
      case 'policyBenefits': {
        // open webpage
        openLinkInNewTab(
          'https://api.getvisitapp.com/apiv3/static-resources/view/v4-backend-images/visit-cis-wordings.pdf'
        );
        break;
      }
      case 'whatsNotCovered': {
        // open webpage
        this.props.history.push('/additional-info');
        break;
      }
      case 'faqs': {
        // open webpage
        if (showSubscription || subscribed) {
          openLinkInNewTab(`${config.websiteBaseUrl}/subscription-faqs.html`);
        } else {
          openLinkInNewTab('https://getvisithelp.freshdesk.com/support/home');
        }
        break;
      }
      case 'tnc': {
        if (showSubscription || subscribed) {
          openLinkInNewTab('https://getvisitapp.com/phonepe-terms.html');
        } else {
          openLinkInNewTab('https://getvisitapp.com/terms.html');
        }
        break;
      }
      default: {
        // default case
        break;
      }
    }
  };

  continueVisit = redirectTo => {
    switch (redirectTo) {
      case 'web': {
        break;
      }
      case 'playStore': {
        openLinkInNewTab(
          'https://play.google.com/store/apps/details?id=com.getvisitapp.window.android&hl=en'
        );
        break;
      }
      case 'appStore': {
        openLinkInNewTab(
          'https://play.google.com/store/apps/details?id=com.getvisitapp.window.android&hl=en'
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  downloadCOI = () => {
    this.setState({ startDownload: true });
    const { authToken } = this.props;
    if (this.props.sponsorId === 90) {
      this.setState({
        coiUrl:
          'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf',
        startDownload: false,
      });
      if (
        typeof window.Android !== 'undefined' &&
        typeof window.Android.downloadFile !== 'undefined'
      ) {
        return window.Android.downloadFile(
          'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf'
        );
      } else {
        document.querySelector(`#resendCoiLink`).click();
      }
      if (window.webkit) {
        if (window.webkit.messageHandlers) {
          if (window.webkit.messageHandlers.reactNative) {
            window.webkit.messageHandlers.reactNative.postMessage(
              JSON.stringify({
                method: 'downloadCoi',
                coiUrl:
                  'https://api.getvisitapp.com/apiv3/static-resources/AkzoNobel-OPD-Benefit-Policy.pdf',
              })
            );
          }
        }
      }
      return;
    }
    getCOI(authToken)
      .then(result => {
        const { coiUrl } = result;
        this.setState({ coiUrl, startDownload: false });

        if (
          typeof window.Android !== 'undefined' &&
          typeof window.Android.downloadFile !== 'undefined'
        ) {
          return window.Android.downloadFile(coiUrl);
        } else {
          document.querySelector(`#resendCoiLink`).click();
        }
        if (window.webkit) {
          if (window.webkit.messageHandlers) {
            if (window.webkit.messageHandlers.reactNative) {
              window.webkit.messageHandlers.reactNative.postMessage(
                JSON.stringify({
                  method: 'downloadCoi',
                  coiUrl,
                })
              );
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchData = () => {
    const { history, authToken, showSubscription } = this.props;
    insuredApi(this.props.authToken)
      .then(result => {
        const insured = result.insured;
        console.log(JSON.stringify(result));
        let policyDetails;
        let policySavings = {};
        if (result.policyDetails && result.policySavings) {
          policyDetails = result.policyDetails;
          policySavings = result.policySavings;
        }
        this.setState({
          policyDetails,
          policySavings,
          reimbursementRequests: result.reimbursementRequests,
        });
        if (!policyDetails) {
          if (showSubscription) {
            window.location.assign(
              `${config.websiteBaseUrl}buy-subscription.html?auth=${
                authToken.split(' ')[1]
              }`
            );
          } else {
            window.location.assign(
              `${config.websiteBaseUrl}buy-opd.html?auth=${
                authToken.split(' ')[1]
              }`
            );
          }
          history.replace('/home');
        } else {
          if (!policyDetails.detailsFilledAt) {
            // Policy details have not been filled yet
            // Pass the auth token to the URL
            const authQueryParam = authToken.split(' ')[1];
            window.location.assign(
              `${config.opdPurchaseUrl}?auth=${authQueryParam}`
            );
          } else {
            this.setState({ loading: false });
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };

  componentDidMount = () => {
    if (this.authToken) {
      console.log('auth token', this.authToken);
      this.props.dispatch({
        type: 'AUTH_TOKEN',
        payload: this.authToken,
      });
      userInfo(this.authToken)
        .then(result => {
          this.props.dispatch({
            type: 'SET_USER_INFO',
            payload: result,
          });
          this.fetchData();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else if (this.props.loginState !== 'LOGGED_IN' || !this.props.authToken) {
      this.props.history.push('/');
    } else {
      this.fetchData();
    }
  };

  onPurchase = () => {
    const { authToken } = this.props;
    const purchaseLink = `${config.opdPurchaseUrl}?auth=${
      authToken.split(' ')[1]
    }`;
    window.location.assign(purchaseLink);
  };

  render() {
    const { showOpd, insured } = this.props;
    const HeaderItems = () => {
      let header = null;
      if (this.state.policyDetails) {
        if (this.state.policyDetails.insurance) {
          header = (
            <Header>
              <HeaderText>You are all Covered!</HeaderText>
              <VisitText>Powered by</VisitText>
              <SponsorIcon src={this.state.policyDetails.logo} alt="Sponsor" />
            </Header>
          );
        } else {
          header = (
            <Header>
              <SubscriptionText>Subscription Details</SubscriptionText>
            </Header>
          );
        }
      } else {
        // header = (
        //   <Header>
        //     <HeadImage src={HeaderImage} />
        //     <HeaderText>VISIT OPD Insurance</HeaderText>
        //     <VisitText>
        //       Cashless Health Insurance that pays for your OPD costs
        //     </VisitText>
        //     <SponsorText>Powered by</SponsorText>
        //     <SponsorIcon src={logo} alt="Sponsor" />
        //   </Header>
        // );
      }
      return header;
    };

    const HeaderComponent = (
      <HeaderContainer bottomMargin={this.state.policyDetails}>
        {HeaderItems()}
      </HeaderContainer>
    );

    const InsuranceCard = () => {
      let element = null;
      if (this.state.policyDetails) {
        if (this.state.policyDetails.insurance) {
          element = (
            <InsuranceInfoCard
              policyDetails={this.state.policyDetails}
              profileImage={this.props.profileImage}
              onTap={() => {}}
            />
          );
        } else {
          element = (
            <MembershipInfoCard
              policyDetails={this.state.policyDetails}
              onTap={() => {}}
            />
          );
        }
      }
      console.log({ element });
      return element;
    };

    const TotalSavingsComponent = () => {
      let element = null;
      if (this.state.reimbursementRequests) {
        element = (
          <SavingsContainer>
            <LeftBoldText marginBottom="10px">
              Your Reimbursement Requests
            </LeftBoldText>
            <ReimbursementRequests details={this.state.reimbursementRequests} />
          </SavingsContainer>
        );
      } else if (this.state.policySavings) {
        element = (
          <SavingsContainer>
            <LeftBoldText>Your Total Savings</LeftBoldText>
            <PriceContainer>
              <PriceText>{this.state.policyDetails.totalSavings}</PriceText>
              {/* <ShareButton>
                ShareImage />
                <ShareText>Share & Earn</ShareText>
              </ShareButton> */}
            </PriceContainer>
            <LeftBoldText marginBottom="1.25rem">
              Your Savings Journey
            </LeftBoldText>

            <SavingsJourney details={this.state.policySavings} />
          </SavingsContainer>
        );
      }

      return element;
    };

    const renderDownloadCoi = () => {
      console.log({ androidVersion: window.Android });
      let element = null;
      if (
        !window.Android ||
        (typeof window.Android !== 'undefined' &&
          typeof window.Android.downloadFile !== 'undefined')
      ) {
        element = (
          <ResendCOIButton
            onTap={() => this.downloadCOI()}
            loading={this.state.startDownload}
          >
            <DownloadIcon />
            <ResendCoiButtonText>
              {this.state.policyDetails.insurance
                ? 'Download COI'
                : 'Download Membership Document'}
            </ResendCoiButtonText>
          </ResendCOIButton>
        );
      }
      return element;
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        {!this.state.loading ? (
          <OuterContainer>
            <BodyContainer>
              <Body>
                {HeaderComponent}

                {InsuranceCard()}
                {renderDownloadCoi()}

                {TotalSavingsComponent()}

                {/* {BodyForNonInsured()} */}

                <a
                  href={`${this.state.coiUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`resendCoiLink`}
                  download
                  style={{ display: 'none' }}
                />
                {this.state.policyDetails.hideShowMore ? null : (
                  <>
                    <FooterTitleText>Know More</FooterTitleText>

                    {insured || showOpd ? (
                      <PolicyInfoCard
                        title="VISIT Insurance Offerings"
                        onTap={() => this.onCardTap('policyBenefits')}
                      />
                    ) : (
                      false
                    )}
                    <PolicyInfoCard
                      title="What’s not covered?"
                      onTap={() => this.onCardTap('whatsNotCovered')}
                    />
                    <PolicyInfoCard
                      title="Frequently Asked Questions"
                      onTap={() => this.onCardTap('faqs')}
                    />
                    <PolicyInfoCard
                      title="Terms and Conditions"
                      onTap={() => this.onCardTap('tnc')}
                    />
                  </>
                )}
              </Body>
            </BodyContainer>
            {this.state.insured ? null : false}
            {/* <GradientPrimaryButton onTap={this.onPurchase}>
                <ButtonText>Go Cashless! Starting Rs. 1299/year</ButtonText>
              </GradientPrimaryButton> */}
          </OuterContainer>
        ) : (
          <LoadingComponent />
        )}
      </FadeInTransition>
    );
  }
}

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
  profileImage: state.user.profileImage,
  sponsorId: state.user.sponsorId,
  showSubscription: state.app.config.showSubscription,
  showOPD: state.app.config.showOPD,
  insured: state.app.config.insured,
  subscribed: state.app.config.subscribed,
});

export default connect(mapStateToProps)(PolicyPurchased);

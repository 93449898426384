import React, { Component } from 'react';
import styled from 'styled-components';
import { BsCheck } from 'react-icons/bs';
import { fontSize } from '../../utils';
import StyledTappable from '../common/StyledTappable';

import { Text } from '../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionContainer = styled(StyledTappable)`
  font-size: ${fontSize(1)};
  justify-content: flex-start;
  min-height: 2rem;
  padding: 12px 0px;
  border-bottom: 1px solid #f0f3f6;
`;

class MultipleSelect extends Component {
  findOptionIndex(option) {
    const { value } = this.props;
    if (!value || !Array.isArray(value)) {
      return -1;
    }
    return value.findIndex((ele) => ele === option);
  }

  render() {
    const { setResponse, options, value } = this.props;
    console.log(options, value);
    const renderListOptions = (ele, index) => {
      return (
        <OptionContainer key={index} onTap={() => setResponse(ele)}>
          {this.findOptionIndex(ele) !== -1 ? (
            <div
              className="mr-12px bg-txtsecondary-shades4 rounded-6"
              style={{
                width: '18px',
                height: '18px',
                padding: '3px',
                background: '#714FFF',
                color: '#fff',
              }}
            >
              <BsCheck
                style={{
                  height: '12px',
                  width: '12px',
                  fontWeight: 700,
                }}
              />
              {/* <div
                className="rounded-6 text-center"
                style={{ width: '12px', height: '12px' }}
              ></div> */}
            </div>
          ) : (
            <div
              className="mr-12px bg-txtsecondary-shades4 rounded-6"
              style={{ width: '18px', height: '18px' }}
            ></div>
          )}
          <p className="text-title-m font-semibold text-icon">{ele}</p>
        </OptionContainer>
      );
    };

    return (
      <OuterContainer className="font-mont">
        {this.props.options.map(renderListOptions)}
      </OuterContainer>
    );
  }
}

export default MultipleSelect;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { BoldText, Text, SemiBoldText } from '../common/Text';

import referGraphic from '../../images/common/policy.svg';
import referBackground from '../../images/home/refer-background.png';

const OuterTappable = styled(TappableCard)`
  margin: 0.4rem 0.5rem;
`;

const OuterContainer = styled.div`
  padding: 0.5rem 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeadContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const HeadLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const GraphicIcon = styled.img`
  height: 4.3125rem;
  margin-top: 0.625rem;
`;

const SubtitleText = styled(SemiBoldText)`
  font-size: 14px;
  color: #413958;
  margin-top: 8px;
  margin-bottom: 12px;
`;

const PolicyActivationCard = ({ onTap }) => (
  <OuterTappable onTap={onTap}>
    <OuterContainer>
      <HeadContainer>
        <HeadLeftContainer>
          <SubtitleText>Activate your OPD benefits</SubtitleText>
          <Text fontSize="12px" color="#72777A">
            Click on the verification email received on your registered email
            address to start enjoying your OPD policy benefits. Verify your
            email now!
          </Text>
        </HeadLeftContainer>
        <div style={{ background: `url(${referBackground})` }}>
          <GraphicIcon src={referGraphic} alt="Refer Graphic" />
        </div>
      </HeadContainer>
      {/* <PrimaryButton onTap={onTap}>
        <CardButtonText>Invite Now</CardButtonText>
      </PrimaryButton> */}
    </OuterContainer>
  </OuterTappable>
);

PolicyActivationCard.propTypes = {
  onTap: PropTypes.func,
};

export default PolicyActivationCard;

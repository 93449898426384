import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes as RemoveIcon } from 'react-icons/fa';

import {
  BlackText,
  BoldText,
  ButtonText,
  Text,
} from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import { PrimaryButton } from '../../components/common/Buttons';
import LoadingComponent from '../../components/common/LoadingComponent';
import { FitcoinIcon } from '../../components/common/Fitcoin';
import { FlyInTransition } from '../../components/transitions/transitions';
import { getRewards, redeemProduct } from '../../services';
import RewardCard from '../../components/common/RewardCard';
import Modal from '../common/Modal';
import { setFitcoinsBalance } from '../../actions';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  padding: 0.875rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const CategoryTitleText = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.875rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  padding: 0.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  padding: 0.5rem;
`;

const CloseIcon = styled(RemoveIcon)``;

const FitcoinText = styled(BlackText)`
  font-size: ${() => `${1.375 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #d2a72b;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 0.625rem;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

class RewardsTabContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewards: [],
      loading: true,
      purchasing: false,
      showModal: false,
      modalVisible: false,
      selectedProduct: [],
    };
  }

  componentDidMount = () => {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Rewards Products Tab',
    });
    logEvent('Redeem Tab');
    this.getRewards();
  };

  confirmPurchase(ele) {
    this.setState({
      showModal: true,
      modalVisible: true,
      selectedProduct: ele,
    });
  }

  purchaseProduct = () => {
    this.setState({
      modalVisible: false,
      purchasing: true,
    });
    const { productId, type } = this.state.selectedProduct;
    const { authToken, history, phone, countryCode } = this.props;
    redeemProduct(productId, type, phone, countryCode, authToken)
      .then((res) => {
        const { couponCode } = res;
        history.push('/rewards/description', {
          product: {
            ...this.state.selectedProduct,
            redeemed: true,
            couponCode,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          showModal: false,
          purchasing: false,
          selectedProduct: null,
        });
      });
  };

  getRewards() {
    const { authToken, dispatch } = this.props;
    getRewards(authToken)
      .then((res) => {
        const { store, walletBalance } = res;
        dispatch(setFitcoinsBalance(walletBalance));
        this.setState({
          rewards: store,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  openReward(ele) {
    const { history } = this.props;
    history.push('/rewards/description', {
      product: ele,
    });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      rewards,
      loading,
      purchasing,
      showModal,
      modalVisible,
      selectedProduct,
    } = this.state;
    const renderReward = (ele, idx) => (
      <RewardCard
        key={idx}
        onTap={() => this.openReward(ele)}
        onPurchase={() => this.confirmPurchase(ele)}
        bgColor={ele.bgColor}
        logoUrl={ele.logoUrl}
        productName={ele.productName}
        offerLabel={ele.offerLabel}
        serviceImage={ele.serviceImage}
        cost={ele.cost}
        imageUrl={ele.imageLinks}
        layout={ele.layout}
      />
    );
    const renderRewardsCategory = (ele, idx) => {
      if (ele.products.length === 0) {
        return false;
      }
      return (
        <div key={idx}>
          <CategoryTitleText>{ele.label}</CategoryTitleText>
          <div className="reward-cards-container">
            {ele.products.map(renderReward)}
          </div>
        </div>
      );
    };
    return (
      <OuterContainer>
        {loading ? <LoadingComponent /> : rewards.map(renderRewardsCategory)}
        {showModal && (
          <Modal>
            <ModalContainer>
              {purchasing ? (
                <LoadingComponent color="white" />
              ) : (
                <FlyInTransition
                  in={modalVisible}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <ModalBody>
                    <CloseContainer onTap={this.closeModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <Text>{`You are about to unlock a ${selectedProduct.productName} coupon using`}</Text>
                    <ButtonTextContainer>
                      <FitcoinIcon />
                      <FitcoinText>{selectedProduct.cost}</FitcoinText>
                    </ButtonTextContainer>
                    <StyledButton onTap={this.purchaseProduct}>
                      <ButtonText>CONFIRM</ButtonText>
                    </StyledButton>
                  </ModalBody>
                </FlyInTransition>
              )}
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  countryCode: state.user.countryCode,
  phone: state.user.userPhone,
});

export default connect(mapStateToProps)(RewardsTabContainer);

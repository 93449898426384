import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import { FadeInTransition } from '../transitions/transitions';
import fileIcon from '../../images/common/file-icon.svg';
import styled from 'styled-components';
import photoIcon from '../../images/common/photo-icon.svg';
import StyledTappable from './StyledTappable';
import uploadIcon from '../../images/common/upload.svg'
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const AttachOptionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

const AttachOption = styled(StyledTappable)`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin: 0 0.25rem;
`;

const AttachOptionInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  overflow: hidden;
`;

const AttachOptionText = styled.div`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const AttachmentInput = styled.input`
  display: none;
`;

export class UploadModal extends Component {
  state = {
    showUploadOption: true,
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  onAttachPhoto() {
    document.querySelector('#photoAttachment').click();
  }

  onAttachFile() {
    document.querySelector('#fileAttachment').click();
  }

  render() {
    const { showUploadOption } = this.state;
    return (
      <Modal>
        <AttachmentInput
          type="file"
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={() => this.props.handleAttachment('photo')}
        />
        <AttachmentInput
          type="file"
          id="fileAttachment"
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={() => this.props.handleAttachment('file')}
        />
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={showUploadOption}
            mountOnEnter
            unmountOnExit
            onExited={this.props.hideModal}
            appear
          >
            <div
              className="bg-textbox rounded-12"
              ref={(node) => (this.node = node)}
            >
              <div
                className="p-16px pl-20px border-0 border-b border-solid
              border-txtsecondary-shades4 flex flex-row items-center pr-48px
              "
              >
                <img src={uploadIcon} alt={'upload'} height={20} width={17} />
                <p className="text-title-m font-semibold pl-12px text-txtlight">
                  Choose an option to upload
                </p>
              </div>
              <div>
                <AttachOptionsContainer>
                  <AttachOption onTap={() => this.onAttachPhoto()}>
                    <AttachOptionInnerContainer>
                      <img src={photoIcon} alt='upload-image' />
                      <AttachOptionText>Image</AttachOptionText>
                    </AttachOptionInnerContainer>
                  </AttachOption>
                  <AttachOption onTap={() => this.onAttachFile()}>
                    <AttachOptionInnerContainer>
                      <img src={fileIcon} alt='upload-pdf' />
                      <AttachOptionText>PDF</AttachOptionText>
                    </AttachOptionInnerContainer>
                  </AttachOption>
                </AttachOptionsContainer>
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}

export default UploadModal;

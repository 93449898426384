export const defaultState = {
  startCall: false,
  videoCallPayload: null,
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'START_VIDEO_CALL':
      return Object.assign({}, state, {
        startCall: true,
        videoCallPayload: action.payload,
      });
    case 'STOP_VIDEO_CALL':
      console.log('STOP_VIDEO_CALL');
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
}

import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function getWaterLogDetails(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/water-tracking`, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeWater(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/water-tracking/intake/remove`, null, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addWater(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/water-tracking/intake/add`, null, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteWaterLogById(authToken, id) {
  const headers = {
    authorization: authToken,
  };

  const data = {
    intakeId: id,
  };

  return axios
    .post(`${config.apiBaseUrl}/water-tracking/intake/remove`, data, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateDefaults(authToken, goal, vesselKey) {
  const headers = {
    authorization: authToken,
  };

  const data = {
    goal,
    vesselKey,
  };

  return axios
    .post(`${config.apiBaseUrl}/water-tracking/change-defaults`, data, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import crossIcon from '../../images/common/cross.svg';
import clockIcon from '../../images/common/clock.svg';
import verticalIcon from '../../images/common/vertical.svg';
import gynaeIcon from '../../images/buying-journey/gynae.svg';
import cardioIcon from '../../images/buying-journey/cardio.svg';
import sexologyIcon from '../../images/buying-journey/sexology.svg';
import dermaIcon from '../../images/buying-journey/dermatology.svg';
import pyschologyIcon from '../../images/buying-journey/pyschologist.svg';
import nutritionIcon from '../../images/buying-journey/nutrition.svg';
import paediatricsIcon from '../../images/buying-journey/pediatrics.svg';
import benefitIcon1 from '../../images/common/benefit1.svg';
import benefitIcon2 from '../../images/common/benefit2.svg';
import benefitIcon3 from '../../images/common/benefit3.svg';
import benefitIcon4 from '../../images/common/benefit4.svg';
import benefitIcon5 from '../../images/common/benefit5.svg';
import benefitIcon6 from '../../images/common/benefit6.svg';
import benefitIcon7 from '../../images/common/benefit7.svg';
import membersBenefitIcon from '../../images/common/members-benefit.svg';
import supportIcon from '../../images/common/support1.svg';
import bgPattern from '../../images/buying-journey/bg-pattern.png';
import percentIcons from '../../images/buying-journey/percent-icon.png';
import populationIcon from '../../images/buying-journey/population.svg';
import verticalsIcon from '../../images/buying-journey/verticals.svg';
import oneHealthPlanLogo from '../../images/buying-journey/one-health-plan-logo.png';
import planHero1 from '../../images/buying-journey/tele-plan-hero-1.png';
import planHero2 from '../../images/buying-journey/tele-plan-hero-2.png';
import planCarousel1 from '../../images/buying-journey/tele-plan-carousel-1.png';
import planCarousel2 from '../../images/buying-journey/tele-plan-carousel-2.png';
import planCarousel3 from '../../images/buying-journey/tele-plan-carousel-3.png';
import planSavings from '../../images/buying-journey/plan-savings.png';
import planHospitals from '../../images/buying-journey/plan-hospitals.png';
import reviewImage from '../../images/buying-journey/review.png';
import arrowDown from '../../images/common/arrow-down.svg';
import arrowUp from '../../images/common/arrow-up.svg';
import StarRatings from 'react-star-ratings';
import { userInfo } from '../../services';
import moment from 'moment';
import { initializeAmplitudeClient } from '../../services/analytics';
import Carousel from '../../components/common/Carousel';
import initializeFreshDesk from '../../utils/freshchat';
import { ExpandTransition } from '../../components/transitions/transitions';
import googleIcon from '../../images/buying-journey/google.png';
import appleIcon from '../../images/buying-journey/apple.png';
import { logEvent } from '../../utils/logEvent';

export class BuyingJourneyStartTest extends Component {
  state = {
    endsIn: new Date(1612722600000),
    minutes: undefined,
    seconds: undefined,
    hours: undefined,
    verticals: [
      {
        name: 'Physican',
        icon: verticalIcon,
      },
      {
        name: 'Dermatology',
        icon: dermaIcon,
      },
      {
        name: 'Gynaecology',
        icon: gynaeIcon,
      },
      {
        name: 'Sexology',
        icon: sexologyIcon,
      },
      {
        name: 'Cadiology',
        icon: cardioIcon,
      },
      {
        name: 'Psychology',
        icon: pyschologyIcon,
      },
      {
        name: 'Nutrition',
        icon: nutritionIcon,
      },
      {
        name: 'Paediatrics',
        icon: paediatricsIcon,
      },
    ],
    benefits: [
      {
        description:
          'Unlimited Online Consultations with Specialists via Call/Chat/Video',
        icon: benefitIcon1,
      },
      {
        description:
          'Unlimited Nutritionist Consults with Customised Diet Plan',
        icon: benefitIcon2,
      },
      {
        description: 'Unlimited One-o-one Psychologist Session',
        icon: benefitIcon4,
      },
      {
        description: 'Covers You and upto 5 family members',
        icon: membersBenefitIcon,
      },
      {
        description: 'Earn Exclusive Rewards for Staying Fit',
        icon: benefitIcon3,
      },
      {
        description: 'Access to Online Wellness, Fitness and Podcast Sessions',
        icon: benefitIcon5,
      },
      {
        description: 'Upto 20% Discount on Lab Tests and Health Packages',
        icon: benefitIcon6,
      },
      {
        description: 'Upto 20% Discount on Medicine Delivery',
        icon: benefitIcon7,
      },
    ],
    hospitalImages: [],
    testimonials: [
      {
        name: 'Ravi Sinha',
        profile: '',
        review:
          '"A superb app, great to have expert opinion at your finger tips. Gives u comfort to express your problems properly. Response is electric fast and detailed"',
        image: 'https://getvisitapp.com/img/ravisinha.png',
      },
      {
        name: 'Manish Sirhindi',
        profile: '',
        review:
          '"One of the best apps that I have come across. It provided me instant consultation with medical experts in my time of need. A must recommended app."',
        image: 'https://getvisitapp.com/img/manish.png',
      },
      {
        name: 'Nakul Maheshwari',
        profile: '',
        review:
          '"The app is amazing, all the specialist also seem to be pretty good. It\'s amazing if you can get a professional advice without actually taking the trouble of going out in traffic and waiting in a que for hours"',
        image: 'https://getvisitapp.com/img/nakul.png',
      },
      {
        name: 'Archana Bilawar',
        profile: '',
        review:
          '"Never thought would come across such a great app which helps solve our all kind of health related doubts along with suggestions on how to tackle our health issues. I would suggest this app to each and everyone out there. You can talk with any Dr available and get suggestions on your health problems."',
        image:
          'https://visit-public.s3.ap-south-1.amazonaws.com/assets/one-health/archana.png',
      },
      {
        name: 'Sneh Jain',
        profile: '',
        review:
          '"A very Nice app, serves its purpose. Easy accessibility to a big pool of doctors. Plus an extra benefit of getting good offers/discounts on various products/services as well. The best thing is that does not contain any ads/promotional content."',
        image:
          'https://visit-public.s3.ap-south-1.amazonaws.com/assets/one-health/sneh.png',
      },
    ],
    showCrossButton: false,
    faq: [
      {
        id: 1,
        question: 'How do I request a teleconsultation?',
        desc: `You can easily request a teleconsultation by following these steps.
\n1. Navigate to the care tab
\n2. Click on the My Online Consults tab
\n3. Select Consult a Doctor
\n4. Choose your choice of Vertical (Speciality)
\n5. Choose your doctor basis the profile of the practitioner
\n6. Click on Consult Now`,
      },
      {
        id: 2,
        question: 'How many members are covered under a single plan?',
        desc: `A plan can include up to 5 members (Self + 4 Dependants)`,
      },
      {
        id: 3,
        question: 'What does a single Teleconsultation include?',
        desc: `1. Unlimited Consultations for all members through Visit App
\n2. Instant resolution to all health problems & any query or information within 10 minutes
\n3. Free Follow up with the practitioner for 7 days for all members
\n4. Get Case summaries and E-prescriptions with all valid consultations`,
      },
      {
        id: 4,
        question: 'How will my dependants use the consult option',
        desc: `Your dependants can access the teleconsultation feature in two ways.
\n1. They can login onto the visit app using their registered number to unlock sponsored benefits
\n2. The primary sponsored user can select the dependent type prior to every consultation booking`,
      },
      {
        id: 5,
        question: 'Are there any hidden charges?	',
        desc: `No, there are no hidden charges with this plan`,
      },
    ],
    selectedFaq: null,
    showDownloadSection: true,
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('authToken')) {
        dispatch({
          type: 'AUTH_TOKEN',
          payload: params.get('authToken'),
        });
        userInfo(params.get('authToken'))
          .then((result) => {
            dispatch({
              type: 'SET_USER_INFO',
              payload: result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (
        params.get('showCrossButton') &&
        params.get('showCrossButton') === 'true'
      ) {
        this.setState({
          showCrossButton: true,
        });
      }
      if (
        params.get('doNotShowDownloadButton') &&
        params.get('doNotShowDownloadButton') === 'true'
      ) {
        this.setState({
          showDownloadSection: false,
        });
      }
    }
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Visit Prime Health Plan Landing Screen Test');
    this.interval = setInterval(() => {
      const then = moment(this.state.endsIn);
      const now = moment(new Date()).utcOffset('+05:30');
      const countdown = moment.duration(then.diff(now));
      // const days = countdown.format('DD');
      let hours = Math.floor(countdown.as('hour')).toFixed(0);
      const minutes = Math.floor(countdown.as('minutes') % 60).toFixed(0);
      const seconds = Math.floor(countdown.as('seconds') % 60).toFixed(0);
      // if (days) {
      //   hours = Number(hours) + Number((days - 1) * 24);
      // }
      this.setState({ hours, minutes, seconds });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
  };

  render() {
    const {
      hours,
      minutes,
      seconds,
      verticals,
      benefits,
      hospitalImages,
      testimonials,
      showCrossButton,
    } = this.state;

    return (
      <div className="w-full font-mont overflow-auto">
        <div className="header">
          <div className="relative">
            <div
              className="w-full
              bg-theme-background
              "
              style={{
                background: `url(${bgPattern}) no-repeat`,
                backgroundSize: 'cover',
              }}
            >
              <div className="flex flex-row items-end justify-between">
                <div className="flex flex-row items-center bg-custom453c87 mt-20px px-8px py-6px ml-24px rounded-8">
                  <img className="mr-4px w-16px" src={percentIcons} alt="%" />
                  <span className="text-caption-s text-textbox font-mont font-bold">
                    FLASH SALE
                  </span>
                  <span className="text-caption-s text-custom574BB3 ml-4px mr-4px font-mont font-bold line-through opacity-50">
                    |
                  </span>
                  <span className="text-caption-s text-textbox font-mont font-bold line-through opacity-50">
                    ₹ 166&nbsp;
                  </span>
                  <span className="text-caption-s text-textbox font-mont font-bold">
                    ₹ 133/month
                  </span>
                </div>
                {showCrossButton && (
                  <div
                    className="cursor-pointer h-36px w-36px bg-textbox rounded-8 mt-40px mr-20px float-right flex flex-row items-center justify-center"
                    onClick={this.closeView}
                  >
                    <img src={crossIcon} alt="cross" />
                  </div>
                )}
              </div>
              <div className="mt-14px mb-10px py-10px px-24px">
                <Carousel className="" autoplay delay={5000} showDots>
                  <img src={planCarousel1} className="w-100" />
                  <img src={planCarousel2} className="w-100" />
                  <img src={planCarousel3} className="w-100" />
                  {/* <div className="flex flex-row items-stretch justify-between">
                  <div className="flex flex-col">
                    <img
                      src={oneHealthPlanLogo}
                      className="h-16px mb-8px"
                      alt="Visit One Health Plan"
                    />
                    <p className="text-title-l text-textbox font-bolder mb-2px">
                      Unlimited
                    </p>
                    <p className="text-title-m text-textbox font-bold">
                      Online Doctor
                      <br /> Consultation For
                      <br /> You & Your Family
                    </p>
                  </div>
                  <img
                    alt="Hero"
                    style={{ maxWidth: '120px' }}
                    src={planHero1}
                  />
                </div>
                <div className="w-full flex flex-row items-stretch justify-between">
                  <div className="flex flex-col">
                    <img
                      src={oneHealthPlanLogo}
                      className="h-16px mb-8px"
                      alt="Visit One Health Plan"
                    />
                    <p className="text-title-l text-textbox font-bolder mb-2px">
                      Unlimited
                    </p>
                    <p className="text-title-m text-textbox font-bold">
                      Fitness Videos
                      <br /> & Meditation Podcasts
                      <br /> For You & Your Family
                    </p>
                  </div>
                  <img
                    alt="Hero"
                    style={{ maxWidth: '120px' }}
                    src={planHero2}
                  />
                </div> */}
                </Carousel>
              </div>
              {(hours > 0 || minutes > 0 || seconds > 0) && (
                <div className="bg-custom493e98 pt-10px pb-10px flex flex-col items-center">
                  <p className="text-tab text-textbox font-bold">
                    Sale Ends In
                  </p>
                  <div className="flex flex-row w-full justify-center">
                    <img className="w-16px" src={clockIcon} alt="clock" />
                    <p className="ml-8px font-mont text-title-m text-textbox font-bolder">
                      {hours}Hr : {minutes}min : {seconds}sec
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="body mb-100px">
          <div className="p-20px pb-24px pr-24px pl-24px bg-txtsecondary-shades4 bg-opacity-40">
            <div className="">
              <div className="pb-16px border-b border-txtsecondary-shades4">
                <div className="flex items-center">
                  <img className="mr-8px" src={populationIcon} alt="Members" />
                  <span className="flex flex-row">
                    <p className="text-primary text-body-l font-bold">
                      2,00,000+
                    </p>
                    <p className="text-black text-body-l font-semibold">
                      &nbsp;Visit Prime Health Members
                    </p>
                  </span>
                </div>
              </div>
              <div className="pt-16px">
                <div className="flex items-center">
                  <img className="mr-8px" src={verticalsIcon} alt="verticals" />
                  <span className="flex flex-row">
                    <p className="text-primary text-body-l font-bold">8</p>
                    <p className="text-black text-body-l font-semibold">
                      &nbsp;Specialist verticals included
                    </p>
                  </span>
                </div>
                <div className="flex flex-row overflow-x-auto mt-24px">
                  {verticals.map((v, idx) => {
                    return (
                      <div
                        className="flex flex-col items-center mr-12px"
                        key={idx}
                      >
                        <img
                          src={v.icon}
                          className="w-20px mb-8px"
                          alt="vertical"
                        />
                        <p className="text-chip font-semibold text-txtlight">
                          {v.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="save-upto p-24px">
            <div className="bg-txtsecondary-shades4 bg-opacity-7 rounded-12">
              <div className="bg-secondary text-textbox pt-12px pb-12px pl-20px pr-40px rounded-t-12">
                <p className="text-title-m font-bold">Save Upto 60%</p>
                <p className="text-body-s font-semibold">
                  on your annual helathcare cost
                </p>
              </div>
              <div className="pt-16px pl-12px pb-20px pr-20px flex flex-row">
                <p
                  style={{ width: 'fit-content' }}
                  className="text-chip text-txtsecondary font-medium transform -rotate-90"
                >
                  1 year
                </p>
                <div className="chart flex flex-row">
                  <div className="h-24">
                    <p>₹ 3,500</p>
                  </div>
                  <div>
                    <p>₹ 1,500</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="p-24px pt-32px pr-32px">
            <div className="benefits">
              <p className="text-title-m font-bold text-txtlight">
                Benefits of buying this plan
              </p>
              <div className="mt-36px">
                {benefits.map((benefit, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex flex-row items-center border-b border-txtsecondary-shades4 pb-24px mt-24px"
                    >
                      <img
                        src={benefit.icon}
                        className="mr-16px"
                        alt="benefit"
                      />
                      <p className="text-title-s font-semibold">
                        <span className="text-secondary-shades6 font-bold">
                          {benefit.description.split(' ')[0]}
                        </span>

                        {benefit.description.replace(
                          benefit.description.split(' ')[0],
                          ''
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="p-24px">
            <img src={planSavings} alt="Savings" className="mb-24px" />
            <p className="text-title-m font-bold text-txtlight">
              Doctors from top hospitals
            </p>
            <div className="mt-32px">
              <img src={planHospitals} alt="Hospital Coverage" />
            </div>
          </div>
          <div className="p-24px pr-0">
            <p className="text-title-m font-bold text-txtlight">
              Some of our happy users
            </p>
            <div className="flex flex-row overflow-x-scroll mt-24px">
              {testimonials.map((ele, idx) => {
                return (
                  <div
                    className="bg-txtsecondary-shades4 rounded-12 p-16px pb-24px mr-16px"
                    style={{
                      minWidth: '275px',
                    }}
                  >
                    <div className="flex flex-row mb-16px">
                      <img
                        src={ele.image}
                        className="h-40px w-40px rounded-full mr-12px"
                      />
                      <div className="flex flex-col">
                        <p className="text-txlight text-title-s font-bold">
                          {ele.name}
                        </p>
                        <p className="text-chip text-txtlight font-medium">
                          {ele.profile}
                        </p>
                      </div>
                    </div>
                    <i className="text-body-s text-txtlight font-regular">
                      {ele.review}
                    </i>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pl-16px pr-16px">
            <img src={reviewImage} alt="review" />
          </div>
          {this.state.showDownloadSection && (
            <div className="p-24px">
              <p className="font-bold text-title-m mb-16px">
                Start Your Plan on the Visit App!
              </p>
              <div className="clear-float">
                <img
                  src={appleIcon}
                  className="mr-8px"
                  onClick={() => {
                    window.location.href =
                      'https://itunes.apple.com/in/app/visit-free-chat-with-a-doctor/id1169969848?mt=8';
                  }}
                  style={{
                    width: '48%',
                    height: 'auto',
                    maxWidth: '48%',
                    float: 'left',
                  }}
                  alt={'app'}
                />
                <img
                  src={googleIcon}
                  onClick={() => {
                    window.location.href =
                      'https://play.google.com/store/apps/details?id=com.getvisitapp.android';
                  }}
                  style={{
                    width: '48%',
                    height: 'auto',
                    maxWidth: '48%',
                    float: 'right',
                  }}
                  alt={'app'}
                />
              </div>
            </div>
          )}
          <div className="p-24px">
            <p className="font-bold text-title-m mb-16px">
              Frequently asked questions
            </p>
            {this.state.faq.map((f, idx) => {
              return (
                <div
                  style={
                    idx === 0
                      ? {
                          borderTop: '1px solid #F0F3F6',
                        }
                      : {}
                  }
                  className="pt-16px pb-12px border-b border-txtsecondary-shades4"
                >
                  <div
                    className="flex flex-row justify-between"
                    onClick={() => {
                      if (this.state.selectedFaq === f.id) {
                        this.setState({
                          selectedFaq: null,
                        });
                      } else {
                        this.setState({
                          selectedFaq: f.id,
                        });
                      }
                    }}
                  >
                    <p className="text-title-s font-medium text-txtlight">
                      {f.question}
                    </p>
                    {this.state.selectedFaq &&
                    this.state.selectedFaq === f.id ? (
                      <img src={arrowUp} />
                    ) : (
                      <img src={arrowDown} />
                    )}
                  </div>
                  <ExpandTransition
                    in={
                      this.state.selectedFaq && this.state.selectedFaq === f.id
                    }
                    mountOnEnter
                    unmountOnExit
                    appear
                  >
                    <div className="mt-16px mb-8px">
                      <p className="text-body-s font-regular text-txtlight">
                        {f.desc.split('\n').map((line) => {
                          return (
                            <span>
                              {' '}
                              {line} <br />
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  </ExpandTransition>
                </div>
              );
            })}
          </div>
          {/* <div className="p-24px">
            <div className="bg-txtsecondary-shades4 p-20px pb-16px rounded-12">
              <div className="flex flex-row border-b border-txtsecondary-shades7 pb-16px">
                <div className="mr-40px">
                  <div className="flex flex-row">
                    <p className="text-title-m font-bold text-txtlight mr-12px">
                      4.5
                    </p>
                    <img src={appleIcon} />
                  </div>
                  <StarRatings
                    rating={4.5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="gold"
                  />
                </div>
                <div>
                  <div className="flex flex-row">
                    <p className="text-title-m font-bold text-txtlight mr-12px">
                      4.2
                    </p>
                    <img src={googleIcon} />
                  </div>
                  <StarRatings
                    rating={4.2}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="gold"
                  />
                </div>
              </div>
              <div className="mt-8px">
                <p className="text-txtlight text-title-l font-bold">
                  5,00,000+
                </p>
                <p className="text-body-s text-txtsecondary font-medium">
                  Consultations completed
                </p>
              </div>
            </div>
          </div> */}

          <div className="p-24px text-center">
            <p className="text-body-s text-txtlight font-semibold mb-4px">
              Need assistance ?
            </p>
            <p className="text-chip txt-txtsecondary font-medium mb-20px">
              Our exceutives are ready to help you out on this
            </p>
            <span
              className="flex flex-row items-center justify-center"
              onClick={this.openSupport}
            >
              <img src={supportIcon} alt="support" />
              <p className="text-body-s ml-8px text-secondary-shades6 font-semibold">
                Connect
              </p>
            </span>
            <p className="mt-8px text-chip font-medium txt-txtsecondary">
              Monday - Saturday | 10am - 7pm
            </p>
          </div>
        </div>
        <div
          className="footer absolute bottom-0 w-full px-24px py-16px bg-textbox"
          style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              {/* <span
                className="flex flex-row items-center justify-center mb-4px"
                onClick={this.openSupport}
              >
                <img src={supportIcon} alt="support" />
                <p className="text-body-s ml-8px text-secondary-shades6 font-semibold">
                  Chat with us
                </p>
              </span> */}
              <strike className="font-semibold text-title-s text-txtsecondary-shades8 mr-4px">
                ₹ 166
              </strike>
              <p className="font-bold text-title-m font-txtlight">
                ₹ 133/month
              </p>
            </div>
            <span
              className="pl-20px pr-16px rounded-12 hover pt-12px pb-12px text-button-s font-semibold text-textbox"
              style={{ background: '#1EB865', backdropFilter: 'blur(4px)' }}
              onClick={() => {
                this.props.history.push('/buying-journey/plans');
              }}
            >
              Buy now
            </span>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userName: state.user.userName,
  user: state.user,
});

export default connect(mapStateToProps)(BuyingJourneyStartTest);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

import TappableCard from '../common/TappableCard';
import { BoldText } from '../common/Text';

import userIcon from '../../images/consultations/me-icon.png';

const OuterContainer = styled(TappableCard)`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  border-radius: 3rem;
  align-items: center;
  padding: 0.25rem;
  background: #F2F2F2;
  border-radius: 8px;
`;

const UserImage = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.375rem;
`;

const UserText = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.5rem;
`;

const ConsultationUser = ({ icon, text, onTap }) => (
  <OuterContainer onTap={onTap}>
    <>
      <UserImage src={userIcon} />
      <UserText>{text || 'Self'}</UserText>
      <FaAngleDown />
    </>
  </OuterContainer>
);

ConsultationUser.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  onTap: PropTypes.func,
};

export default ConsultationUser;

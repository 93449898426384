import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

import { ButtonText, Text, SemiBoldText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import { activateOpdPolicy } from '../../services';
import config from '../../config';

import { PrimaryButton } from '../../components/common/Buttons';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: hidden;
`;

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  ${'' /* background: #56c02c; */}
  padding: 10px;
`;

const HeaderTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BackContainer = styled(StyledTappable)`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: 14px;
  ${'' /* color: white; */}
`;

const HeaderText = styled(Text)`
  font-size: 14px;
  ${'' /* color: white; */}
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

class LogMealContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      employeeId: '',
      activated: false,
    };
  }

  activateOpdPolicy = () => {
    const { employeeId } = this.state;
    const { authToken } = this.props;
    this.setState({
      loading: true,
    });
    activateOpdPolicy(employeeId, authToken)
      .then(() => {
        setTimeout(() => {
          this.refreshPage();
        }, 2500);
        this.setState({
          activated: true,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  refreshPage = () => {
    window.location.href = config.rootUrl;
  };

  render() {
    const { history } = this.props;
    const { activated, loading, employeeId } = this.state;
    return (
      <OuterContainer>
        <HeaderContainer>
          <HeaderTopContainer>
            <HeaderTitleContainer>
              <BackContainer onTap={history.goBack}>
                <StyledArrow />
              </BackContainer>
              <HeaderText>Activate your OPD Benefits</HeaderText>
            </HeaderTitleContainer>
          </HeaderTopContainer>
          {/* <SearchBoxContainer>
            <FaSearch />
            <StyledInputBox
              valueLink={searchTextLink}
              className="search-input"
              placeholder="Search for your health issues"
            />
          </SearchBoxContainer>
          {selectedDishes.length === 0 ? (
            <SelectedDishesTitleText>
              No foods added yet
            </SelectedDishesTitleText>
          ) : (
            <SelectedDishesContainer>
              {selectedDishes.map(renderSelectedDish)}
            </SelectedDishesContainer>
          )} */}
        </HeaderContainer>
        <BodyContainer className="pt-20">
          {activated ? (
            <>
              <SemiBoldText fontSize="18px" className="text-center">
                Your benefits have been activated!
              </SemiBoldText>
              <Text fontSize="14px" className="text-center mt-8">
                This page will auto redirect you to the home page. If it does
                not, please&nbsp;
                <span
                  style={{
                    cursor: 'pointer',
                    color: '#4f34d2',
                    textDecoration: 'underline',
                  }}
                  onClick={this.refreshPage}
                >
                  click here
                </span>
                .
              </Text>
            </>
          ) : (
            <>
              <SemiBoldText fontSize="14px">
                Enter your employee ID to activate OPD Benefits
              </SemiBoldText>
              <input
                className="reimbursement-input mt-10"
                type="text"
                placeholder="Your Employee ID"
                onChange={(e) => {
                  this.setState({
                    employeeId: e.target.value,
                  });
                }}
              />
              <PrimaryButton
                disabled={
                  loading || employeeId.trim().length === 0 || activated
                }
                loading={loading}
                className="mt-16"
                onTap={() => this.activateOpdPolicy()}
              >
                <ButtonText>Activate Benefits</ButtonText>
              </PrimaryButton>
            </>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(LogMealContainer);

import React, { Component } from 'react';
import styled from 'styled-components';
import WaterIcon from '../../images/home/water_logo.svg';
import WaterLogMinus from '../../images/home/water_glass_minus_filled.png';
import WaterLogPlus from '../../images/home/water_glass_plus_filled.png';
import ViewDoc from '../../images/home/view_details_doc.svg';
import { Text } from '../common/Text';
import ProgressBar from '../../components/common/WaterProgressBar';
import {
  addWater,
  getWaterLogDetails,
  removeWater,
} from '../../services/water-log';

const OuterTappable = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border-radius: 8px;
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 24px;
  height: 52px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
`;

const TitleText = styled(Text)`
  float: left;
  margin-right: 0.5rem;
  color: #21243c;
  margin-left: 7px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: inline-block;
  color: #21243c;
`;

const WaterLogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f5f5f5;
`;

const ProgressContainer = styled.div`
  width: 70%;
  top: 28px;
  position: absolute;
  margin-left: 14%;
  margin-right: 15%;
  height: 20px;
`;

const Tags = styled.p`
  color: #21243c;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
`;

const ViewDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: #916ef7;
  font-weight: 600;
`;

const ModifyContainer = styled.div``;

export default class WaterLogCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: 0,
      addLabel: null,
      removeLabel: null,
      vesselGoal: 0,
      selectedVesselKey: null,
      addVeselIcon: null,
      removeVeselIcon: null,
      goalPercentage: 0,
    };
  }

  componentDidMount() {
    this.getWaterLogDetails();
  }

  getWaterLogDetails = () => {
    getWaterLogDetails(this.props.authToken)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const loggingCard = data.loggingCard;
        this.setState({
          goal: loggingCard.goal,
          addLabel: loggingCard.addLabel,
          removeLabel: loggingCard.removeLabel,
          selectedVesselKey: loggingCard.selectedVesselKey,
          vesselGoal: loggingCard.vesselGoal,
          goalPercentage: loggingCard.goalPercentage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  decreaseWater = () => {
    removeWater(this.props.authToken)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        this.setState({
          addLabel: data.addLabel,
          removeLabel: data.removeLabel,
          goalPercentage: data.goalPercentage,
          vesselGoal: data.vesselGoal,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  increaseWater = () => {
    addWater(this.props.authToken)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        this.setState({
          addLabel: data.addLabel,
          removeLabel: data.removeLabel,
          goalPercentage: data.goalPercentage,
          vesselGoal: data.vesselGoal,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      goal,
      addLabel,
      removeLabel,
      selectedVesselKey,
      vesselGoal,
      goalPercentage,
    } = this.state;
    return (
      <OuterTappable className="rounded-12 border border-txtsecondary-shades7 shadow-card">
        <OuterContainer>
          <TitleContainer>
            <div className="flex-center mb-4">
              <img src={WaterIcon} alt="" width={'20px'} height={'20px'} />
              <TitleText>Water Log</TitleText>
            </div>
          </TitleContainer>
        </OuterContainer>
        <div className="relative">
          <WaterLogContainer>
            <ModifyContainer
              onClick={() => {
                if (removeLabel != '0ml') {
                  this.decreaseWater();
                }
              }}
            >
              <img src={WaterLogMinus} alt="" width={'30px'} height={'30px'} />
              <Tags>{removeLabel}</Tags>
            </ModifyContainer>
            <div>{/* <ProgressBar width={`${100}%`} /> */}</div>
            <ModifyContainer onClick={this.increaseWater}>
              <img src={WaterLogPlus} alt="" width={'30px'} height={'30px'} />
              <Tags>{addLabel}</Tags>
            </ModifyContainer>
          </WaterLogContainer>
          <ProgressContainer>
            <ProgressBar
              width={`${
                goalPercentage <= 1 ? Math.floor(goalPercentage * 100) : 100
              }%`}
            />
            <Tags
              style={{
                textAlign: 'center',
                color: '#47464a',
                marginTop: '8px',
                fontSize: '14px',
              }}
            >
              Goal : <span>{goal}ml</span> | <span>{vesselGoal}</span> Glasses
            </Tags>
          </ProgressContainer>
        </div>
        <ViewDetails onClick={() => this.props.history.push('/water-details')}>
          <img
            src={ViewDoc}
            alt="view_doc"
            width="20px"
            height="20px"
            style={{ marginRight: '4px' }}
          />
          View Details
        </ViewDetails>
      </OuterTappable>
    );
  }
}

export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const reverseDebounce = (func, wait, immediate) => {
  let disableFuncCall = false;
  return (...args) => {
    if (!disableFuncCall || immediate) {
      func.apply(this, args);
      disableFuncCall = true;
      setTimeout(() => {
        disableFuncCall = false;
      }, wait);
    }
  };
};

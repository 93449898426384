import styled from 'styled-components';

const CardContainer = styled.div`
  border-radius: 0.875rem;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
`;

export default CardContainer;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';

const OuterTappable = styled(TappableCard)`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex: 1 0 auto;
  // flex-direction: column;
  align-items: space-between;
  align-self: stretch;
`;

const OuterContainer = styled.div`
  padding: 0.675rem 0.625rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  margin-left: 1rem;
`;

const TitleText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  float: left;
  margin-right: 0.5rem;
  color: #460299;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #b3b3b3;
`;

const PolicyInfoCard = ({ onTap, title }) => (
  <OuterTappable onTap={onTap}>
    <OuterContainer>
      <TitleContainer>
        <div>
          <TitleText>{title}</TitleText>
        </div>
      </TitleContainer>
      <ArrowIcon />
    </OuterContainer>
  </OuterTappable>
);

PolicyInfoCard.propTypes = {
  onTap: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default PolicyInfoCard;

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import CovidActive from './CovidActive';
import CovidHome from './CovidHome';
import CovidSwiggyHome from './CovidSwiggyHome';
import CovidOrderSummary from './CovidOrderSummary';
import CovidSwiggyActive from './CovidSwiggyActive';
import CovidGartnerHome from './CovidGartnerHome';
const CovidHomeNavigator = ({ loginState, authToken, history }) => {
  // if (loginState !== 'LOGGED_IN' || !authToken) {
  //   history.push('/');
  //   return false;
  // }
  console.log(CovidOrderSummary);
  return (
    <Switch>
      <Route exact path="/covid-home" component={CovidHome} />
      <Route exact path="/covid-swiggy" component={CovidSwiggyHome} />
      <Route exact path="/covid-gartner" component={CovidGartnerHome} />
      <Route exact path="/covid-swiggy/active" component={CovidSwiggyActive} />
      <Route exact path="/covid-home/active" component={CovidActive} />
      <Route
        exact
        path="/covid-home/order-summary"
        component={CovidOrderSummary}
      />
      <Redirect to="/covid-home" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(CovidHomeNavigator);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { FiPlusCircle } from 'react-icons/fi';
import { TiCameraOutline } from 'react-icons/ti';

import StyledTappable from '../../components/common/StyledTappable';
import TappableCard from '../../components/common/TappableCard';
import UploadingComponent from '../../components/common/UploadingComponent';
import {
  ButtonText,
  RegularText,
  BoldText,
  Text,
} from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';
import { fontSize } from '../../utils';

import uploadPrescriptionIcon from '../../images/consultations/upload-prescription.svg';
import { uploadFileAttachment } from '../../services';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background: white;
  text-align: center;
  padding: 18px;
  overflow: hidden;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const CloseIcon = styled(FaTimes)`
  align-self: flex-end;
  color: #909090;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const PrescriptionIcon = styled.img`
  width: 78px;
  height: 78px;
  align-self: center;
  margin-top: 40px;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => fontSize(1.125)};
  margin-top: 12px;
  margin-bottom: 8px;
`;

const DoctorImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  align-self: center;
  margin-top: 18px;
`;

const DoctorName = styled(BoldText)`
  font-size: ${() => fontSize(1)};
`;

const DateText = styled(Text)`
  font-size: ${() => fontSize(0.875)};
`;

const SubtitleText = styled(RegularText)`
  opacity: 0.7;
  font-size: ${() => fontSize(1)};
  margin-top: 18px;
`;

const StyledButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
`;

const CameraIcon = styled(TiCameraOutline)`
  color: white;
  font-size: ${() => fontSize(1.125)};
  margin-right: 10px;
`;

const AttachImageContainer = styled(TappableCard)`
  position: relative;
  flex-shrink: 0;
  height: 6.125rem;
  width: 6.875rem;
  margin: 0 0.5rem;
  background-image: ${props => `url(${props.fileUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const AttachMoreContainer = styled(AttachImageContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AddIcon = styled(FiPlusCircle)`
  font-size: ${() => fontSize(1.5)};
  color: #8852cc;
`;

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 1.25rem;
  padding-bottom: 1rem;
`;

class UploadPrescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: [],
      uploading: false,
      uploadProgress: 0,
    };
  }

  uploadPrescription = () => {
    document.querySelector('#photoAttachment').click();
  };

  handleAttachment() {
    const { userId, doctorId, consultationId, authToken } = this.props;
    const inputElem = document.querySelector('#photoAttachment');

    const file = inputElem.files[0];
    if (!file) {
      return;
    }

    const onUploadProgress = progressEvent => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        this.setState({
          uploadProgress: (loaded / total) * 100,
        });
      } else {
        this.setState({
          uploadProgress: 100,
        });
      }
    };
    this.setState({ uploading: true });
    uploadFileAttachment(
      file,
      userId,
      consultationId,
      doctorId,
      true,
      onUploadProgress,
      authToken,
    )
      .then(() => {
        const { uploadedFiles } = this.state;
        const fileReader = new FileReader();

        fileReader.onload = e => {
          this.setState({
            uploadedFiles: Array.from(uploadedFiles).concat(e.target.result),
          });
        };
        fileReader.readAsDataURL(file);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          uploading: false,
          uploadProgress: 0,
        });
      });
  }

  render() {
    const {
      consultationId,
      doctorImg,
      doctorName,
      dateLabel,
      onClose,
      onSubmit,
    } = this.props;
    const { uploading, uploadProgress, uploadedFiles } = this.state;
    const renderUploadedFile = (ele, idx) => {
      return (
        <AttachImageContainer
          key={idx}
          fileUrl={ele}
          onTap={() => {
            document.querySelector(`#image-anchor-${idx}`).click();
          }}
        />
      );
    };
    return (
      <OuterContainer>
        {uploading && (
          <UploadingComponent
            uploadProgress={`${uploadProgress}%`}
            borderRadius={0}
          />
        )}
        <BodyContainer>
          <CloseContainer onTap={onClose}>
            <CloseIcon />
          </CloseContainer>
          <PrescriptionIcon src={uploadPrescriptionIcon} />
          <TitleText>Upload your Prescription</TitleText>
          <RegularText>
            To validate your Cashless Consultation, please upload your
            prescription.
          </RegularText>
          <DoctorImage src={doctorImg} />
          <DoctorName>{doctorName}</DoctorName>
          <DateText>{dateLabel}</DateText>
          <SubtitleText>
            To validate your Cashless Consultation, please upload your
            prescription.
          </SubtitleText>
        </BodyContainer>
        {uploadedFiles.length > 0 ? (
          <>
            <AttachedFilesContainer>
              <>
                {uploadedFiles.map(renderUploadedFile)}
                <AttachMoreContainer onTap={this.uploadPrescription}>
                  <AddIcon />
                  <Text>Add more</Text>
                </AttachMoreContainer>
              </>
            </AttachedFilesContainer>
            <StyledButton onTap={() => onSubmit(consultationId)}>
              <ButtonText>Submit</ButtonText>
            </StyledButton>
          </>
        ) : (
          <StyledButton onTap={this.uploadPrescription}>
            <CameraIcon />
            <ButtonText>Upload photos of prescription</ButtonText>
          </StyledButton>
        )}
        <input
          type="file"
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={() => this.handleAttachment()}
        />
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(UploadPrescription);

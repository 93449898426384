import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchVideoSessionDetailByRoomId } from '../../services';
export class VideoCallRedirector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    console.log(params.get('r'));
    if (params.get('r')) {
      fetchVideoSessionDetailByRoomId(params.get('r')).then((res) => {
        console.log(res);
        this.props.history.push('/video-call', {
          sessionId: res.roomDetails.sessionId,
          token: res.roomDetails.token,
          roomName: res.roomDetails.roomName,
          authToken: this.props.authToken,
          userId: res.roomDetails.userId,
          userName: res.roomDetails.userName,
          doctorName: res.roomDetails.doctorName,
          profileImg: res.roomDetails.doctorProfileImage,
          doctorId: res.roomDetails.doctorId,
          disableGoBack: true,
        });
      })
      .catch((err)=>{
          console.log(err)
      })
    }
  }
  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(VideoCallRedirector);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { BoldText, ButtonText } from '../common/Text';
import { FitcoinIcon, FitcoinContainer } from '../common/Fitcoin';
import { PrimaryButton } from '../common/Buttons';

import referIcon from '../../images/home/refer-icon.png';
import giftIcon from '../../images/home/reward-gift.png';
import referGraphic from '../../images/home/refer-graphic.png';
import fitCoinWhite from '../../images/home/fitcoin-white.png';
import referBackground from '../../images/home/refer-background.png';

const OuterTappable = styled(TappableCard)`
  margin: 0.4rem 0.5rem;
`;

const OuterContainer = styled.div`
  padding: 0.5rem 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeadContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const HeadLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 150px;
`;

const GraphicIcon = styled.img`
  height: 4.3125rem;
  margin-top: 0.625rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleIcon = styled.img`
  float: left;
  width: 1rem;
  margin-right: 0.5rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  float: left;
  margin-right: 0.5rem;
`;

const SubtitleText = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-top: 0.25rem;
`;

const EarnText = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #c6b000;
  float: left;
`;

const BadgeContainer = styled(FitcoinContainer)`
  align-self: stretch;
  justify-content: space-around;
  padding: 0.25rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.875rem;
`;

const BadgeIcon = styled.img`
  height: 1.1875rem;
  margin-right: 0.5rem;
`;

const BadgeText = styled(BoldText)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  float: left;
`;

const CardButtonText = styled(ButtonText)`
  color: white;
`;

const ReferCard = ({ onTap }) => (
  <OuterTappable onTap={onTap}>
    <OuterContainer>
      <HeadContainer>
        <HeadLeftContainer>
          <SubtitleText>Invite your colleagues to Visit App!</SubtitleText>
        </HeadLeftContainer>
        <div style={{ background: `url(${referBackground})` }}>
          <GraphicIcon src={referGraphic} alt="Refer Graphic" />
        </div>
      </HeadContainer>
      <BadgeContainer>
        <BadgeText>Refer & Earn</BadgeText>
        <div className="vertical-line" style={{ marginRight: '0' }} />
        <div className="txt-white-600-12 center" style={{ margin: 0 }}>
          <span>You both get</span>{' '}
          <img className="ml-3 mr-3" src={fitCoinWhite} alt="fitcoin" />{' '}
          <span>20</span>
        </div>
      </BadgeContainer>

      {/* <PrimaryButton onTap={onTap}>
        <CardButtonText>Invite Now</CardButtonText>
      </PrimaryButton> */}
    </OuterContainer>
    <div className="txt-dblue-600-12 hover refer-footer" onClick={onTap}>
      Invite Now
    </div>
  </OuterTappable>
);

ReferCard.propTypes = {
  onTap: PropTypes.func,
};

export default ReferCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FaChevronRight,
  FaChevronLeft,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';

import Header from '../../components/common/Header';
import StyledTappable from '../../components/common/StyledTappable';
import {
  Text,
  ButtonText,
  BoldText,
  RegularText,
} from '../../components/common/Text';
import ProgressBar from '../../components/common/ProgressBar';
import { fontSize } from '../../utils';
import { PrimaryButton } from '../../components/common/Buttons';
import LoadingComponent from '../../components/common/LoadingComponent';

import mealLogIcon from '../../images/meal-log/meallogicon.png';
import carbsIcon from '../../images/meal-log/carbs.png';
import fatsIcon from '../../images/meal-log/fats.png';
import proteinsIcon from '../../images/meal-log/proteins.png';
import breakfastIcon from '../../images/meal-log/breakfast.svg';
import morningsnacksIcon from '../../images/meal-log/morningsnacks.svg';
import lunchIcon from '../../images/meal-log/lunch.svg';
import eveningsnacksIcon from '../../images/meal-log/eveningsnacks.svg';
import dinnerIcon from '../../images/meal-log/dinner.svg';
import { getNutritionDetails } from '../../services';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 16px;
`;

const ArrowIcon = styled(FaChevronLeft)`
  color: #212121;
`;

const DateSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

const DateText = styled(Text)`
  font-size: 14px;
  margin-right: 0.5rem;
`;

const TotalCalorieContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalCalorieText = styled(BoldText)`
  font-size: 14px;
  color: #2a2a2a;
`;

const RemainingCalorieText = styled(RegularText)`
  font-size: 14px;
  color: #2a2a2a;
`;

const NutritionContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

const MealLogIcon = styled.img`
  width: 30px;
  height: 38px;
`;

const NutrientContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const NutrientIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const NutrientText = styled(BoldText)`
  font-size: 14px;
  color: #2a2a2a;
  margin-top: 16px;
`;

const NutrientLabel = styled(RegularText)`
  font-size: 14px;
  color: #2a2a2a;
  margin-top: 10px;
`;

const StyledButton = styled(PrimaryButton)`
  background: #56c02c;
  margin: 20px 0;
`;

const LogTitleText = styled(BoldText)`
  font-size: 16px;
  text-align: center;
  margin-bottom: 14px;
`;

const MealContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  border-top: 1px solid #7d7d80;
  padding: 12px;
`;

const LogLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const LogTitle = styled(Text)`
  font-size: 14px;
`;

const LogIcon = styled.img`
  width: 18px;
  height: 13px;
  margin-right: 15px;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CalorieText = styled(RegularText)`
  font-size: 14px;
  color: #808080;
`;

const ExpandIcon = styled(FaChevronUp)`
  color: #3c3c3c;
  font-size: 12px;
  margin-left: 10px;
`;

const MealText = styled(RegularText)`
  font-size: 14px;
  color: #4c4c4b;
`;

const getImageforMeal = (meal) => {
  switch (meal) {
    case 'Breakfast':
      return breakfastIcon;
    case 'Morning Snacks':
      return morningsnacksIcon;
    case 'Lunch':
      return lunchIcon;
    case 'Evening Snacks':
      return eveningsnacksIcon;
    case 'Dinner':
      return dinnerIcon;
    default:
      return breakfastIcon;
  }
};

class MealLogContainer extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.state = {
      loading: true,
      selectedDateTimestamp: today.valueOf(),
      mealData: [],
      selectedMeal: '',
      totalCals: 0,
      remainingCals: 0,
      totalProgress: 0,
      totalCarbs: 0,
      carbsGoal: 0,
      carbsProgress: 0,
      totalProtein: 0,
      proteinGoal: 0,
      proteinProgress: 0,
      totalFats: 0,
      fatsGoal: 0,
      fatsProgress: 0,
    };
  }

  componentDidMount() {
    logEvent('Meal Tracking Dashboard Card Detail Screen');
    this.getNutritionData();
  }

  getNutritionData = () => {
    const { userId, authToken } = this.props;
    const { selectedDateTimestamp } = this.state;
    this.setState({ loading: true });
    getNutritionDetails(selectedDateTimestamp, userId, authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          loading: false,
          mealData: res.meals,
          totalCals: res.totalToday,
          remainingCals: res.remainingCals,
          totalProgress: res.userProgress,
          totalCarbs: res.totalMacroNutrients.totalCarbohydrates,
          carbsGoal: res.carbsGoal,
          carbsProgress: res.carbsProgress,
          totalProtein: res.totalMacroNutrients.totalProtein,
          proteinGoal: res.proteinGoal,
          proteinProgress: res.proteinProgress,
          totalFats: res.totalMacroNutrients.totalFat,
          fatsGoal: res.fatsGoal,
          fatsProgress: res.fatsProgress,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setPreviousDate = () => {
    const { selectedDateTimestamp } = this.state;
    this.setState(
      {
        selectedDateTimestamp: selectedDateTimestamp - 1000 * 60 * 60 * 24,
      },
      () => {
        this.getNutritionData();
      }
    );
  };

  setNextDate = () => {
    const { selectedDateTimestamp } = this.state;
    this.setState(
      {
        selectedDateTimestamp: selectedDateTimestamp + 1000 * 60 * 60 * 24,
      },
      () => {
        this.getNutritionData();
      }
    );
  };

  expandMeal(mealTitle) {
    this.setState({
      selectedMeal: mealTitle,
    });
  }

  logMeal = () => {
    const { selectedDateTimestamp } = this.state;
    const { history } = this.props;
    history.push('/logmeal/select', {
      dayTimestamp: selectedDateTimestamp,
    });
  };

  render() {
    const {
      loading,
      selectedDateTimestamp,
      totalCals,
      remainingCals,
      totalProgress,
      totalCarbs,
      carbsGoal,
      carbsProgress,
      totalProtein,
      proteinGoal,
      proteinProgress,
      totalFats,
      fatsGoal,
      fatsProgress,
      mealData,
    } = this.state;
    const renderDateText = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      switch (today.valueOf() - selectedDateTimestamp) {
        case 0:
          return 'Today';
        case 1000 * 60 * 60 * 24:
          return 'Yesterday';
        default: {
          const date = new Date(selectedDateTimestamp);
          const options = {
            day: 'numeric',
            month: 'long',
          };
          return `${date.toLocaleDateString('nu', options)}`;
        }
      }
    };

    const renderMealItem = (ele) => {
      return (
        <LogLine>
          <MealText>{`${ele.dishName} x ${ele.quantity}`}</MealText>
          <CalorieText>{`${ele.totalCalories} Cal`}</CalorieText>
        </LogLine>
      );
    };

    const renderMeal = (ele) => {
      const isExpanded = this.state.selectedMeal === ele.title;
      return (
        <MealContainer>
          <LogLine as={StyledTappable} onTap={() => this.expandMeal(ele.title)}>
            <RowContainer>
              <LogIcon src={getImageforMeal(ele.title)} />
              <LogTitle>{ele.title}</LogTitle>
            </RowContainer>
            <RowContainer>
              <CalorieText>{`${ele.total} Cal`}</CalorieText>
              {isExpanded ? <ExpandIcon /> : <ExpandIcon as={FaChevronDown} />}
            </RowContainer>
          </LogLine>
          {isExpanded &&
            (ele.items.length === 0 ? (
              <LogLine>
                <MealText>No Meals Logged yet</MealText>
              </LogLine>
            ) : (
              ele.items.map(renderMealItem)
            ))}
        </MealContainer>
      );
    };

    return (
      <OuterContainer>
        <Header color="#56c02c" title="Your Nutrition Data" />
        <BodyContainer>
          {loading ? (
            <LoadingComponent />
          ) : (
            <>
              <DateSwitchContainer>
                <StyledTappable onTap={() => this.setPreviousDate()}>
                  <ArrowIcon />
                </StyledTappable>
                <DateText>{renderDateText()}</DateText>
                <StyledTappable onTap={() => this.setNextDate()}>
                  <ArrowIcon as={FaChevronRight} />
                </StyledTappable>
              </DateSwitchContainer>
              <NutritionContainer>
                <TotalCalorieContainer>
                  <TotalCalorieText>{`${totalCals} of ${
                    totalCals + remainingCals
                  } Cals`}</TotalCalorieText>
                  <RemainingCalorieText as="span">
                    <BoldText as="span">{`${remainingCals} Cals`}</BoldText>{' '}
                    left for today
                  </RemainingCalorieText>
                </TotalCalorieContainer>
                <MealLogIcon src={mealLogIcon} />
              </NutritionContainer>
              <ProgressBar width={`${totalProgress * 100}%`} />
              <NutritionContainer>
                <NutrientContainer>
                  <NutrientIcon src={carbsIcon} />
                  <NutrientText>{`${totalCarbs}/${carbsGoal}g`}</NutrientText>
                  <ProgressBar height="3px" width={`${carbsProgress * 100}%`} />
                  <NutrientLabel>Carbs</NutrientLabel>
                </NutrientContainer>
                <NutrientContainer>
                  <NutrientIcon src={proteinsIcon} />
                  <NutrientText>{`${totalProtein}/${proteinGoal}g`}</NutrientText>
                  <ProgressBar
                    height="3px"
                    width={`${proteinProgress * 100}%`}
                  />
                  <NutrientLabel>Proteins</NutrientLabel>
                </NutrientContainer>
                <NutrientContainer>
                  <NutrientIcon src={fatsIcon} />
                  <NutrientText>{`${totalFats}/${fatsGoal}g`}</NutrientText>
                  <ProgressBar height="3px" width={`${fatsProgress * 100}%`} />
                  <NutrientLabel>Fats</NutrientLabel>
                </NutrientContainer>
              </NutritionContainer>
              <StyledButton onTap={this.logMeal}>
                <ButtonText>+ log meal</ButtonText>
              </StyledButton>
              <LogTitleText>Meal logs</LogTitleText>
              {mealData.map(renderMeal)}
            </>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(MealLogContainer);

import { store } from '../store/index';

export const showError = (err) => {
  let errorTitle = 'Alert';
  let errorDesc =
    'We were unable to fulfill your request. Please try again later.';

  if (err.response && err.response.data && err.response.data.errorMessage) {
    errorDesc = err.response.data.errorMessage;
  } else if (err && err.data && err.data.errorMessage) {
    errorDesc = err.data.errorMessage;
  }

  if (err.response && err.response.data && err.response.data.errorHeader) {
    errorTitle = err.response.data.errorHeader;
  } else if (err && err.data && err.data.errorHeader) {
    errorTitle = err.data.errorHeader;
  }
  if (err?.config?.method?.toUpperCase() === 'OPTIONS') {
    // Preflight Request Error handling
    console.error('Preflight Request Error: ', err);
    return;
  }
  // CORS-specific error handling
  if (err.message && err.message?.includes('Network Error')) {
    console.error('CORS Error: ', err);
    return;
  }
  console.log({ err, errorTitle, errorDesc });

  store.dispatch({
    type: 'SHOW_ERROR',
    payload: {
      errorTitle: errorTitle,
      errorDesc: errorDesc,
    },
  });
};

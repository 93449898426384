import React, { Component } from 'react';
import Modal from '../../containers/common/Modal';
import crossIcon from '../../images/common/cross.svg';
import { FlyInTransition } from '../transitions/transitions';
import { connect } from 'react-redux';

class ClaimsKnowMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      knowMoredata: [],
    };
    console.log('knowMore', props);
  }

  componentDidMount() {
    this.setState({
      knowMoredata: this.props.knowMoreDetailsData,
    });
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      (this.node && this.node.contains(e.target)) ||
      (this.node2 && this.node2.contains(e.target))
    ) {
      return;
    }
    this.hideModal();
  };

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };
  render() {
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-12 overflow-auto"
              ref={(node) => (this.node = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                    Documents Verified
                  </span>
                </div>
                <img
                  src={crossIcon}
                  alt="cross"
                  className="hover"
                  onClick={this.hideModal}
                />
              </div>
              <div className="p-20">
                {this.props.data && this.props.data.length > 0
                  ? this.props.data.map((ele, idx) => {
                      return (
                        <div
                          className={`mb-12px  border-b border-txtsecondary-shades4 ${
                            idx > 0 ? '' : ''
                          }`}
                        >
                          <span className="font-medium text-body-s  text-txtsecondary ">
                            {/* {this.props.knowMoreDetailsData.itemName} */}
                            {ele.itemName}
                          </span>
                          <div className="">
                            <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-12px">
                              <p className="text-body-l font-semibold text-txtlight">
                                Invoice Amount
                              </p>
                              <p className="text-body-l font-semibold text-txtlight">
                                ₹ {ele.itemPrice}
                              </p>
                            </div>
                            <div className="flex-start-end align-items-center py-12px">
                              <p className="text-body-l font-semibold text-txtlight">
                                Approved Amount
                              </p>
                              <p
                                className={`text-body-l font-semibold ${
                                  ele.itemPrice == ele.approvedAmount
                                    ? 'text-custom1eb865'
                                    : 'text-accent2-shades4'
                                }`}
                              >
                                ₹ {ele.approvedAmount}
                              </p>
                            </div>
                            {ele.remarks ? (
                              <div className="mb-16px bg-accent2-shades2 bg-opacity-50 rounded-12 p-12px text-body-l text-txtlight font-regular">
                                <p className="text-body-s text-txtlight font-semibold">
                                  Remarks
                                </p>
                                <p className="pt-4px text-body-s text-txtlight font-regular">
                                  {ele.remarks}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ClaimsKnowMore);

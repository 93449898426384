import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function getOrderSummary(transactionId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/subscription/order-summary`, {
      headers,
      params: {
        transactionId,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPreOrderSummary(subPlanId, planId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/subscription/preorder-summary`, {
      headers,
      params: {
        planId,
        subPlanId,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addRelative(relativeDetails, transactionId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/subscription/add-member`,
      {
        transactionId,
        dependentName: relativeDetails.name,
        dependentRelation: relativeDetails.relation,
        dependentGender: relativeDetails.gender,
        dependentMobile: relativeDetails.mobileNumber,
        dependentDob: relativeDetails.dateOfBirth,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addDependentsToPlan(dependentIds, transactionId) {
  const state = store.getState();
  const headers = {
    authorization: state.user.authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/subscription/add-members-to-plan`,
      {
        transactionId,
        dependentIds,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { Text } from '../common/Text';
import { FaChevronRight, FaAngleDown, FaAngleUp } from 'react-icons/fa';

import StatusIconPng from '../../images/lab-test/status.png';
import placeholderDoctor from '../../images/consultations/placeholderDoctor.png'
const OrderContainer = styled(TappableCard)`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 18px;
  margin: 0.5rem 0;
  border: 1px solid #e3e3e3;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d3d6db;
`;

const TitleText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusItem = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

const StatusText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) =>
    props.active || props.completed ? '#0091E5;' : '#A5AAB0'};
  margin-right: 5px;
`;

const PrescriptionStatusCard = ({
  orderRequest,
  onTap,
  dontShowPickupTime,
}) => {
  console.log(dontShowPickupTime);
  const { cardType: type, partnerName: heading, status } = orderRequest;
  const [AllTestVisible, setAllTestVisible] = useState(false);

  // <OrderContainer onTap={onTap}>
  //   <TitleContainer>
  //     <TitleTextContainer>
  //       <TitleText className="txt-black-600-14">
  //         {heading ? heading : type==='pending-digitisation' ? 'Prescription' :doctorName ? doctorName : type}
  //       </TitleText>
  //       <FaChevronRight />
  //     </TitleTextContainer>
  //   </TitleContainer>
  if (type === 'booked-order') {
    if (status === 'payment-pending') {
      return (
        <OrderContainer onTap={onTap}>
          <TitleContainer>
            <TitleTextContainer className="flex flex-row justify-between">
              <TitleText className="txt-black-600-13">
                {orderRequest.doctorName ? (
                  <div className="flex flex-row items-center">
                    <img src={orderRequest.doctorImage || placeholderDoctor} height={"24px"} width={"24px"} className="border-radius-20 mr-8px" />
                    <div className="flex flex-col">
                      <p>{orderRequest.doctorName}</p>
                      <p className="text-chip font-medium">
                        {orderRequest.vertical}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p>{heading}</p>
                )}
              </TitleText>
              <FaChevronRight />
            </TitleTextContainer>
          </TitleContainer>
          <div>
            <div className="flex-start-end mt-13 mb-8">
              <p className="lab-test-name">{orderRequest.testsType}</p>
              <p className="txt-grey-600-12">{orderRequest.bookedOn}</p>
            </div>
            <div className="flex-start-end mt-13 mb-8">
              <p className="txt-black-600-12">
                {orderRequest.tests.length} Tests
              </p>
              <StatusText active={true}>
                {'Additional Payment Pending'}
              </StatusText>
            </div>
            {orderRequest.tests.length === 1 && (
              <div className="tests-container mt-10 rounded-12">
                <p className="txt-black-500-12">{orderRequest.tests[0]}</p>
              </div>
            )}
            {orderRequest.tests.length > 1 && (
              <div className="tests-container mt-10 rounded-12">
                {AllTestVisible ? (
                  <div
                    className="flex-start-end"
                    onClick={() => {
                      setAllTestVisible(false);
                    }}
                  >
                    <div>
                      {orderRequest.tests.map((item, idx) => {
                        return (
                          <p key={idx} className="txt-black-500-12 mt-5">
                            {item}
                          </p>
                        );
                      })}
                    </div>
                    <FaAngleUp />
                  </div>
                ) : (
                  <div
                    className="flex-start-end"
                    onClick={() => {
                      setAllTestVisible(true);
                    }}
                  >
                    <p className="txt-black-500-12">
                      {orderRequest.tests.join(', ').substr(0, 40)}...
                    </p>
                    <FaAngleDown />
                  </div>
                )}
              </div>
            )}
            <div
              style={{ background: '#FFF7E7' }}
              className="rounded-8 p-12px mt-12px text-body-s font-medium text-txlight"
            >
              {orderRequest.pendingCopayText}
            </div>
            <p
              className="txt-black-600-12 lab-home-btn-blue hover"
              style={{ marginTop: '16px' }}
              onClick={() => onTap(orderRequest)}
            >
              Pay & Confirm
            </p>
          </div>
        </OrderContainer>
      );
    }
    return (
      <OrderContainer onTap={onTap}>
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-13">{heading}</TitleText>
            <FaChevronRight />
          </TitleTextContainer>
        </TitleContainer>
        <div>
          <p className="lab-test-name mt-13">
            {orderRequest.testNames?.substring(0, 33)}...
          </p>
          <p className="lab-test-name mt-4 mb-4">{orderRequest.patientName}</p>
          <div
            style={
              !dontShowPickupTime
                ? {
                    paddingBottom: '16px',
                  }
                : {}
            }
          >
            <StatusItem>
              {orderRequest.status === 'pending' ? (
                <>
                  <StatusText active={true}>In-progess</StatusText>
                  <img src={StatusIconPng} alt="status" />
                </>
              ) : (
                <StatusText active={true}>{orderRequest.status}</StatusText>
              )}
            </StatusItem>
            <StatusText>{orderRequest.bookedOn}</StatusText>
          </div>
          {orderRequest.statusDate && !dontShowPickupTime && (
            <div
              className="status-date"
              style={{ borderTop: '1px dashed #d3d6db' }}
            >
              {orderRequest.statusText}{' '}
              <span style={{ color: '#0091E5' }}>
                {orderRequest.statusDate}
              </span>
            </div>
          )}
        </div>
      </OrderContainer>
    );
  }
  if (type === 'pending-digitisation') {
    return (
      <OrderContainer onTap={onTap}>
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-13">Prescription</TitleText>
            <FaChevronRight />
          </TitleTextContainer>
        </TitleContainer>
        <div
          style={{
            paddingTop: '12px',
          }}
        >
          <StatusItem>
            <StatusText active={true}>Digitizing</StatusText>
            <img src={StatusIconPng} alt="status" />
          </StatusItem>
          <StatusText>{orderRequest.date}</StatusText>
        </div>
      </OrderContainer>
    );
  }
  if (type === 'reimbursement') {
    return (
      <OrderContainer>
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-13">Reimbursement</TitleText>
            <FaChevronRight />
          </TitleTextContainer>
        </TitleContainer>
        <div>
          <p className="lab-test-name mt-13">{orderRequest.patientName}</p>
          <p className="txt-grey-600-12 mt-4 mb-4">
            {orderRequest.prescriptionGivenOn}
          </p>

          <p className="txt-black-600-12 lab-home-btn hover" onClick={onTap}>
            {orderRequest.btnLabel}
          </p>
        </div>
      </OrderContainer>
    );
  }
  if (type === 'available-cart') {
    return (
      <OrderContainer onTap={false}>
        <TitleContainer>
          <TitleTextContainer>
            <TitleText className="txt-black-600-12 flex-center">
              <img
                src={orderRequest.doctorImage}
                alt="dr."
                height={20}
                width={20}
              />
              <span className="pl-5">
                <span>{orderRequest.doctorName}</span>
                <br />
                <span className="txt-grey-10">{orderRequest.vertical}</span>
              </span>
            </TitleText>
            <FaChevronRight />
          </TitleTextContainer>
        </TitleContainer>
        <div>
          <div className="flex-start-end mt-13 mb-8">
            <p className="lab-test-name">{orderRequest.testsType}</p>
            <p className="txt-grey-600-12">
              {orderRequest.prescriptionGivenOn}
            </p>
          </div>
          <p className="txt-black-600-12">{orderRequest.tests.length} Tests</p>
          {orderRequest.tests.length === 1 && (
            <div className="tests-container mt-10">
              <p className="txt-black-500-12">{orderRequest.tests[0]}</p>
            </div>
          )}
          {orderRequest.tests.length > 1 && (
            <div className="tests-container mt-10">
              {AllTestVisible ? (
                <div
                  className="flex-start-end"
                  onClick={() => {
                    setAllTestVisible(false);
                  }}
                >
                  <div>
                    {orderRequest.tests.map((item, idx) => {
                      return (
                        <p key={idx} className="txt-black-500-12 mt-5">
                          {item}
                        </p>
                      );
                    })}
                  </div>
                  <FaAngleUp />
                </div>
              ) : (
                <div
                  className="flex-start-end"
                  onClick={() => {
                    setAllTestVisible(true);
                  }}
                >
                  <p className="txt-black-500-12">
                    {orderRequest.tests.join(', ').substr(0, 40)}...
                  </p>
                  <FaAngleDown />
                </div>
              )}
            </div>
          )}
          <p
            className="txt-black-600-12 lab-home-btn-blue hover"
            onClick={() => onTap(orderRequest)}
          >
            Book Test
          </p>
        </div>
      </OrderContainer>
    );
  } else {
    return <div />;
  }
};

PrescriptionStatusCard.propTypes = {
  onTap: PropTypes.func,
  orderRequest: PropTypes.object.isRequired,
};

export default PrescriptionStatusCard;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import { Text, BoldText } from './Text';
import { fontSize } from '../../utils';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelText = styled(Text)`
  margin-top: 0.625rem;
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const StyledInput = styled(Input)`
  font-size: ${fontSize(1)};
  margin-top: 0.25rem;
  border-bottom: 1px solid #000 !important;
  padding-bottom: 0.375rem;
  text-align: center;
  width: ${props => (props.width ? props.width : 'auto')};

  ::placeholder {
    opacity: 0.2;
  }
`;

const InvalidText = styled(BoldText)`
  color: #ff704d;
  font-size: ${fontSize(1)};
`;

class DobInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValue: '',
      invalidDate: false,
      monthValue: '',
      invalidMonth: false,
      yearValue: '',
      invalidYear: false,
      invalidDob: false,
    };
    if (this.props.dob) {
        this.state.yearValue = moment(this.props.dob).format('YYYY');
        this.state.monthValue = moment(this.props.dob).format('MM');
        this.state.dateValue = moment(this.props.dob).format('DD');
    }
  }

  render() {
    const dateValueLink = Link.state(this, 'dateValue').onChange(val => {
      if (val.toString().trim() === '') {
        document.querySelector('#date-input').focus();
      } else if (val.toString().trim().length === 2) {
        document.querySelector('#month-input').focus();

        if (Number.isNaN(val)) {
          this.setState({
            invalidDate: true,
          });
        } else {
          const numericDate = Number.parseInt(val);
          console.log(numericDate);
          if (numericDate <= 0 || numericDate >= 32) {
            this.setState({
              invalidDate: true,
            });
          } else {
            this.setState({
              invalidDate: false,
            });
            // Check if the date of birth is valid
            const { yearValue, monthValue } = this.state;
            // Check only if the other fields are non-empty
            if (
              yearValue.trim() === '' ||
              monthValue.trim() === '' ||
              Number.isNaN(yearValue) ||
              Number.isNaN(monthValue)
            ) {
              return;
            }
            const { onChange } = this.props;
            const dobValue = new Date(
              Number.parseInt(yearValue),
              Number.parseInt(monthValue) - 1,
              Number.parseInt(val),
            );
            if (
              dobValue &&
              dobValue.getMonth() + 1 === Number.parseInt(monthValue)
            ) {
              this.setState({ invalidDob: false });
              onChange && onChange(dobValue);
            } else {
              this.setState({
                invalidDob: true,
              });
            }
          }
        }
      }
    });
    const monthValueLink = Link.state(this, 'monthValue').onChange(val => {
      if (val.toString().trim() === '') {
        document.querySelector('#month-input').focus();
      } else if (val.toString().trim().length === 2) {
        document.querySelector('#year-input').focus();

        if (Number.isNaN(val)) {
          this.setState({
            invalidMonth: true,
          });
        } else {
          const numericDate = Number.parseInt(val);
          if (numericDate <= 0 || numericDate > 12) {
            this.setState({
              invalidMonth: true,
            });
          } else {
            this.setState({
              invalidMonth: false,
            });
            // Check if the date of birth is valid
            const { dateValue, yearValue } = this.state;
            const { onChange } = this.props;
            // Check only if the other fields are non-empty
            if (
              yearValue.trim() === '' ||
              dateValue.trim() === '' ||
              Number.isNaN(yearValue) ||
              Number.isNaN(dateValue)
            ) {
              return;
            }
            const dobValue = new Date(
              Number.parseInt(yearValue),
              Number.parseInt(val) - 1,
              Number.parseInt(dateValue),
            );
            if (dobValue && dobValue.getMonth() + 1 === Number.parseInt(val)) {
              this.setState({ invalidDob: false });
              onChange && onChange(dobValue);
            } else {
              this.setState({
                invalidDob: true,
              });
            }
          }
        }
      }
    });
    const yearValueLink = Link.state(this, 'yearValue').onChange(val => {
      if (val.toString().trim().length === 4) {
        if (Number.isNaN(val)) {
          this.setState({
            invalidYear: true,
          });
        } else {
          const numericYear = Number.parseInt(val);
          const today = new Date();
          if (numericYear <= 1900 || numericYear >= today.getFullYear() - 18) {
            this.setState({
              invalidYear: true,
            });
          } else {
            this.setState({
              invalidYear: false,
            });
            // Check if the date value is correct
            const { dateValue, monthValue } = this.state;
            const { onChange } = this.props;
            // Check only if the other fields are non-empty
            if (
              monthValue.trim() === '' ||
              monthValue.trim() === '' ||
              Number.isNaN(monthValue) ||
              Number.isNaN(monthValue)
            ) {
              return;
            }
            const dobValue = new Date(
              Number.parseInt(val),
              Number.parseInt(monthValue) - 1,
              Number.parseInt(dateValue),
            );
            if (
              dobValue &&
              dobValue.getMonth() + 1 === Number.parseInt(monthValue)
            ) {
              this.setState({ invalidDob: false });
              onChange && onChange(dobValue);
            } else {
              this.setState({
                invalidDob: true,
              });
            }
          }
        }
      }
    });
    const { className } = this.props;
    const { invalidDate, invalidMonth, invalidYear, invalidDob } = this.state;
    const getInvalidText = () => {
      if (invalidDate) {
        return 'Please enter a valid date';
      } else if (invalidMonth) {
        return 'Please enter a valid month';
      } else if (invalidYear) {
        return 'Please enter a valid year';
      } else {
        return 'Please enter a valid date of birth';
      }
    };
    return (
      <OuterContainer className={className}>
        <LabelText>Date of Birth</LabelText>
        <BodyContainer>
          <StyledInput
            type="tel"
            width="3rem"
            placeholder="DD"
            id="date-input"
            className="visit-input"
            valueLink={dateValueLink}
          />
          <StyledInput
            type="tel"
            width="3rem"
            placeholder="MM"
            id="month-input"
            className="visit-input"
            valueLink={monthValueLink}
          />
          <StyledInput
            type="tel"
            width="6rem"
            placeholder="YYYY"
            id="year-input"
            className="visit-input"
            valueLink={yearValueLink}
          />
        </BodyContainer>
        {(invalidDate || invalidMonth || invalidYear || invalidDob) && (
          <InvalidText>{getInvalidText()}</InvalidText>
        )}
      </OuterContainer>
    );
  }
}

DobInput.propTypes = {
  onChange: PropTypes.func,
};

export default DobInput;

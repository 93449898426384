import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes as RemoveIcon, FaAngleRight } from 'react-icons/fa';

import {
  dashboardApi,
  getConsultationSummary,
  homeTab,
  fetchConsultationBlocker,
} from '../../services';
import { setFitcoinsBalance } from '../../actions';

import LoadingComponent from '../../components/common/LoadingComponent';
import { BoldText, Text, ButtonText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import DashboardActionCard from '../../components/home/DashboardActionCard';
import HealthActionCard from '../../components/home/HealthActionCard';
import HomeActionCard from '../../components/home/HomeActionCard';
import QuickActionCard from '../../components/home/QuickActionCard';
import SubscriptionCard from '../../components/home/SubscriptionCard';
import RewardsCard from '../../components/home/RewardsCard';
import ReferCard from '../../components/home/ReferCard';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import Modal from '../common/Modal';
import UploadPrescription from '../consultation/UploadPrescription';

import treatIcon from '../../images/home/treat.svg';
import fitcoinLine from '../../images/common/fitcoin-line.png';
import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import specialistIcon from '../../images/home/specialist.svg';
import labTestIcon from '../../images/home/lab-test.svg';
import medDelIcon from '../../images/home/med-del.svg';
import mealLogIcon from '../../images/home/meal-log.svg';
import metabolicProfileIcon from '../../images/home/metabolic-profile.svg';
import hraIcon from '../../images/home/hra.png';
import giftIcon from '../../images/common/gift-gold-icon.svg';
import backgroundPattern from '../../images/common/pattern.png';
import { RegularText } from '../../components/common/Text';
import { FitcoinButton } from '../../components/common/Buttons';
import symCheckIcon from '../../images/home/sym.svg';
import labIcon from '../../images/home/lab.svg';
import onlineIcon from '../../images/home/online.svg';
import offlineIcon from '../../images/home/offline.svg';
import medIcon from '../../images/home/med.svg';
import mealIcon from '../../images/home/meal.svg';
import metaIcon from '../../images/home/meta.png';
import PolicyActivationCard from '../../components/home/PolicyActivationCard';
import ImageCard from '../../components/home/ImageCard';
import ChatIcon from '../../images/home/chatIcon.png';
import membersIcon from '../../images/home/members.svg';
import initializeFreshDesk from '../../utils/freshchat';
import { logEvent } from '../../utils/logEvent';
import TinderBenefitsCard from '../../components/home/TinderBenefitsCard';
import WaterLogCard from '../../components/home/WaterLogCard';
import DependantIcon from '../../images/home/dependant.svg';
import PodcastIcon from '../../images/health-content/podcasts/podcast.svg';

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  padding: 0 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  max-width: 100%;
`;

const StyledActionCard = styled(DashboardActionCard)`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0rem')};
  flex: 1 0;
`;

const QuickActionsContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
  overflow: scroll;
`;

const StyledQuickActionCard = styled(QuickActionCard)`
  width: 27%;
  flex: none;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  background-image: ${() => `url(${backgroundPattern})`};
  background-repeat: repeat;
  padding: 0.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  padding: 0.5rem;
`;

const CloseIcon = styled(RemoveIcon)`
  color: white;
`;

const GiftImage = styled.img`
  width: 4rem;
  margin-bottom: 1.125rem;
`;

const LineSeparator = styled.img`
  width: 10rem;
  margin-bottom: 0.875rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
`;

const StepNumber = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  border: 1px solid white;
  color: white;
  width: 2rem;
  height: 1.5rem;
  border-radius: 3rem;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.125rem;
`;

const StepTextTile = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepTextSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepTextSubtitle = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: left;
  opacity: 0.6;
  color: white;
`;

const FitcoinIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.25rem;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #714fff;
`;

const AdditionalCardContainer = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e3e3e3;
`;

const AdditionalCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const PodcastCard = styled.div`
  width: 96%;
  margin-left: 2%;
  background: #3a2ca0;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 21px 16px;
  border-radius: 12px;
  margin-top: 12px;
  height: 88px;
`;

const PodcastHeading = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 4px;
`;

const PodcastDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardApiLoaded: false,
      servicesCards: [],
      fitCoinsCards: [],
      additionalCards: [],
      blockers: {},
      tinderBenefits: null,
      quickActionCards: [
        // {
        //   cardType: 'meal-log',
        //   collapse: false,
        //   pendingConsult: 0,
        //   cardDirective: {
        //     actionType: 'native',
        //     action: { redirectTo: 'meal-log' },
        //   },
        //   labelIcon: 'search',
        //   label: 'Log Meal',
        //   labelInfo: 'Find a doctor nearby',
        //   actionIcon: 'arrow',
        // },
        // {
        //   cardType: 'metabolic-profile',
        //   collapse: false,
        //   pendingConsult: 0,
        //   cardDirective: {
        //     actionType: 'bot-chat',
        //     action: {
        //       entryPoint: 'log-meal-fitness-goal',
        //       evidenceId: 'go',
        //       flowType: 'fitnessGoal',
        //       text: 'Metabolic Profile',
        //     },
        //   },
        //   labelIcon: 'search',
        //   label: 'Metabolic Profile',
        //   labelInfo: 'Find a doctor nearby',
        //   actionIcon: 'arrow',
        // },
      ],
      hraCard: [
        {
          cardType: 'hra-card',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: {
              redirectTo: 'hra',
            },
          },
          labelIcon: 'search',
          label: 'Metabolic Profile',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
      ],
      opdCards: [],
      inviteCards: [],
      showModal: false,
      modalVisible: false,
      showPendingConsultBlocker: false,
      pendingConsult: {
        doctorId: '',
        consultationId: '',
        doctorImg: '',
        doctorName: '',
        dateLabel: '',
      },
      enrollmentCard: [],
      ahcCard: [],
      healthContentCards: [],
    };
  }

  componentDidMount() {
    // GA Tracking
    logEvent('Home Tab');
    this.props.history.goBack();
    this.callDashboardApi();
    // // this.homeTab();
    this.fetchConsultationBlocker(); //please enable this code for consulation pending blocker
  }

  callDashboardApi() {
    const { dispatch } = this.props;
    dashboardApi(this.props.authToken)
      .then((result) => {
        console.log('data', result);
        dispatch(setFitcoinsBalance(result?.userInfo?.walletBalance));
        result.home.forEach((categories) => {
          switch (categories.category) {
            case 'tinder-benefits':
              this.setState({
                tinderBenefits: categories,
              });
              break;
            case 'enrollment-card':
              this.setState({
                enrollmentCard: [categories],
              });
              break;
            case 'ahc':
              this.setState({
                ahcCard: [categories],
              });
              break;
            case 'services':
              this.setState({
                servicesCards: categories.cards,
              });
              break;
            case 'fitness-mind':
              this.setState({
                healthContentCards: categories.cards,
              });
            case 'fit-coins':
              this.setState({
                fitCoinsCards: categories.cards,
              });
              break;
            case 'opd-policy':
              this.setState({
                opdCards: categories.cards,
              });
              break;
            case 'additional-cards': {
              this.setState({
                additionalCards: categories.cards,
              });
              break;
            }
            case 'invite-earn':
              // this.setState({
              //   inviteCards: categories.cards,
              // });
              break;
            default:
              break;
          }
        });
        this.setState({
          dashboardApiLoaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  homeTab = () => {
    homeTab(this.props.authToken)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  startTreament = (
    showSearch,
    pendingConsult,
    cancelledRequest,
    showVerticals,
    verticalId
  ) => {
    const { history, authToken, userId } = this.props;
    if (cancelledRequest > 0) {
      history.push('/consultation/cancel-blocker', {
        requestId: cancelledRequest,
      });
    } else if (pendingConsult > 0) {
      getConsultationSummary(pendingConsult, userId, authToken)
        .then((summary) => {
          const { dateLabel, doctorId, doctorName, doctorImg } =
            summary.consultationInfo;
          this.setState({
            pendingConsult: {
              consultationId: pendingConsult,
              doctorId,
              doctorImg,
              doctorName,
              dateLabel,
            },
            showModal: true,
            showPendingConsultBlocker: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/consultation/doctor-select', {
        specialistSearch: false,
        searchType: 'find',
        showSearch,
        showVerticals: showVerticals
          ? showVerticals
          : verticalId
          ? false
          : true,
        verticalId: verticalId,
      });
    }
  };

  findSpecialist = (pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;

    if (this.state.blockers.cancelledRequest > 0) {
      this.props.history.push('/consultation/cancel-blocker', {
        requestId: this.state.blockers.cancelledRequest,
        goBack: true,
      });
    } else if (this.state.blockers.pendingRequestId > 0) {
      this.props.history.push('/consultation/pending-blocker', {
        requestId: this.state.blockers.pendingRequestId,
        goBack: true,
      });
    } else {
      history.push('/consultation/start', {
        specialistSearch: false,
        searchType: 'find',
      });
    }
  };

  bookTests = () => {
    const { history } = this.props;
    history.push('/lab-test');
  };

  orderMedicine = () => {
    const { history } = this.props;
    history.push('/pharmacy');
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  openRewards = () => {
    const { history } = this.props;
    history.push('/home/rewards');
  };

  showFitcoinModal = () => {
    this.setState({
      showModal: true,
      modalVisible: true,
    });
  };

  runAction(ele) {
    const { cardDirective } = ele;
    const { userId, history } = this.props;
    switch (cardDirective.actionType) {
      case 'bot':
        history.push('/bot', {
          chatName: cardDirective.action.text,
          channel: `visit.bot.${userId}`,
          action: cardDirective.action,
        });
        break;
      case 'native':
        switch (cardDirective.action.redirectTo) {
          case 'meal-log':
            logEvent('Meal Tracking Dashboard Card Clicked');
            history.push('/logmeal');
            break;
          case 'hra':
            logEvent('HRA Home Tab Card');
            history.push('/hra');
            break;
          case 'ahc-detail':
            history.push('/lab-test/ahc-package?showCloseButton=false');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  closeUploadModal = () => {
    this.setState({
      showPendingConsultBlocker: false,
    });
  };

  submitUploadModal = (consultationId) => {
    const { history } = this.props;
    history.push('/home/consult', {
      consultationId,
    });
  };

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  render() {
    const { history, showFitCoins } = this.props;
    const {
      servicesCards,
      fitCoinsCards,
      opdCards,
      quickActionCards,
      hraCard,
      inviteCards,
      showModal,
      modalVisible,
      showPendingConsultBlocker,
      pendingConsult,
      tinderBenefits,
      enrollmentCard,
      ahcCard,
      additionalCards,
      healthContentCards,
    } = this.state;
    const renderServicesCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'con-assistant':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.startTreament(
                  ele.cardDirective.action.allowSearch,
                  ele.pendingConsult,
                  ele.cancelledRequest,
                  ele.cardDirective.action.showVerticals,
                  ele.cardDirective.action.verticalId
                )
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={onlineIcon}
            />
          );
        case 'con-search':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.findSpecialist(ele.pendingConsult, ele.cancelledRequest)
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={offlineIcon}
            />
          );
        // case 'sym-check':
        //   return (
        //     <HomeActionCard
        //       key={idx}
        //       onTap={() => this.runAction(ele)}
        //       title={ele.label}
        //       subtitle={ele.labelInfo}
        //       earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
        //       icon={symCheckIcon}
        //     />
        //   );
        case 'hra-card':
          logEvent('HRA Home Tab Card');
          return (
            <HomeActionCard
              key={idx}
              onTap={() => this.runAction(ele)}
              title="Health Risk Assessment"
              subtitle="Get insights into your health"
              icon={hraIcon}
            />
          );
        case 'ahc-card':
          return (
            <ImageCard
              onTap={() => this.runAction(ele)}
              imageLink={ele.image}
            />
          );
        case 'support':
          return (
            <div
              className="support-card mb-5 mx-8px hover"
              onClick={this.openSupport}
              key={ele.cardType}
            >
              <div className="flex-center">
                <img src={ChatIcon} alt="chat" />
                <p className="ml-5">{ele.label}</p>
              </div>
              <ArrowIcon />
            </div>
          );
        default:
          return false;
      }
    };

    const renderHealthContentCards = (cards) => {
      return cards.map((card) => {
        switch (card.cardKey) {
          case 'tik-podcast':
            return (
              <PodcastCard
                onClick={() =>
                  this.props.history.push('/health-content/podcasts-home')
                }
              >
                <div>
                  <PodcastHeading>Mindfulness Podcasts</PodcastHeading>
                  <PodcastDescription>
                    Listen and strenghten your mental health
                  </PodcastDescription>
                </div>
                <div>
                  <img src={PodcastIcon} />
                </div>
              </PodcastCard>
            );
        }
      });
    };

    const renderActionsCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'pharmacy':
          if (ele.showPharmacy) {
            return (
              <HomeActionCard
                onTap={() => this.orderMedicine(ele)}
                title={ele.label}
                icon={medIcon}
                offer={ele.offer}
                discountText={ele.labelBanner}
                earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              />
            );
          }
          return;
        case 'lab-tests':
          return (
            <HomeActionCard
              onTap={this.bookTests}
              title={ele.label}
              icon={labIcon}
              offer={ele.offer}
              discountText={ele.labelBanner}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
            />
          );
        default:
          return false;
      }
    };

    const renderQuickActionCard = (ele) => {
      switch (ele.cardType) {
        case 'meal-log':
          return (
            <HomeActionCard
              key={ele.cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              icon={mealIcon}
              discountText={ele.labelBanner}
              earnBadge={showFitCoins}
            />
          );
        case 'metabolic-profile':
          return (
            <HomeActionCard
              key={ele.cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              icon={metaIcon}
              discountText={ele.labelBanner}
              earnBadge={showFitCoins}
            />
          );
        default:
          return false;
      }
    };
    const renderOpdCard = (ele, idx) => {
      switch (ele.cardType) {
        // case 'buy-policy':
        //   return (
        //     <SubscriptionCard
        //       key={idx}
        //       alternatives={ele.alternatives}
        //       onTap={() => history.replace('/home/policy-purchased')}
        //     />
        //   );
        case 'activate-opd':
          return <PolicyActivationCard onTap={() => {}} />;
        default:
          return false;
      }
    };
    const renderInviteCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'ftInvite':
          return <ReferCard key={idx} onTap={() => history.push('/refer')} />;
        default:
          return false;
      }
    };
    const renderFitcoinCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'claim-coins':
          return (
            <RewardsCard
              key={idx}
              onSeeAllTapped={this.openRewards}
              onKnowMoreTapped={this.showFitcoinModal}
            />
          );
        default:
          return false;
      }
    };
    const renderEnrollmentCard = (ele, idx) => {
      console.log(ele);
      return (
        <div
          className="border border-txtsecondary-shades7 m-8px bg-textbox rounded-8 cursor-pointer"
          onClick={() => {
            window.location.assign(
              ele.cards[0].cardDirective.action.redirectTo
            );
          }}
        >
          <div className="p-20px flex flex-row justify-between items-center">
            <div className="mr-16px">
              <p className="text-body-l font-semibold text-txtlight mb-12px">
                {ele.title}
              </p>
              <p className="text-body-s font-medium text-txtlight">
                {ele.text}
              </p>
            </div>
            <img
              src={ele.image}
              alt="enroll"
              style={{
                width: '30%',
              }}
            />
          </div>
          <div className="border-t border-txtsecondary-shades7 p-20px flex flex-row items-center justify-center">
            <img
              src={membersIcon}
              alt="enroll"
              style={{
                height: '20px',
              }}
            />
            <p className="text-body-l font-semibold text-primary ml-8px">
              {ele.buttonText}
            </p>
          </div>
        </div>
      );
    };

    const renderAhcCard = (ele, idx) => {
      console.log('ahc', ele);
      console.log(ele.cards.cardDirective.action.redirectTo);
      const url = new URL(ele.cards.cardDirective.action.redirectTo);
      return (
        <div
          // className="border border-txtsecondary-shades7 bg-textbox rounded-8 cursor-pointer"
          className="cursor-pointer"
          onClick={() => {
            // window.location.assign(ele.cards.cardDirective.action.redirectTo);
            history.push(url.searchParams.get('rt'));
          }}
        >
          <img
            src={ele.cards.link.split('=')[1]}
            // style={{ height: '40%', width: '100%' }}
            alt="ahc"
          />
        </div>
      );
    };

    const renderAdditionalCards = (ele, idx) => {
      console.log('additional card', ele);
      let url;
      let openInSameTab = false;
      if ((ele.cardDirective.action || {}).redirectTo) {
        url = new URL((ele.cardDirective.action || {}).redirectTo);
        openInSameTab = (ele.cardDirective.action || {}).openInSameTab;
      }
      if (ele.cardType === 'html') {
        return (
          <div
            // className="border border-txtsecondary-shades7 bg-textbox rounded-8 cursor-pointer"
            className="cursor-pointer my-16px mx-auto"
            onClick={() => {
              // window.location.assign(ele.cards.cardDirective.action.redirectTo);
              if (url && !openInSameTab) {
                window.open(url, '_blank');
              } else if (url) {
                window.location.assign(url);
              }
            }}
          >
            <img
              src={ele.link.split('=')[1]}
              // style={{ height: '40%', width: '100%' }}
              alt="ahc"
            />
          </div>
        );
      }
      return (
        <AdditionalCardContainer>
          <AdditionalCard>
            <div className="border-b border-customE5E9FE">
              <div className="p-20px pr-16px">
                <div>
                  <p
                    style={{
                      color: '#0F0B28',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    {ele.cardInfo.heading}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center">
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#72777A',
                      textAlign: 'start',
                      marginRight: '34px',
                      lineHeight: '150%',
                    }}
                  >
                    {ele.cardInfo.text}
                  </p>
                  <img
                    src={ele.cardInfo.image}
                    style={{
                      width: '96px',
                      height: '70px',
                      paddingRight: '12px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="p-16px flex flex-row items-center justify-center"
              onClick={() => {
                if (ele.cardDirective.actionType === 'pwa-redirection') {
                  window.location.assign(
                    ele.cardDirective.elements[0].redirectTo
                  );
                }
              }}
            >
              <img
                src={DependantIcon}
                style={{ width: '20px', height: '20px', marginRight: '4px' }}
              />
              <p
                style={{
                  color: '#714FFF',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Add Dependents Now
              </p>
            </div>
          </AdditionalCard>
        </AdditionalCardContainer>
      );
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <>
          {this.state.dashboardApiLoaded ? (
            <BodyContainer>
              {enrollmentCard && enrollmentCard.length > 0
                ? enrollmentCard.map(renderEnrollmentCard)
                : ''}
              {additionalCards.map(renderAdditionalCards)}
              {ahcCard && ahcCard.length > 0 ? ahcCard.map(renderAhcCard) : ''}
              {/* {tinderBenefits ? (
                <TinderBenefitsCard
                  benefits={tinderBenefits}
                  history={this.props.history}
                />
              ) : (
                ''
              )} */}
              {opdCards.map(renderOpdCard)}
              {servicesCards.map(renderServicesCard)}
              {!tinderBenefits ? hraCard.map(renderServicesCard) : ''}
              {servicesCards.map(renderActionsCard)}
              {renderHealthContentCards(healthContentCards)}

              <QuickActionsContainer>
                {quickActionCards.map(renderQuickActionCard)}
              </QuickActionsContainer>

              {/* <WaterLogCard
                authToken={this.props.authToken}
                history={history}
              /> */}
              {/* {fitCoinsCards.map(renderFitcoinCard)} */}
              {inviteCards.map(renderInviteCard)}
            </BodyContainer>
          ) : (
            <LoadingComponent />
          )}
          {showModal && (
            <Modal>
              <ModalContainer>
                <FlyInTransition
                  in={modalVisible}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <ModalBody>
                    <CloseContainer onTap={this.closeModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <GiftImage src={giftIcon} />
                    <LineSeparator src={fitcoinLine} />
                    <ModalTitle>Introducing FIT Coins</ModalTitle>
                    <StepsContainer>
                      <StepContainer>
                        <StepNumber>1</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Complete transactions</StepTextTile>
                          <StepTextSubtitle>
                            Book consultations, order medicines & Lab tests via
                            VISIT App
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>2</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Earn FIT Coins</StepTextTile>
                          <StepTextSubtitle>
                            For every successful transaction, you earn upto 1000
                            FITCoins
                          </StepTextSubtitle>
                          <StepTextSubtitleContainer>
                            <StepTextSubtitle>
                              Spending ₹ 1 = 1{' '}
                            </StepTextSubtitle>
                            <FitcoinIcon src={fitcoinIconWhite} />
                          </StepTextSubtitleContainer>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>3</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Claim your rewards</StepTextTile>
                          <StepTextSubtitle>
                            Spend your FITcoins to claim vouchers, Phonepe Cash
                            and more offers.
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                    </StepsContainer>
                    <FitcoinButton onTap={this.openRewards}>
                      <ButtonText>SEE REWARDS</ButtonText>
                    </FitcoinButton>
                  </ModalBody>
                </FlyInTransition>
                <FlyInTransition
                  in={showPendingConsultBlocker}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <UploadPrescription
                    doctorId={pendingConsult.doctorId}
                    consultationId={pendingConsult.consultationId}
                    doctorImg={pendingConsult.doctorImg}
                    doctorName={pendingConsult.doctorName}
                    dateLabel={pendingConsult.dateLabel}
                    onClose={this.closeUploadModal}
                    onSubmit={this.submitUploadModal}
                  />
                </FlyInTransition>
              </ModalContainer>
            </Modal>
          )}
        </>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  user: state.user,
  showFitCoins: state.app.config.showFitCoins,
});

export default connect(mapStateToProps)(HomeContainer);

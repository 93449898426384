import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import {
  BoldText,
  ButtonText,
  RegularText,
  Text,
} from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import { PrimaryButton } from '../../components/common/Buttons';
import { fontSize } from '../../utils';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  background: white;
`;

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background: #56c02c;
  padding: 10px;
`;

const BackContainer = styled(StyledTappable)`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaTimes)`
  font-size: 14px;
  color: white;
`;

const HeaderText = styled(Text)`
  font-size: 14px;
  color: white;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-top: 24px;
`;

const MealText = styled(BoldText)`
  font-size: 14px;
  margin-bottom: 16px;
`;

const NutrientsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
  & > div:first-child > div {
    border-radius: 5rem 0 0 5rem;
  }
  & > div:last-child > div {
    border-radius: 0 5rem 5rem 0;
  }
`;

const NutrientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.width};
`;

const NutrientBar = styled.div`
  width: 100%;
  height: 10px;
  background: ${props => props.color};
  margin-bottom: 8px;
`;

const NutrientText = styled(BoldText)`
  color: ${props => props.color};
`;

const NutrientsDataContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  margin: 24px 0;
`;

const NutrientDataContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NutrientIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${props => props.color};
`;

const NutrientData = styled(RegularText)`
  color: #212121;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const QuantityButton = styled(StyledTappable)`
  background: #56c02c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 8px;
  color: white;
`;

const QuantityText = styled(BoldText)`
  font-size: 14px;
  color: #323232;
  margin: 0 10px;
`;

const CancelButton = styled(StyledTappable)`
  margin: 0 8px;
`;

const CancelText = styled(BoldText)`
  color: #aaa4a4;
`;

const AddButton = styled(PrimaryButton)`
  background: #56c02c;
  margin: 0 8px;
`;

const MeasureText = styled(MealText)`
  font-size: 14px;
`;

class AddMealContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedQuantity: props.dish.amount,
    };
  }

  incrementQuantity = () => {
    const { selectedQuantity } = this.state;
    if (selectedQuantity === 10) {
      return;
    }
    this.setState({
      selectedQuantity: selectedQuantity + 0.5,
    });
  };

  decrementQuantity = () => {
    const { selectedQuantity } = this.state;
    if (selectedQuantity === 0.5) {
      return;
    }
    this.setState({
      selectedQuantity: selectedQuantity - 0.5,
    });
  };

  render() {
    const { dish, onAdd, onClose } = this.props;
    const { selectedQuantity } = this.state;
    return (
      <OuterContainer>
        <HeaderContainer>
          <BackContainer onTap={onClose}>
            <StyledArrow />
          </BackContainer>
          <HeaderText>Logging Breakfast</HeaderText>
        </HeaderContainer>
        <BodyContainer>
          <MealText>{dish.name}</MealText>
          <MealText>{`${selectedQuantity * dish.calories} Cal`}</MealText>
          <NutrientsContainer>
            <NutrientContainer
              width={`${(dish.proteinRatio * 100).toFixed(0)}%`}
            >
              <NutrientBar color="#343779" />
              <NutrientText color="#343779">{`${(
                dish.proteinRatio * 100
              ).toFixed(0)}%`}</NutrientText>
            </NutrientContainer>
            <NutrientContainer width={`${(dish.carbsRatio * 100).toFixed(0)}%`}>
              <NutrientBar color="#33a9ac" />
              <NutrientText color="#33a9ac">{`${(dish.carbsRatio * 100).toFixed(
                0,
              )}%`}</NutrientText>
            </NutrientContainer>
            <NutrientContainer width={`${(dish.fatsRatio * 100).toFixed(0)}%`}>
              <NutrientBar color="#ffc300" />
              <NutrientText color="#ffc300">{`${(dish.fatsRatio * 100).toFixed(
                0,
              )}%`}</NutrientText>
            </NutrientContainer>
          </NutrientsContainer>
          <NutrientsDataContainer>
            <NutrientDataContainer>
              <NutrientIcon color="#343779" />
              <NutrientData>
                Proteins -{' '}
                <BoldText as="span">{`${(
                  selectedQuantity * dish.protein
                ).toFixed(2)}g`}</BoldText>
              </NutrientData>
            </NutrientDataContainer>
            <NutrientDataContainer>
              <NutrientIcon color="#33a9ac" />
              <NutrientData>
                Carbs -{' '}
                <BoldText as="span">{`${(selectedQuantity * dish.carbs).toFixed(
                  2,
                )}g`}</BoldText>
              </NutrientData>
            </NutrientDataContainer>
            <NutrientDataContainer>
              <NutrientIcon color="#ffc300" />
              <NutrientData>
                Fats -{' '}
                <BoldText as="span">{`${(selectedQuantity * dish.fats).toFixed(
                  2,
                )}g`}</BoldText>
              </NutrientData>
            </NutrientDataContainer>
          </NutrientsDataContainer>
          <RegularText>How many servings did you have?</RegularText>
          <QuantityContainer>
            <QuantityButton onTap={this.decrementQuantity}>
              <BoldText>-</BoldText>
            </QuantityButton>
            <QuantityText>{selectedQuantity}</QuantityText>
            <QuantityButton onTap={this.incrementQuantity}>
              <BoldText>+</BoldText>
            </QuantityButton>
          </QuantityContainer>
          <MeasureText>{dish.measureUnit}</MeasureText>
          <QuantityContainer>
            <CancelButton onTap={onClose}>
              <CancelText>CANCEL</CancelText>
            </CancelButton>
            <AddButton onTap={() => onAdd(selectedQuantity)}>
              <ButtonText>ADD FOOD</ButtonText>
            </AddButton>
          </QuantityContainer>
        </BodyContainer>
      </OuterContainer>
    );
  }
}

export default AddMealContainer;

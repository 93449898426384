import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import RewardsHistory from './RewardsHistory';
import RewardDescription from './RewardDescription';
import PurchaseConfirmation from './PurchaseConfirmation';

const RewardsNavigator = ({ loginState, authToken, history }) => {
  if (loginState !== 'LOGGED_IN' || !authToken) {
    history.push('/');
    return false;
  }
  return (
    <Switch>
      <Route path="/rewards/history" exact component={RewardsHistory} />
      <Route path="/rewards/description" exact component={RewardDescription} />
      <Route
        path="/rewards/confirmation"
        exact
        component={PurchaseConfirmation}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(RewardsNavigator);

import React from 'react';
import styled from 'styled-components';

import { BoldText } from './Text';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

const HeaderText = styled(BoldText)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #413958;
`;

const ConsultationHeader = () => (
  <HeaderContainer>
    <HeaderText>Get Care</HeaderText>
  </HeaderContainer>
);

export default ConsultationHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RegularText } from '../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.625rem 0.75rem;
  background: #f7f7f7;
  border-radius: 0.875rem;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MessageText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  word-break: break-word;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1rem;
`;

const TimestampText = styled(RegularText)`
  opacity: 0.5;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BotMessage = ({ message, timestamp }) => (
  <OuterContainer>
    <BubbleContainer>
      <MessageContainer>
        <MessageText>{message}</MessageText>
        {timestamp && (
          <TimestampContainer>
            <TimestampText>
              {new Date(timestamp).toLocaleTimeString('nu', {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </TimestampText>
          </TimestampContainer>
        )}
      </MessageContainer>
    </BubbleContainer>
  </OuterContainer>
);

BotMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default BotMessage;

export const tabFormatter = (tab) => {
  switch (tab) {
    case 'pharmacy':
      return '/pharmacy';
    case 'hra':
      return '/hra?noBackButton=true';
    case 'labs':
      return '/lab-test';
    case 'online-consults':
      return '/consultation/online/preview';
    case 'dependents':
      return '/manage-dependents';
    case 'podcast':
      return '/health-content/podcasts-home';
    default:
      return '/home';
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../common/CardContainer';
import { Text, SubscriptionText } from '../common/Text';

const OuterCard = styled(CardContainer)`
  margin: 0rem 0.5rem;
  display: flex;
  flex: 1 0 auto;
  // flex-direction: column;
  align-items: space-between;
  align-self: stretch;
  transform: translateY(-3.5rem);
  margin-bottom: -3.5rem;
`;

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: ${props => (props.addBottomMargin ? '1.625rem' : '0')};
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 0.25rem;
  color: #666666;
  display: flex;
  flex-direction: row;
`;

const InfoText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
  margin-bottom: 0.25rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
`;

const HorizontalLine = styled.div`
  display: flex;
  align-self: stretch;
  height: 0.05rem;
  background-color: #979797;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.75rem;
`;

const MembershipInfoCard = ({ policyDetails }) => (
  <OuterCard>
    <OuterContainer>
      <InfoContainer>
        <Header>
          <SubscriptionText>YOUR SUBSCRIPTION</SubscriptionText>
        </Header>
        <HorizontalLine />
        <Body>
          <BodyContainer>
            <InputContainer>
              <AgeContainer>
                <LabelText>Subscription ID</LabelText>
                <InfoText>{policyDetails.policyNumber}</InfoText>
              </AgeContainer>
            </InputContainer>
            <InputContainer>
              <AgeContainer>
                <LabelText>Name</LabelText>
                <InfoText>{policyDetails.userName}</InfoText>
              </AgeContainer>
            </InputContainer>
            {/* <InputContainer>
              <AgeContainer>
                <LabelText>Renewal on</LabelText>
                <InfoText>{policyDetails.renewalOn}</InfoText>
              </AgeContainer>
            </InputContainer> */}
            {policyDetails.waitingPeriod > 0 && (
              <InputContainer>
                <AgeContainer>
                  <LabelText>Waiting Period</LabelText>
                  <InfoText>{`${policyDetails.waitingPeriod} days(${policyDetails.daysRemaining} days remaining)`}</InfoText>
                </AgeContainer>
              </InputContainer>
            )}
          </BodyContainer>
        </Body>
      </InfoContainer>
    </OuterContainer>
  </OuterCard>
);

MembershipInfoCard.propTypes = {
  onTap: PropTypes.func,
};

export default MembershipInfoCard;

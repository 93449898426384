import React, { Component } from 'react';

export class TinderBenefitsCard extends Component {
  constructor(props) {
    super(props);
  }

  openConsultBanner = () => {
    const bannerDetail = this.props.benefits;
    this.props.history.push('/consultation/doctor-select', {
      specialistSearch: false,
      searchType: 'find',
      showSearch: false,
      showVerticals: false,
      verticalId: bannerDetail.verticalId,
    });
  };

  render() {
    const bannerDetail = this.props.benefits;
    return (
      <div className="p-10px font-mont">
        <div
          style={{
            background: 'linear-gradient(135deg, #EC87A1 0.55%, #A346CD 97.3%)',
          }}
          className="rounded-12 pb-16px pl-16px pr-8px consult-card mb-20px"
          onClick={this.openConsultBanner}
        >
          <img
            src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/tinder-logo.png"
            style={{ height: '50px' }}
          />
          <div className="flex flex-row justify-between items-center">
            <div
              className="text-textbox"
              style={{
                maxWidth: '60%',
              }}
            >
              <p className="text-body-l font-bold">
                Get 50% Discount on Therapy sessions
              </p>
              <p className="text-body-s mt-2px font-medium">
                Exclusive for tinder members
              </p>
              <p className="bg-textbox text-grey-shades5 text-center py-6px mt-18px rounded-12 text-body-s font-bold">
                {' '}
                Consult Now
              </p>
            </div>
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/illustration.png"
              alt="illustration"
              style={{ height: '80px' }}
            />
          </div>
        </div>
        <div
          className="benefits rounded-12 p-20px cursor-pointer"
          style={{ boxShadow: ' 0px 2px 20px rgba(254, 68, 110, 0.15)' }}
          onClick={() => {
            window.location.href = 'https://vsyt.me/o/vstT01';
          }}
        >
          <p className="text-body-l font-semibold text-txtlight">
            Download Visit app to avail benefits exclusive for{' '}
            <span style={{ color: '#FE416F' }} className="font-bold">
              Tinder members
            </span>
          </p>
          <div className="flex flex-row items-center mt-20px pb-20px border-b border-txtsecondary-shades4">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-2.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">Access</span>
              &nbsp;to Online Wellness, Fitness and Podcast Sessions
            </p>
          </div>
          <div className="flex flex-row items-center mt-20px pb-20px border-b border-txtsecondary-shades4">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-4.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">Access</span>
              &nbsp;to Fitness Videos across Yoga, HIIT, Dance Fitness & more.
            </p>
          </div>
          <div className="flex flex-row items-center mt-20px pb-20px">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-3.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">FITCoins</span>
              &nbsp;Rewards Program
            </p>
          </div>
          <div
            className="w-100 rounded-12 text-center py-12px"
            style={{ background: '#FE416F' }}
          >
            <p className="text-textbox font-bold text-body-l">DOWNLOAD APP</p>
          </div>
        </div>
      </div>
    );
    return (
      <div className="p-10px font-mont">
        {bannerDetail && bannerDetail.pendingConsult > 0 ? (
          <div
            style={{
              background:
                'linear-gradient(135deg, #EC87A1 0.55%, #A346CD 97.3%)',
            }}
            className="rounded-12 pb-16px pl-16px pr-8px consult-card mb-20px"
            onClick={this.openConsultBanner}
          >
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/tinder-logo.png"
              style={{ height: '50px' }}
            />
            <div className="flex flex-row justify-between items-center">
              <div className="text-textbox">
                <p className="text-body-l font-bold">
                  {bannerDetail.pendingConsult} Therapy sessions Left{' '}
                </p>
                <p className="text-chip mt-2px font-medium">
                  Expires on {bannerDetail.endsAt}
                </p>
                <p className="bg-textbox text-grey-shades5 text-center py-6px mt-18px rounded-12 text-body-s font-bold">
                  {' '}
                  Consult Now
                </p>
              </div>
              <img
                src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/illustration.png"
                alt="illustration"
                style={{ height: '80px' }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              background:
                'linear-gradient(135deg, #EC87A1 0.55%, #A346CD 97.3%)',
            }}
            className="rounded-12 pb-16px pl-16px pr-8px consult-card mb-20px"
            onClick={this.openConsultBanner}
          >
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/tinder-logo.png"
              style={{ height: '50px' }}
            />
            <div className="flex flex-row justify-between items-center">
              <div className="text-textbox">
                <p className="text-body-l font-bold">0 sessions Left</p>
                <p className="text-chip mt-2px font-medium">
                  Consult at discounted price exclusive for tinder members
                </p>
                <p className="bg-textbox text-grey-shades5 text-center py-6px mt-18px rounded-12 text-body-s font-bold">
                  {' '}
                  Consult Now
                </p>
              </div>
              <img
                src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/illustration.png"
                alt="illustration"
                style={{ height: '80px' }}
              />
            </div>
          </div>
        )}
        <div
          className="benefits rounded-12 p-20px cursor-pointer"
          style={{ boxShadow: ' 0px 2px 20px rgba(254, 68, 110, 0.15)' }}
          onClick={() => {
            window.location.href = 'https://vsyt.me/o/vstT01';
          }}
        >
          <p className="text-body-l font-semibold text-txtlight">
            Download Visit app to avail benefits exclusive for{' '}
            <span style={{ color: '#FE416F' }} className="font-bold">
              Tinder members
            </span>
          </p>
          <div className="flex flex-row items-center mt-20px pb-20px border-b border-txtsecondary-shades4">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-2.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">Access</span>
              &nbsp;to Online Wellness, Fitness and Podcast Sessions
            </p>
          </div>
          <div className="flex flex-row items-center mt-20px pb-20px border-b border-txtsecondary-shades4">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-4.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">Access</span>
              &nbsp;to Fitness Videos across Yoga, HIIT, Dance Fitness & more.
            </p>
          </div>
          <div className="flex flex-row items-center mt-20px pb-20px">
            <img
              src="https://visit-public.s3.ap-south-1.amazonaws.com/assets/tinder-benefits/benefit-3.png"
              style={{ height: '56px' }}
            />
            <p className="font-semibold text-body-s text-txtlight pl-20px">
              <span className="text-secondary-shades6 font-bold">FITCoins</span>
              &nbsp;Rewards Program
            </p>
          </div>
          <div
            className="w-100 rounded-12 text-center py-12px"
            style={{ background: '#FE416F' }}
          >
            <p className="text-textbox font-bold text-body-l">DOWNLOAD APP</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TinderBenefitsCard;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../common/CardContainer';
import { Text, RegularText, BoldText } from '../common/Text';
import Heart from '../../images/policy/heart.svg';

const OuterCard = styled(CardContainer)`
  margin: 0rem 0.5rem;
  display: flex;
  flex: 1 0 auto;
  // flex-direction: column;
  align-items: space-between;
  align-self: stretch;
  transform: translateY(-3.5rem);
  margin-bottom: -3.5rem;
`;

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin-left: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: ${props => (props.addBottomMargin ? '1.625rem' : '0')};
`;

const BigInputContainer = styled(InputContainer)`
  flex: 2;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 0.25rem;
  color: #666666;
  display: flex;
  flex-direction: row;
`;

const InfoText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 0.25rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
`;

const ProfileImage = styled.img`
  height: 4rem;
  width: 4rem;
  margin: 0.625rem;
  margin-left: 1rem;
  align-self: flex-start;
`;

const HeartIcon = styled.img`
  height: 1.825rem;
  width: 1.825rem;
`;

const HeaderText = styled(BoldText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #808080;
  margin-left: 0.625rem;
`;

const HorizontalLine = styled.div`
  display: flex;
  align-self: stretch;
  height: 0.05rem;
  background-color: #ffa14f;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PolicyInfoCard = ({ policyDetails, profileImage }) => (
  <OuterCard>
    <OuterContainer>
      <InfoContainer>
        <Header>
          <HeartIcon src={Heart} />
          <HeaderText>Your Insurance</HeaderText>
        </Header>
        <HorizontalLine />
        <Body>
          <BodyContainer>
            <InputContainer>
              <AgeContainer>
                <LabelText>Name</LabelText>
                <InfoText>{policyDetails.userName}</InfoText>
              </AgeContainer>
            </InputContainer>
          </BodyContainer>

          <BodyContainer>
            <InputContainer>
              <AgeContainer>
                <LabelText>Age</LabelText>
                <InfoText>{policyDetails.userAge}</InfoText>
              </AgeContainer>
            </InputContainer>
            <InputContainer>
              <AgeContainer>
                <LabelText>Gender</LabelText>
                <InfoText>{policyDetails.userGender}</InfoText>
              </AgeContainer>
            </InputContainer>
            {/* <BigInputContainer>
              <AgeContainer>
                <LabelText>Renewal on</LabelText>
                <InfoText>{policyDetails.renewalOn}</InfoText>
              </AgeContainer>
            </BigInputContainer> */}
          </BodyContainer>
        </Body>
      </InfoContainer>
      <ProfileImage className="profile-image" src={profileImage} />
    </OuterContainer>
  </OuterCard>
);

PolicyInfoCard.propTypes = {
  onTap: PropTypes.func,
};

export default PolicyInfoCard;

import axios from 'axios';
import { showError } from '../utils';
import config from '../config';

let placesSdk, locationSearchSessionToken, autoCompleteService;
const initPlacesSdk = () => {
  placesSdk = window.google ? window.google.maps.places : undefined;

  autoCompleteService = placesSdk
    ? new placesSdk.AutocompleteService()
    : undefined;
};

export function searchLocation(searchValue) {
  if (!placesSdk) {
    initPlacesSdk();
    if (!placesSdk) {
      const err = new Error('Places SDK not initialized');
      return Promise.reject(err);
    }
  }
  if (!locationSearchSessionToken) {
    locationSearchSessionToken = new placesSdk.AutocompleteSessionToken();
  }
  return new Promise((resolve, reject) => {
    autoCompleteService.getPlacePredictions(
      {
        input: searchValue,
        sessionToken: locationSearchSessionToken,
        componentRestrictions: {
          country: ['in'],
        },
      },
      (results, status) => {
        if (status === placesSdk.PlacesServiceStatus.OK) {
          resolve(
            results.map(ele => ({
              titleText: ele.structured_formatting.main_text,
              secondaryText: ele.structured_formatting.secondary_text,
              placeId: ele.place_id,
            })),
          );
        } else if ((status = placesSdk.PlacesServiceStatus.ZERO_RESULTS)) {
          resolve([]);
        } else {
          const err = new Error('Request failed');
          err.code = status;
          err.errorMessage = 'Unable to fetch results. Please try again later.';
          showError(err);
          reject(err);
        }
      },
    );
  });
}

export function getAddressDetails(attrEle, placeId) {
  if (!placesSdk) {
    initPlacesSdk();
    if (!placesSdk) {
      const err = new Error('Places SDK not initialized');
      return Promise.reject(err);
    }
  }
  if (!locationSearchSessionToken) {
    const err = new Error('No session token found');
    return Promise.reject(err);
  }
  const placesService = new placesSdk.PlacesService(attrEle);
  return new Promise((resolve, reject) => {
    placesService.getDetails(
      {
        fields: [
          'address_components',
          'formatted_address',
          'geometry.location',
        ],
        placeId,
        sessionToken: locationSearchSessionToken,
      },
      (result, status) => {
        let city;
        if (status === placesSdk.PlacesServiceStatus.OK) {
          result.address_components.forEach(component => {
            if (!city && component.types.indexOf('locality') !== 1) {
              city = component.long_name;
            }
          });
          resolve({
            city,
            formattedAddress: result.formatted_address,
            location: result.geometry.location,
          });
        } else {
          const err = new Error('Request failed');
          err.code = status;
          err.errorMessage = 'Unable to fetch results. Please try again later.';
          showError(err);
          reject(err);
        }
      },
    );
  });
}

export function getMmiAddressSuggestions(query, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/location/auto-suggest`, {
      headers,
      params: {
        q: query,
      },
    })
    .then(resp => {
      const responseBody = resp.data;
      console.log(responseBody);
      if (responseBody.message === 'success') {
        return responseBody.suggestedLocations;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

// export function getMmiAddressDetails(eLoc) {
//   return axios
//     .get(`${process.env.REACT_APP_MMI_BASE_URL}/place_detail`, {
//       params: {
//         place_id: eLoc,
//       },
//     })
//     .then(resp => {
//       const responseBody = resp.data;
//       console.log(responseBody);
//       if (responseBody.responseCode === 200) {
//         return responseBody.results;
//       } else {
//         const err = new Error('Invalid response');
//         err.data = responseBody;
//         throw err;
//       }
//     })
//     .catch(err => {
//       showError(err);
//       throw err;
//     });
// }

initPlacesSdk();

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft, FaTimes as RemoveIcon } from 'react-icons/fa';
import Carousel from '../../components/common/Carousel';
import StyledTappable from '../../components/common/StyledTappable';
import { FitcoinIcon } from '../../components/common/Fitcoin';
import { FlyInTransition } from '../../components/transitions/transitions';
import {
  BoldText,
  BlackText,
  ButtonText,
  RegularText,
  Text,
} from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';
import CardContainer from '../../components/common/CardContainer';
import TappableCard from '../../components/common/TappableCard';
import Modal from '../../containers/common/Modal';
import { redeemProduct } from '../../services';
import LoadingComponent from '../../components/common/LoadingComponent';
import { fontSize } from '../../utils/font-size';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
`;

const BackContainer = styled(StyledTappable)``;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #fff;
`;

const RewardBrandText = styled(BoldText)`
  font-weight: 600;
  font-size: 18px;
  color: #2a2a2a;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
`;

const StyledButtonText = styled(BlackText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: ${(props) => (props.color ? props.color : '#c6ac35')};
  background: ${(props) =>
    props.color ? '' : 'linear-gradient(45deg, #ede67b, #a37520)'};
  -webkit-background-clip: ${(props) => (props.color ? '' : 'text')};
  -webkit-text-fill-color: ${(props) => (props.color ? '' : 'transparent')};
`;

const DescriptionContainer = styled(CardContainer)`
  box-shadow: none;
  padding: 1rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const DescriptionTitle = styled(BoldText)`
  font-size: 16px;
  color: #2a2a2a;
  line-height: 20px;
  margin-bottom: 0.625rem;
`;

const DescriptionBody = styled(RegularText)`
  line-height: 20px;
  font-size: 13px;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 1rem;
  margin-top: 0.75rem;
`;

const OfferPointsContainer = styled.ul`
  padding: 0;
  margin: 0;
  counter-reset: item;
  list-style-position: outside;
`;

const OfferPoint = styled.li`
  font-size: 13px;
  margin: 16px 0;
  padding-left: 20px;

  display: list-item;
  counter-increment: item;
  list-style-type: none;

  :before {
    content: counter(item);
    border-radius: 100%;
    color: #2a2a2a;
    margin-left: -30px;
    width: 30px;
    height: 16px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
  }
  :after {
    margin-right: 10px;
  }
`;

const PurchaseButton = styled(TappableCard)`
  align-self: flex-start;
  background: #303040;
  flex-shrink: 0;
  margin: 16px 0;
  text-align: center;
  border-radius: 22px;
  width: 100%;
  flex: auto;
  height: 30px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  padding: 0.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  padding: 0.5rem;
`;

const CloseIcon = styled(RemoveIcon)``;

const FitcoinText = styled(BlackText)`
  font-size: ${() => `${1.375 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #d2a72b;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 0.625rem;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 40px;
  align-items: stretch;
  align-self: stretch;
  overflow-x: hidden;
  position: relative;
`;

const BannerImageContainer = styled.div`
  width: ${(props) => props.width};
  flex-shrink: 0;
`;

const BannerImage = styled.img`
  width: 100%;
`;
class RewardDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalVisible: false,
      showModal: false,
      redeemed: false,
      copied: false,
      couponCode: '',
    };
    const { state } = props.location;
    const { history } = props;
    if (state) {
      this.product = state.product;
      if (state.product.redeemed) {
        this.state.redeemed = true;
        this.state.couponCode = state.product.couponCode;
        this.state.purchaseDate = new Date().toLocaleDateString('en-gb', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        });
      }
    } else {
      history.goBack();
    }
  }

  componentDidMount() {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Product Description Screen',
    });
    logEvent('Fitcoin Product Description Viewed', {
      productName: this.product.productName,
      productId: this.product.productId,
    });
  }

  confirmPurchase = () => {
    const { history } = this.props;
    history.push('/rewards/confirmation', {
      product: this.product,
    });
  };

  purchaseProduct = () => {
    this.setState({
      modalVisible: false,
      loading: true,
    });
    const { productId, type } = this.product;
    const { authToken, phone, countryCode } = this.props;
    redeemProduct(productId, type, phone, countryCode, authToken)
      .then((res) => {
        const { couponCode } = res;
        console.log(
          new Date().toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          })
        );
        this.setState({
          redeemed: true,
          couponCode,
          purchaseDate: new Date().toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ showModal: false, loading: false });
      });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  copyCouponCode = () => {
    const inviteCode = document.querySelector('.coupon-code');
    inviteCode.select();
    console.log(document.execCommand('copy'));
    this.setState({
      copied: true,
    });
  };

  render() {
    const {
      bgColor,
      productName,
      cost,
      description,
      offerDetails,
      productImages,
      redeemDetails,
      knowMoreText,
      knowMoreLink,
    } = this.product;
    console.log(this.product);
    const { showModal, modalVisible, loading } = this.state;
    const { history } = this.props;
    return (
      <OuterContainer>
        <HeaderContainer>
          <div className="reward-header flex-start-end">
            <div className="flex-center">
              <BackContainer onTap={() => history.goBack()}>
                <StyledArrow />
              </BackContainer>

              <div className="txt-white-600-14 ml-20">{productName}</div>
            </div>
            <div className="fitcoin-container">
              <FitcoinIcon />
              <StyledButtonText color={'#c6ac35'}>{cost}</StyledButtonText>
            </div>
          </div>
          <CarouselContainer>
            <Carousel autoplay delay={5000} showDots={productImages.length > 1}>
              {productImages.map((ele, idx) => (
                <BannerImageContainer key={idx} width="100%">
                  <BannerImage src={ele} />
                </BannerImageContainer>
              ))}
            </Carousel>
          </CarouselContainer>
          {/* <HeaderImageContainer>
            <RewardBrandContainer>
              <RewardBrandIcon src={logoUrl} />
              <RewardBrandText>{productName}</RewardBrandText>
            </RewardBrandContainer>
            <RewardImage src={serviceImage} />
          </HeaderImageContainer>
          <RewardTitle>{offerLabel}</RewardTitle>
          <RewardSubtitle>{serviceLabel}</RewardSubtitle>
          <TagText>{uspLabel}</TagText>
          {redeemed ? (
            <>
              <RedeemedContainer stopPropagation onTap={this.copyCouponCode}>
                <RedeemText
                  type="text"
                  className="visit-input coupon-code"
                  value={couponCode}
                />
                {copied ? <CopyText>Copied</CopyText> : <CopyIcon />}
              </RedeemedContainer>
              <PurchaseDateText>{`Unlocked on ${purchaseDate}`}</PurchaseDateText>
            </>
          ) : (
            <RewardButton onTap={this.confirmPurchase}>
              <ButtonTextContainer>
                <StyledButtonText color={bgColor}>Get for</StyledButtonText>
                <FitcoinIcon />
                <StyledButtonText color={bgColor}>{cost}</StyledButtonText>
              </ButtonTextContainer>
            </RewardButton>
          )} */}
        </HeaderContainer>
        <DescriptionContainer>
          <RewardBrandText>{productName}</RewardBrandText>
          <PurchaseButton onTap={this.confirmPurchase}>
            <ButtonTextContainer>
              <StyledButtonText color={bgColor}>Buy</StyledButtonText>
              <FitcoinIcon />
              <StyledButtonText color={bgColor}>{cost}</StyledButtonText>
            </ButtonTextContainer>
          </PurchaseButton>
          <DescriptionTitle>Description</DescriptionTitle>
          <DescriptionBody>{description}</DescriptionBody>
          <DescriptionBody>
            {knowMoreText}
            <br />
            {knowMoreLink}
          </DescriptionBody>
          {offerDetails.length > 0 && (
            <TermsContainer>
              <DescriptionTitle>Offer Details</DescriptionTitle>
              <OfferPointsContainer>
                {offerDetails.map((ele, idx) => (
                  <OfferPoint key={idx}>{ele}</OfferPoint>
                ))}
              </OfferPointsContainer>
            </TermsContainer>
          )}
          {redeemDetails.length > 0 && (
            <TermsContainer>
              <DescriptionTitle>How to Redeem</DescriptionTitle>
              <OfferPointsContainer>
                {redeemDetails.map((ele, idx) => (
                  <OfferPoint key={idx}>{ele}</OfferPoint>
                ))}
              </OfferPointsContainer>
            </TermsContainer>
          )}
        </DescriptionContainer>
        {showModal && (
          <Modal>
            <ModalContainer>
              {loading ? (
                <LoadingComponent color="white" />
              ) : (
                <FlyInTransition
                  in={modalVisible}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <ModalBody>
                    <CloseContainer onTap={this.closeModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <Text>{`You are about to unlock a ${productName} coupon using`}</Text>
                    <ButtonTextContainer>
                      <FitcoinIcon />
                      <FitcoinText>{cost}</FitcoinText>
                    </ButtonTextContainer>
                    <StyledButton onTap={this.purchaseProduct}>
                      <ButtonText>CONFIRM</ButtonText>
                    </StyledButton>
                  </ModalBody>
                </FlyInTransition>
              )}
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  countryCode: state.user.countryCode,
  phone: state.user.userPhone,
  balance: state.user.walletBalance,
});

export default connect(mapStateToProps)(RewardDescription);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledTappable from '../common/StyledTappable';

const OuterTappable = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  margin: 0.4rem 0.5rem;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

const BannerImage = styled.img`
  width: 100%;
`;

const ImageCard = ({ onTap, imageLink }) => (
  <OuterTappable onTap={onTap}>
    <OuterContainer>
      <BannerImage src={imageLink} />
    </OuterContainer>
  </OuterTappable>
);

ImageCard.propTypes = {
  onTap: PropTypes.func,
};

export default ImageCard;

import React, { Component } from 'react';

export class Support extends Component {
  constructor(props) {
    super(props);
    function initFreshChat() {
      window.fcWidget.init({
        config: {
          cssNames: {
            expanded: 'custom_fc_expanded',
            widget: 'custom_fc_frame',
          },
        },
        token: 'a3138f46-af50-4eca-a681-90c747950123',
        host: 'https://wchat.freshchat.com',
        name: 'ar',
        phoneNo: '7728807666',
        sponsorId: '106',
        sponsorName: 'Akzo Nobel',
        open: true,
      });
    }
    function initialize(i, t) {
      var e;

      return i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://wchat.freshchat.com/js/widget.js'),
          (e.onload = initFreshChat),
          i.head.appendChild(e));
    }
    function initiateCall() {
      return initialize(document, 'freshchat-js-sdk');
    }
    window.addEventListener
      ? window.addEventListener('load', initiateCall, !1)
      : window.attachEvent('load', initiateCall, !1);
  }
  render() {
    return <div className="custom_fc_frame">Open</div>;
  }
}

export default Support;

import { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft, FaChevronRight } from 'react-icons/fa';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import { Text } from '../../components/common/Text';
import { connect } from 'react-redux';
import Modal from '../common/Modal';
import LoadingComponent from '../../components/common/LoadingComponent';
import {
  addWater,
  deleteWaterLogById,
  getWaterLogDetails,
  removeWater,
} from '../../services/water-log';
import ProgressBar from '../../components/common/WaterProgressBar';
import WaterLogMinus from '../../images/home/water_glass_minus_filled.png';
import WaterLogPlus from '../../images/home/water_glass_plus_filled.png';
import D100ML from '../../images/water-log/disable-icons/100_ml.svg';
import D200ML from '../../images/water-log/disable-icons/200_ml.svg';
import D250ML from '../../images/water-log/disable-icons/250_ml.svg';
import D300ML from '../../images/water-log/disable-icons/300_ml.svg';
import D400ML from '../../images/water-log/disable-icons/400_ml.svg';
import D500ML from '../../images/water-log/disable-icons/500_ml.svg';

import E100ML from '../../images/water-log/enable-icons/100_ml_e.svg';
import E200ML from '../../images/water-log/enable-icons/200_ml_e.svg';
import E250ML from '../../images/water-log/enable-icons/250_ml_e.svg';
import E300ML from '../../images/water-log/enable-icons/300_ml_e.svg';
import E400ML from '../../images/water-log/enable-icons/400_ml_e.svg';
import E500ML from '../../images/water-log/enable-icons/500_ml_e.svg';
import GlassIcon from '../../images/water-log/glass_small.svg';
import DeleteIcon from '../../images/water-log/small_delete.svg';
import EditIcon from '../../images/water-log/edit_black.svg';
import EditWaterGoal from './EditWaterGoal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  user-select: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 6px 6px;
`;

const BackContainer = styled(Tappable)`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
  margin: 6px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

const WaterLogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  height: 180px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  display: flex;
  align-items: center;
`;

const ProgressContainer = styled.div`
  width: 60%;
  top: 90px;
  position: absolute;
  margin-left: 20%;
  margin-right: 20%;
  height: 20px;
`;

const Tags = styled.p`
  color: #47464a;
  font-size: 15px;
  text-align: center;
`;

const EditButton = styled.button`
  width: 90%;
  margin: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid #efefef;
  color: #47464a;
  font-weight: 600;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;

const ModifyContainer = styled.div``;

const Image = styled.img`
  diplay: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: scale-down;
`;

const Dimage = styled.img`
  diplay: flex;
  width: 64px;
  height: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -8px;
  padding: 4px;
`;

class WaterLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      goal: 0,
      goalLabel: null,
      addLabel: null,
      removeLabel: null,
      vesselGoal: 0,
      vesselSize: null,
      selectedVesselKey: null,
      addVesselIcon: null,
      removeVesselIcon: null,
      goalPercentage: 0,
      totalIntake: 0,
      intakeHistory: [],
      showEditGoalModal: false,
      showEditGoalModalBlocker: false,
    };
  }

  componentDidMount() {
    this.getWaterLogDetails();
  }

  getWaterLogDetails = () => {
    getWaterLogDetails(this.props.authToken)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const loggingCard = data.loggingCard;
        const todaysHistory = data.todaysHistory;
        this.updateAddVesselIcons(loggingCard.addVesselKey);
        this.updateRemoveVesselIcons(
          loggingCard.removeVesselKey,
          loggingCard.removeLabel
        );
        this.setState({
          goal: loggingCard.goal,
          goalLabel: loggingCard.goalLabel,
          addLabel: loggingCard.addLabel,
          removeLabel: loggingCard.removeLabel,
          selectedVesselKey: loggingCard.selectedVesselKey,
          vesselGoal: loggingCard.vesselGoal,
          goalPercentage: loggingCard.goalPercentage,
          totalIntake: loggingCard.totalIntake,
          vesselSize: loggingCard.vesselSize,
          loading: false,
          intakeHistory: todaysHistory,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  decreaseWater = () => {
    removeWater(this.props.authToken)
      .then((response) => {
        this.getWaterLogDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  increaseWater = () => {
    addWater(this.props.authToken)
      .then((response) => {
        this.getWaterLogDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteLogById = (id) => {
    deleteWaterLogById(this.props.authToken, id)
      .then((response) => {
        console.log(response);
        this.getWaterLogDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showEditGoalModal = (ele) => {
    this.setState({
      showEditGoalModal: true,
      showEditGoalModalBlocker: true,
    });
  };

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return null;
    }
    this.setState({ showEditGoalModalBlocker: false });
  };

  hideModal = () => {
    this.setState({ showEditGoalModal: false });
    this.setState({ showEditGoalModalBlocker: false });
  };

  hideModalWithUpdate = () => {
    this.getWaterLogDetails();
    this.setState({ showEditGoalModal: false });
    this.setState({ showEditGoalModalBlocker: false });
  };

  updateAddVesselIcons = (addLabel) => {
    switch (addLabel) {
      case 'coffee-cup':
        return this.setState({ addVesselIcon: E100ML });
      case 'water-glass':
        return this.setState({ addVesselIcon: E200ML });
      case 'water-glass-1':
        return this.setState({ addVesselIcon: E250ML });
      case 'tea-cup':
        return this.setState({ addVesselIcon: E300ML });
      case 'beer':
        return this.setState({ addVesselIcon: E400ML });
      case 'water-bottle':
        return this.setState({ addVesselIcon: E500ML });
    }
  };

  updateRemoveVesselIcons = (removeLabel, value) => {
    switch (removeLabel) {
      case 'coffee-cup':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D100ML : E100ML,
        });
      case 'water-glass':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D200ML : E200ML,
        });
      case 'water-glass-1':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D250ML : E250ML,
        });
      case 'tea-cup':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D300ML : E300ML,
        });
      case 'beer':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D400ML : E400ML,
        });
      case 'water-bottle':
        return this.setState({
          removeVesselIcon: value === '0ml' ? D500ML : E500ML,
        });
      default:
        return this.setState({ addVesselIcon: WaterLogMinus });
    }
  };

  render() {
    const {
      goal,
      addLabel,
      removeLabel,
      selectedVesselKey,
      vesselGoal,
      totalIntake,
      goalPercentage,
      intakeHistory,
      showEditGoalModal,
      vesselSize,
    } = this.state;

    const renderWaterIntakes = (data) => {
      return (
        <div
          style={{
            background: '#f3f3f3',
            borderRadius: '6px',
            boxShadow: '0px 0px 10px rgba(243, 243, 243, 0.1)',
          }}
        >
          {data.map((ele) => {
            return (
              <div
                key={ele.intakeId}
                className="flex flex-row justify-between p-4"
              >
                <div className="flex flex-row">
                  <img src={GlassIcon} width={'20px'} height={'20px'} />
                  <div className="flex flex-col ml-6">
                    <b>{ele.timeLabel}</b>
                    <p>{ele.intake}ml</p>
                  </div>
                </div>
                <img
                  src={DeleteIcon}
                  width={'30px'}
                  height={'30px'}
                  onClick={() => this.deleteLogById(ele.intakeId)}
                />
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center h-full">
            <LoadingComponent />
          </div>
        ) : (
          <FadeInTransition
            in={true}
            timeout={200}
            mountOnEnter
            unmountOnExit
            appear
          >
            <OuterContainer>
              <HeaderContainer>
                <BackContainer onTap={() => this.props.history.goBack()}>
                  <StyledArrow />
                </BackContainer>
                <HeaderTitleContainer>
                  <HeaderText>Water Details</HeaderText>
                </HeaderTitleContainer>
              </HeaderContainer>
              <BodyContainer>
                <div className="relative">
                  <WaterLogContainer>
                    <ModifyContainer
                      onClick={() => {
                        if (removeLabel != '0ml') {
                          this.decreaseWater();
                        }
                      }}
                      style={
                        removeLabel != '0ml'
                          ? {}
                          : { marginLeft: '-5px', marginBottom: '8px' }
                      }
                    >
                      {removeLabel === '0ml' ? (
                        <Dimage src={this.state.removeVesselIcon} alt="" />
                      ) : (
                        <Image src={this.state.removeVesselIcon} alt="" />
                      )}
                      <Tags>{removeLabel}</Tags>
                    </ModifyContainer>
                    <div>{/* <ProgressBar width={`${100}%`} /> */}</div>
                    <ModifyContainer onClick={this.increaseWater}>
                      <Image src={this.state.addVesselIcon} alt="" />
                      <Tags>{addLabel}</Tags>
                    </ModifyContainer>
                  </WaterLogContainer>
                  <ProgressContainer>
                    <ProgressBar
                      width={`${
                        goalPercentage <= 1
                          ? Math.floor(goalPercentage * 100)
                          : 100
                      }%`}
                    />
                    <Tags style={{ color: '#47464a' }}>
                      <div className="flex flex-row justify-between mt-6">
                        <div style={{ width: '30%' }}>
                          <p
                            className="text-left font-semibold"
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            Intake:
                          </p>
                          <b
                            className="text-left font-extrabold"
                            style={{
                              fontSize: '20px',
                              float: 'left',
                            }}
                          >
                            {totalIntake}ml
                          </b>
                        </div>
                        <div
                          style={{
                            borderLeft: '1px solid #cdcdcd',
                            marginTop: '2px',
                            marginBottom: '2px',
                          }}
                        ></div>
                        <div>
                          <p
                            className="text-right font-semibold"
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            Goal:
                          </p>
                          <b
                            className="text-left font-extrabold"
                            style={{
                              fontSize: '20px',
                            }}
                          >
                            {goal}ml
                          </b>
                        </div>
                      </div>
                    </Tags>
                  </ProgressContainer>
                </div>

                <EditButton onClick={this.showEditGoalModal}>
                  <img src={EditIcon} width={'30px'} height={'30px'} />
                  <p>Edit Goal</p>
                </EditButton>

                <div style={{ marginTop: '14px' }}>
                  <p
                    style={{
                      color: '#7c7c7c',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginBottom: '8px',
                    }}
                  >
                    Today's Water Intakes
                  </p>
                  {intakeHistory.length > 0 &&
                    renderWaterIntakes(intakeHistory)}
                </div>
              </BodyContainer>
            </OuterContainer>
          </FadeInTransition>
        )}

        {showEditGoalModal && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <FadeInTransition
                in={this.state.showEditGoalModalBlocker}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <div
                  ref={(node) => (this.node = node)}
                  className="bg-textbox flex flex-col items-center justify-center rounded-12"
                  style={{ width: '90%', maxWidth: '400px' }}
                >
                  <EditWaterGoal
                    goalLabel={this.state.goalLabel}
                    vesselSize={vesselSize}
                    goal={goal}
                    authToken={this.props.authToken}
                    selectedVesselKey={selectedVesselKey}
                    hideModalWithUpdate={this.hideModalWithUpdate}
                  />
                </div>
              </FadeInTransition>
            </ModalContainer>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(WaterLog);

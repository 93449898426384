import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import { FaArrowLeft } from 'react-icons/fa';

import { verifyOtp, login, loginSso } from '../../actions/index';
import { BoldText, Text } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { LinkText, PrimaryButton } from '../../components/common/Buttons';

import greenTick from '../../images/onboarding/greenTick.svg';
import contactGraphic from '../../images/onboarding/verify.svg';
import StyledTappable from '../../components/common/StyledTappable';
import { logEvent } from '../../utils/logEvent';
import { verifyOtpp } from '../../services';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
`;

const ScreenIcon = styled.img`
  max-width: 4.265rem;
`;

const TitleText = styled(BoldText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  align-self: flex-start;
  text-align: left;
`;

const SubtitleText = styled(Text)`
  fotn-size: 1rem;
  margin-top: 0.75rem;
  text-align: left;
  align-self: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.625rem;
`;

const ResendContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 1.875rem;
  margin-bottom: 1.625rem;
`;

const ResendText = styled(SubtitleText)`
  margin-top: 0;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  max-width: 3rem;
  padding: 0.33rem 0;
  border-bottom: 1px solid black !important;
  text-align: center;
`;

const OtpResendContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 0.875rem;
  // margin-left: 0.625rem;
`;

const SelectIcon = styled.img`
  max-width: 1.25rem;
  align-self: center;
`;

const OtpResendText = styled(ResendText)`
  color: #5cbf3d;
  margin-left: 0.625rem;
`;

class OnboardingVerifyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpValue1: '',
      otpValue2: '',
      otpValue3: '',
      otpValue4: '',
      loading: false,
      resendOtp: false,
    };
    this.otpValue2 = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);

  }

  verifyOtp = async () => {
    this.setState({
      loading: true,
    });
    const otp = `${this.state.otpValue1}${this.state.otpValue2}${this.state.otpValue3}${this.state.otpValue4}`;
    const { dispatch, history, location } = this.props;
    console.log(history);
    if (location.state.data.askPhone) {
      const result = await verifyOtpp(
        otp,
        location.state.phoneNumber,
        location.state.authToken
      );

      dispatch(
        loginSso(location.state.encrypted, location.state.clientId, history)
      );
    } else {
      dispatch(
        verifyOtp(this.props.phoneNumber, otp, history, location.search)
      );
    }
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Backspace') {
      const currentlyFocusedElement = document.activeElement.id;
      switch (currentlyFocusedElement) {
        case 'input-1':
          if (this.state.otpValue1 === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-2':
          if (this.state.otpValue2 === '') {
            document.querySelector('#input-1').focus();
          }
          break;
        case 'input-3':
          if (this.state.otpValue3 === '') {
            document.querySelector('#input-2').focus();
          }
          break;
        case 'input-4':
          if (this.state.otpValue4 === '') {
            document.querySelector('#input-3').focus();
          }
          break;
        default:
          break;
      }
    }
  };

  submitPhoneNumber = () => {
    this.setState({
      resendOtp: true,
    });
    console.log('Phone number submitted ', this.props.phoneNumber);
    const { dispatch, history } = this.props;
    dispatch(login(this.props.phoneNumber, 91, null, history, true, ''));
  };

  componentDidMount = () => {
    const { history, loginState } = this.props;
    if (loginState === 'LOGGED_IN') {
      history.replace('/home');
    } else if (loginState === 'ONE_LAST_STEP') {
      history.push('/one-last-step');
    } else if (loginState === 'SPONSOR_EMAIL') {
      history.push('/corporate-onboard');
      return false;
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      logEvent('Onboarding OTP Screen');

      document.querySelector('#input-1').focus();

      window.addEventListener('keydown', this.handleKeyPress, false);
    }
  };

  componentDidUpdate = () => {
    // otp error then change loading back to false
    if (this.state.loading && this.props.appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  render() {
    const { history } = this.props;
    const otpValue1Link = Link.state(this, 'otpValue1')
      .pipe((val) => {
        console.log({ val });
        if (val && val.toString().length === 4) {
          this.setState({
            otpValue1: val[0],
            otpValue2: val[1],
            otpValue3: val[2],
            otpValue4: val[3],
          });
          document.querySelector('#input-4').focus();
        } else {
          console.log({ val });
          this.setState({
            otpValue1: val[0] ? val[0] : '',
          });
        }
      })
      .onChange((val) => {
        console.log({ val }, 'here');
        this.setState({
          otpValue1: val[0],
        });
        if (val.toString() === '') {
          document.querySelector('#input-1').focus();
        } else {
          document.querySelector('#input-2').focus();
        }
      });
    const otpValue2Link = Link.state(this, 'otpValue2').onChange((val, as) => {
      console.log({ val, as });
      this.setState({
        otpValue2: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-1').focus();
      } else {
        document.querySelector('#input-3').focus();
      }
    });
    const otpValue3Link = Link.state(this, 'otpValue3').onChange((val) => {
      this.setState({
        otpValue3: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-2').focus();
      } else {
        document.querySelector('#input-4').focus();
      }
    });
    const otpValue4Link = Link.state(this, 'otpValue4').onChange((val) => {
      this.setState({
        otpValue4: val[0],
      });
      if (val.toString() === '') {
        document.querySelector('#input-3').focus();
      }
    });

    const resendOtpContainer = () => {
      let element = null;

      if (this.state.resendOtp) {
        element = (
          <OtpResendContainer>
            <SelectIcon src={greenTick} alt="Success" />
            <OtpResendText>Otp resent successfully.</OtpResendText>
          </OtpResendContainer>
        );
      }

      return element;
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <StyledTappable onTap={() => history.goBack()}>
              <FaArrowLeft />
            </StyledTappable>
            <ScreenIcon src={contactGraphic} alt="Phone" />
          </HeaderContainer>
          <TitleText>Verification Code</TitleText>
          <SubtitleText>{`OTP has been sent to +${this.props.countryCode}-${this.props.phoneNumber}`}</SubtitleText>
          <InputContainer>
            <StyledInputBox
              type="tel"
              id="input-1"
              className="visit-input"
              valueLink={otpValue1Link}
            />
            <StyledInputBox
              type="tel"
              id="input-2"
              maxLength={1}
              className="visit-input"
              valueLink={otpValue2Link}
            />
            <StyledInputBox
              type="tel"
              id="input-3"
              maxLength={1}
              className="visit-input"
              valueLink={otpValue3Link}
            />
            <StyledInputBox
              type="tel"
              id="input-4"
              maxLength={1}
              className="visit-input"
              valueLink={otpValue4Link}
            />
          </InputContainer>
          <ResendContainer>
            <ResendText>Didn't receive?</ResendText>
            <LinkText onClick={this.submitPhoneNumber}>Resend OTP</LinkText>
          </ResendContainer>
          <PrimaryButton
            onTap={this.verifyOtp}
            disabled={
              this.state.otpValue1.length === 0 ||
              this.state.otpValue2.length === 0 ||
              this.state.otpValue3.length === 0 ||
              this.state.otpValue4.length === 0
            }
            loading={this.state.loading}
          >
            <StyledButtonText>verify</StyledButtonText>
          </PrimaryButton>

          {resendOtpContainer()}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  phoneNumber: state.app.loginNumber,
  countryCode: state.app.loginCountryCode,
  appError: state.app.appError,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingVerifyOtp);

import configDevelopment from './config-development.js';
import configStaging from './config-staging.js';
import configProduction from './config-production.js';
let config = null;
const ENV = process.env.REACT_APP_BUILD_ENV;
console.log('CRA Application ENV', ENV);
if (ENV === 'development') {
  config = configDevelopment;
} else if (ENV === 'staging') {
  config = configStaging;
} else if (ENV === 'production') {
  config = configProduction;
} else {
  config = configDevelopment;
}
export default config;

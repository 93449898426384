// chatclient

import PubNub from 'pubnub';
import randomString from '../utils/random-string';
import { store } from '../store/index';
import {
  addChatMessage,
  setChatMessages,
  setDoctorTyping,
  setBotChatMessages,
  addBotChatMessage,
  startVideoCall,
} from '../actions/index';
import config from '../config';

class ChatClient {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  subscribe(channelId) {
    this.pubnub.subscribe({
      channels: [channelId],
      withPresence: true,
    });
    this.messageListener = this.addMessageListener(
      (payload, channel, group, timetoken) => {
        payload.timetoken = Number.parseFloat(timetoken) / 10000;
        if (payload.flowType) {
          if (payload.cardType !== 'chatImage') {
            store.dispatch(addBotChatMessage(payload));
          }
        } else {
          store.dispatch(addChatMessage(payload));
        }
      }
    );
    this.statusListener = this.addPresenceListener(
      (action, channelName, occupany, state) => {
        console.log(action, state);
        if (action === 'state-change') {
          if (state.status === 'TYPING') {
            store.dispatch(setDoctorTyping(true, state.doctorId));
          } else if (state.status === 'STOP_TYPING') {
            store.dispatch(setDoctorTyping(false, null));
          }
        }
      }
    );
  }

  subscribeForVideo(channelGroup) {
    console.log({ channelGroup }, 'subscribeForVideo');
    this.pubnub.subscribe(
      {
        channelGroups: [channelGroup],
        withPresence: true,
      },
      function(status, response) {
        if (status.error) {
          // handle error
          console.log({ status }, 'subscribeForVideo');
        } else {
          console.log('subscribeForVideo', response.timetoken);
        }
      }
    );
    this.messageListener = this.addMessageListenerForVideo(
      (payload, channel, group, timetoken) => {
        console.log(
          { payload, channel, group, timetoken },
          'message in subscribeForVideo'
        );
        store.dispatch(startVideoCall(payload));
      }
    );
  }

  addMessageListenerForVideo(fnc) {
    const listener = {
      message: m => {
        const channelName = m.channel; // The channel for which the message belongs
        // The channel group or wildcard subscription match (if exists)
        const channelGroup = m.subscription;
        const pubTT = m.timetoken; // Publish timetoken
        const payload = m.message; // The Payload
        // Pass the payload to handler in case it is not from user, or it is an image or document payload
        console.log('Recieving message', {
          payload,
          channelName,
          channelGroup,
          pubTT,
        });
        if (
          payload.notificationType &&
          payload.notificationType === 'doctorCalling'
        ) {
          // Add message to redux store
          fnc(payload, channelName, channelGroup, pubTT);
        }
      },
    };
    this.pubnub.addListener(listener);
    return listener;
  }

  unsubscribeForVideo(channelGroup) {
    console.log(this.pubnub.unsubscribe({ channelGroups: [channelGroup] }));
    this.removeListener(this.messageListener);
  }

  unsubscribe(channelId) {
    console.log(
      this.pubnub.unsubscribe({
        channels: [channelId],
      })
    );
    this.removeListener(this.messageListener);
    this.removeListener(this.statusListener);
  }

  addMessageListener(fnc) {
    const { user } = store.getState();
    const listener = {
      message: m => {
        const channelName = m.channel; // The channel for which the message belongs
        // The channel group or wildcard subscription match (if exists)
        const channelGroup = m.subscription;
        const pubTT = m.timetoken; // Publish timetoken
        const payload = m.message; // The Payload
        // Pass the payload to handler in case it is not from user, or it is an image or document payload
        console.log('Recieving message', payload);

        if (
          (payload.senderId && payload.senderId.toString() !== user.userId.toString()) ||
          payload.cardType === 'chatImage' ||
          payload.cardType === 'documentCard' ||
          payload.cardType === 'connectionRequest' ||
          payload.cardType === 'reschedule-card'
        ) {
          // Add message to redux store
          fnc(payload, channelName, channelGroup, pubTT);
        }
      },
    };
    this.pubnub.addListener(listener);
    return listener;
  }

  addStatusListener(fnc) {
    // handle status
    this.pubnub.addListener({
      status: s => {
        const {
          category,
          operation,
          affectedChannels,
          subscribedChannels,
          affectedChannelGroups,
          lastTimetoken,
          currentTimetoken,
        } = s;

        fnc(
          category,
          operation,
          affectedChannels,
          subscribedChannels,
          affectedChannelGroups,
          lastTimetoken,
          currentTimetoken
        );
      },
    });
  }

  addPresenceListener(fnc) {
    this.pubnub.addListener({
      presence: p => {
        // handle presence
        const {
          action,
          channelName,
          occupancy,
          state,
          channelGroup,
          presenceEventTime,
          uuid,
        } = p;

        fnc(
          action,
          channelName,
          occupancy,
          state,
          channelGroup,
          presenceEventTime,
          uuid
        );
      },
    });
  }

  removeListener(fnc) {
    this.pubnub.removeListener(fnc);
  }

  setState(status, patientId) {
    const { chat } = store.getState();
    const channel = chat.chatChannel;
    this.pubnub.setState(
      {
        channels: [channel],
        state: {
          patientId,
          status,
        },
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          return;
        }
      }
    );
  }

  botPublish(message) {
    const { botChat } = store.getState();
    const channel = botChat.chatChannel;
    const chatMessage = this.createBotMessage(message);
    store.dispatch(addBotChatMessage(chatMessage));
    console.log('Publishing bot message', chatMessage);
    this.pubnub.publish(
      {
        message: chatMessage,
        channel,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        const { botChat } = store.getState();
        botChat.chatMessages.forEach(ele => {
          console.log(ele, chatMessage);
          if (ele.messageId === chatMessage.messageId) {
            chatMessage.timetoken =
              Number.parseFloat(response.timetoken) / 10000;
          }
        });
        store.dispatch(setBotChatMessages(Array.from(botChat.chatMessages)));
      }
    );
  }

  publish(message) {
    const { chat } = store.getState();
    const channel = chat.chatChannel;
    const { consultationSummary } = chat;
    let urgentAssistance = false;
    if (consultationSummary && !consultationSummary.serviceTimings.available) {
      urgentAssistance = true;
    }
    const consultationId = chat.consultationId;
    const doctorId = chat.consultationSummary.consultationInfo.doctorId;
    const assistantId = chat.consultationSummary.consultationInfo.assistantId;
    const chatMessage = this.createChatMessage(
      message,
      consultationId,
      urgentAssistance
    );
    store.dispatch(addChatMessage(chatMessage));

    console.log('Publishing message');
    console.log(chatMessage);
    this.setState('USER_STOP_TYPING', chat.receiverId);
    this.pubnub.publish(
      {
        message: chatMessage,
        channel,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        const { chat } = store.getState();
        chat.chatMessages.forEach(ele => {
          console.log(ele, chatMessage);
          if (ele.messageId === chatMessage.messageId) {
            chatMessage.timetoken =
              Number.parseFloat(response.timetoken) / 10000;
          }
        });
        store.dispatch(setChatMessages(Array.from(chat.chatMessages)));
      }
    );
    // Publish to doctor inbound channel
    if (doctorId && doctorId.trim() !== '') {
      this.pubnub.publish(
        {
          message: chatMessage,
          channel: `visit-inbound-doctor-${doctorId}`,
        },
        (status, response) => {
          if (status.error) {
            console.log('Doctor channel', status);
          }
          console.log('Doctor channel', response);
        }
      );
    }
    if (assistantId && assistantId.trim() !== '') {
      this.pubnub.publish(
        {
          message: chatMessage,
          channel: `visit-inbound-doctor-${assistantId}`,
        },
        (status, response) => {
          if (status.error) {
            console.log('Doctor channel', status);
          }
          console.log('Doctor channel', response);
        }
      );
    }
    return chatMessage;
  }

  createBotMessage(message) {
    const { user, botChat } = store.getState();
    console.log(message, botChat);
    const chatMessage = {
      senderId: user.userId,
      userType: 'user',
      messageId: randomString(20),
      flowType: message.flowType || botChat.flowType,
      cardType: message.cardType || 'text',
      flowDirective: {
        entryPoint: message.entryPoint || botChat.flowDirective.entryPoint,
        ...message.flowDirective,
      },
      text: message.text,
      ...message,
    };
    return chatMessage;
  }

  createChatMessage(message, consultationId, urgentAssistance) {
    const { user } = store.getState();
    const chatMessage = {
      senderId: user.userId,
      name: user.userName,
      messageId: randomString(20),
      consultationId,
      platform: 'web-sdk',
      text: message.text || '',
      userType: 'patient',
      notificationType: 'chat',
      type: 'chat',
      urgentAssistance,
      cardType: message.cardType || 'text',
      messageType: message.messageType,
      ...message,
    };

    // Add notification payload
    const basePayloadCopy = Object.assign({}, chatMessage);
    chatMessage.pn_apns = {
      aps: basePayloadCopy,
    };

    chatMessage.pn_gcm = {
      data: basePayloadCopy,
    };

    return chatMessage;
  }

  history(options) {
    const { chat } = store.getState();
    return new Promise((resolve, reject) => {
      this.pubnub.history(options, (status, res) => {
        if (!status.error) {
          const { chatMessages } = chat;
          const historicalMessages = res.messages;
          const formattedMessages = [];
          historicalMessages.forEach(message => {
            formattedMessages.push({
              timetoken: message.timetoken / 10000,
              ...message.entry,
            });
          });
          console.log(formattedMessages);
          store.dispatch(
            setChatMessages(formattedMessages.concat(chatMessages))
          );
          resolve();
        } else {
          console.log(status);
          reject(status);
        }
      });
    });
  }
}

let client;

const initializeChatClient = () => {
  if (client) {
    return client;
  } else {
    const { user } = store.getState();
    const pubnubParams = {
      subscribeKey: config.pubnubSubscribeKey,
      publishKey: config.pubnubPublishKey,
      authKey: user.userUUID,
      uuid: `browser::${user.userUUID}`,
    };

    const pubnub = new PubNub(pubnubParams);

    client = new ChatClient(pubnub);
    return client;
  }
};

export { initializeChatClient };

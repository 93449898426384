import axios from 'axios';
import config from '../config';
import { showError } from '../utils';


export function uploadMaternityDocuments(requestData, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/subscription/upload-maternity-documents`, requestData, {
      headers
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getMaternityBenefitDetails(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/subscription/get-maternity-benefits`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.status === true) {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
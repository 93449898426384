import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';

import { BoldText } from './Text';

import homeIcon from '../../images/common/home-tab-icon.svg';
import homeActiveIcon from '../../images/common/home-active-tab-icon.svg';
import consultIcon from '../../images/common/consult-tab-icon.svg';
import consultActiveIcon from '../../images/common/consult-active-tab-icon.svg';
import subscriptionIcon from '../../images/common/insure-unselected.png';
import selectedSubscriptionIcon from '../../images/common/insure.png';
import accountIcon from '../../images/common/account-tab-icon.svg';
import accountActiveIcon from '../../images/common/account-active-tab-icon.svg';
import shopIcon from '../../images/common/shop-tab-icon.svg';
import shopActiveIcon from '../../images/common/shop-active-tab-icon.svg';
import membershipIcon from '../../images/policy/visit_membership.svg';
import selectedMembershipIcon from '../../images/policy/visit_membership.svg';

const FooterContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 13px 0.25rem 0 0.25rem;
`;

const TabImage = styled.img`
  height: 1.25rem;
`;

const MiddleTabImage = styled.img`
  height: 1.25rem;
  /* background-color: white;
  padding: 0.625rem;
  border-radius: 0.625rem; */
  overflow-y: auto;
  transform: translateY(-0.425rem) scale(1.5);
`;

const TabContainer = styled(Tappable)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &.Tappable-active {
    opacity: 0.5;
  }
  margin: 0 0.25rem;
`;

const TabText = styled(BoldText)`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${(props) =>
    props.active
      ? props.activeColor
        ? props.activeColor
        : '#4F34D2'
      : '#808080'};
  margin-top: 6px;
  margin-bottom: 6px;
`;

const tabs = [
  {
    title: 'Home',
    icon: homeIcon,
    activeIcon: homeActiveIcon,
    link: '/home',
  },
  {
    title: 'Care',
    icon: consultIcon,
    activeIcon: consultActiveIcon,
    link: '/home/consult',
  },
  {
    title: 'Insure',
    icon: subscriptionIcon,
    activeIcon: selectedSubscriptionIcon,
    link: '/home/insure',
  },
  // {
  //   title: 'Membership',
  //   icon: membershipIcon,
  //   activeIcon: selectedMembershipIcon,
  //   link: '/home/policy-purchased',
  // },
  {
    title: 'Redeem',
    icon: shopIcon,
    activeIcon: shopActiveIcon,
    link: '/home/rewards',
  },
  {
    title: 'Account',
    icon: accountIcon,
    activeIcon: accountActiveIcon,
    link: '/home/account',
  },
];

const Footer = ({ history, location, config }) => {
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  if (searchParams.get('hidetabs') === 'true') {
    return false;
  }
  function renderTab(ele, idx) {
    const { pathname } = this.history.location;
    const active = pathname === ele.link;
    if (ele.title === 'Insure') {
      if (config.insured || config.subscribed) {
        return (
          <TabContainer
            key={idx}
            active={active}
            onTap={() => this.history.replace(ele.link)}
          >
            <TabImage src={active ? ele.activeIcon : ele.icon} />
            <TabText active={pathname === ele.link}>
              {config.insureTabText || ele.title}
            </TabText>
          </TabContainer>
        );
      }
      return false;
    }
    if (ele.title === 'Care') {
      if (config && config.showCareTab) {
        return (
          <TabContainer
            key={idx}
            active={active}
            activeColor={ele.activeColor}
            onTap={() => this.history.push(ele.link)}
          >
            <TabImage src={active ? ele.activeIcon : ele.icon} />
            <TabText
              active={pathname === ele.link}
              activeColor={ele.activeColor}
            >
              {ele.title}
            </TabText>
          </TabContainer>
        );
      }
      return false;
    }
    // if (ele.title === 'Membership') {
    //   if (config.showSubscription || config.subscribed) {
    //     return (
    //       <TabContainer
    //         key={idx}
    //         active={active}
    //         onTap={() => this.history.replace(ele.link)}
    //       >
    //         <MiddleTabImage src={active ? ele.activeIcon : ele.icon} />
    //         <TabText active={pathname === ele.link}>{ele.title}</TabText>
    //       </TabContainer>
    //     );
    //   }
    //   return false;
    // }
    if (ele.title === 'Redeem') {
      if (config.showFitCoins) {
        return (
          <TabContainer
            key={idx}
            active={active}
            activeColor={ele.activeColor}
            onTap={() => this.history.push(ele.link)}
          >
            <TabImage src={active ? ele.activeIcon : ele.icon} />
            <TabText
              active={pathname === ele.link}
              activeColor={ele.activeColor}
            >
              {ele.title}
            </TabText>
          </TabContainer>
        );
      }
      return false;
    }
    return (
      <TabContainer
        key={idx}
        active={active}
        activeColor={ele.activeColor}
        onTap={() => this.history.push(ele.link)}
      >
        <TabImage src={active ? ele.activeIcon : ele.icon} />
        <TabText active={pathname === ele.link} activeColor={ele.activeColor}>
          {ele.title}
        </TabText>
      </TabContainer>
    );
  }
  return <FooterContainer>{tabs.map(renderTab, { history })}</FooterContainer>;
};

const mapStateToProps = (state) => ({
  config: state.app.config,
});

export default withRouter(connect(mapStateToProps)(Footer));

import React, { Component } from 'react';
import styled from 'styled-components';
import { IoMdSend } from 'react-icons/io';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import StyledTappable from '../common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  align-items: stretch;
  align-self: stretch;
`;

const InputTappable = styled(StyledTappable)`
  padding: 0 0.5rem;
`;

const InputBox = styled(Input)`
  padding: 0.75rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const SendIcon = styled(IoMdSend)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${props => (props.active ? '#8852CC' : 'rgba(0,0,0,0.5)')};
`;

class StringInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
    };
  }

  sendMessage = () => {
    const { chatMessage } = this.state;
    const { sendMessage } = this.props;
    const message = {
      text: chatMessage,
      cardType: 'text',
    };

    this.setState({ chatMessage: '' });
    sendMessage(message);
  };

  render() {
    const chatMessageLink = Link.state(this, 'chatMessage');
    const { chatMessage } = this.state;
    const { numeric } = this.props;
    return (
      <OuterContainer>
        <InputBox
          valueLink={chatMessageLink}
          type={numeric ? 'tel' : 'text'}
          className="visit-input"
          placeholder="Type your message here"
        />
        <InputTappable
          onTap={this.sendMessage}
          disabled={chatMessage.trim().length === 0}>
          <SendIcon active={chatMessage.trim().length !== 0} />
        </InputTappable>
      </OuterContainer>
    );
  }
}

export default StringInput;

import amplitude from 'amplitude-js';
import config from '../config';
import moment from 'moment';

const getUnixTime = (date) => {
  var momentDate = moment(date);
  if (momentDate.isValid()) {
    return new Date(date).getTime();
  }
  return new Date().getTime();
};

class AmpClient {
  constructor(amplitudeInstance) {
    this.amplitudeInstance = amplitudeInstance;
  }

  init() {
    this.amplitudeInstance.init(config.amplitudeApiKey, null, {
      includeUtm: true,
    });
  }

  initUser(userInfo) {
    if (userInfo && userInfo.userId) {
      this.amplitudeInstance.setUserId(userInfo.userId);
      let signupDate = new Date(userInfo.signupDate * 1000);
      // getMonth returns 0-11 (jan-dec)
      signupDate = `${signupDate.getFullYear()}-${
        signupDate.getMonth() + 1
      }-${signupDate.getDate()}`;
      this.amplitudeInstance.setUserProperties({
        gender: userInfo.userGender,
        Name: userInfo.userName,
        SponsorName: userInfo.sponsorName,
        walletBalance: userInfo.walletBalance,
        signupDate,
        version: config.version,
      });
      if (
        window.clevertap &&
        window.clevertap.onUserLogin &&
        window.clevertap.onUserLogin.push
      ) {
        window.clevertap.onUserLogin.push({
          Site: {
            Gender:
              userInfo.userGender !== null &&
              userInfo.userGender !== '' &&
              userInfo.userGender.length > 1
                ? userInfo.userGender.charAt(0).toUpperCase()
                : 'M',
            Name: userInfo.userName,
            SponsorName: userInfo.sponsorName,
            sponsorId: userInfo.sponsorId,
            signupDate:
              signupDate && new Date(signupDate)
                ? `$D_${new Date(signupDate).valueOf()}`
                : '',
            Identity: userInfo.userId,
            userId: userInfo.userId,
            version: config.version,
            Email: userInfo.userEmail,
            Phone: `+91${userInfo.userPhone}`,
            DOB: userInfo.dateOfBirth ? new Date(userInfo.dateOfBirth) : null,
          },
        });
      }
    }
  }

  logEvent(event, props) {
    this.amplitudeInstance.logEvent(event, props);
    if (window.clevertap) {
      window.clevertap.event.push(event, props || {});
    }
  }

  logoutUser() {
    this.amplitudeInstance.setUserId(null);
    this.amplitudeInstance.regenerateDeviceId();
  }
}

let client;

const initializeAmplitudeClient = () => {
  if (client) {
    return client;
  } else {
    const amplitudeInstance = amplitude.getInstance();

    client = new AmpClient(amplitudeInstance);
    return client;
  }
};

export { initializeAmplitudeClient };

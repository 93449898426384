import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdContentCopy } from 'react-icons/md';

import TappableCard from './TappableCard';
import StyledTappable from './StyledTappable';
import { FitcoinIcon } from '../../components/common/Fitcoin';
import { BlackText, Text, RegularText } from './Text';
import { PrimaryButton } from './Buttons';
import { fontSize } from '../../utils/font-size';

const RewardsCard = styled(TappableCard)`
  box-shadow: none;
  margin: 5.5px 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background: ${(props) =>
    props.bgColor ? `url(${props.bgColor})` : '#8852cc'};
  padding: 9px;
  padding-right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: ${(props) => (props.layout === 'fullwidth' ? '100%' : '44%')};
  flex: ${(props) => (props.layout === 'fullwidth' ? 'auto' : '')};
  height: ${(props) => (props.layout === 'fullwidth' ? '330px' : '215px')};
  border-radius: 8px;
  justify-content: flex-end;
`;

const RewardsBodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.layout === 'fullwidth' ? '16px' : '8px')};
`;

const RewardBrandIcon = styled.img`
  height: 3rem;
`;

const RewardBrandText = styled(Text)`
  font-size: ${(props) => (props.layout === 'fullwidth' ? '14px' : '12px')};
  font-weight: 600;
  line-height: 17px;
  color: white;
  text-shadow: 0px 2px 2px rgba(51, 51, 51, 0.2);
`;

const RewardTitle = styled(BlackText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${(props) => (props.color ? props.color : '#c6ac35')};
  background: ${(props) =>
    props.color ? '' : 'linear-gradient(45deg, #ede67b, #a37520)'};
  -webkit-background-clip: ${(props) => (props.color ? '' : 'text')};
  -webkit-text-fill-color: ${(props) => (props.color ? '' : 'transparent')};
`;

const RewardSubtitle = styled(RegularText)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${(props) => (props.color ? props.color : '#c6ac35')};
  background: ${(props) =>
    props.color ? '' : 'linear-gradient(45deg, #ede67b, #a37520)'};
  -webkit-background-clip: ${(props) => (props.color ? '' : 'text')};
  -webkit-text-fill-color: ${(props) => (props.color ? '' : 'transparent')};
`;

const RewardImage = styled.img`
  height: 8.5rem;
  width: 8.25rem;
  margin-left: 0.5rem;
`;

const RedeemedContainer = styled(StyledTappable)`
  border: 1px dashed black;
  padding: 0.75rem 1rem;
  margin-right: 1rem;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

const RedeemText = styled.input`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  background: none;
`;

const CopyIcon = styled(MdContentCopy)`
  font-size: ${() => `${1.25 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const RewardButton = styled.div`
  background: #303040;
  align-self: flex-start;
  border-radius: 22px;
  padding: 4px 0;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

const ButtonText = styled(BlackText)`
  color: ${(props) => (props.color ? props.color : '#c6ac35')};
  background: ${(props) =>
    props.color ? '' : 'linear-gradient(45deg, #ede67b, #a37520)'};
  -webkit-background-clip: ${(props) => (props.color ? '' : 'text')};
  -webkit-text-fill-color: ${(props) => (props.color ? '' : 'transparent')};
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
`;

const PurchaseDateText = styled(RegularText)`
  font-size: ${fontSize(0.75)};
  color: #fff;
  margin-top: 1.125rem;
`;

const RewardCard = (props) => {
  const {
    onTap,
    onPurchase,
    index,
    disabled,
    bgColor,
    logoUrl,
    productName,
    offerLabel,
    serviceImage,
    cost,
    couponCode,
    redeemed,
    purchaseDate,
    imageUrl,
    layout,
    title,
  } = props;
  return (
    <RewardsCard
      bgColor={imageUrl[0]}
      layout={layout}
      onTap={onTap}
      disabled={disabled}
    >
      <RewardsBodyContainer layout={layout}>
        <div>
          <RewardBrandText layout={layout}>
            {productName || title}
          </RewardBrandText>
          <RewardTitle>{offerLabel}</RewardTitle>
        </div>
      </RewardsBodyContainer>
      {redeemed ? (
        <>
          <RewardButton>
            <ButtonTextContainer>
              <ButtonText className="pl-10">{purchaseDate}</ButtonText>
            </ButtonTextContainer>
          </RewardButton>
        </>
      ) : (
        <RewardButton>
          <ButtonTextContainer>
            <FitcoinIcon />
            <ButtonText>{cost} FIT</ButtonText>
          </ButtonTextContainer>
        </RewardButton>
      )}
    </RewardsCard>
  );
};

RewardCard.propTypes = {
  onTap: PropTypes.func,
  onPurchase: PropTypes.func,
  disabled: PropTypes.bool,
  bgColor: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  offerLabel: PropTypes.string.isRequired,
  serviceImage: PropTypes.string,
  cost: PropTypes.number.isRequired,
  couponCode: PropTypes.string,
  redeemed: PropTypes.bool,
  purchaseDate: PropTypes.string,
};

RewardCard.defaultProps = {
  bgColor: '#8852cc',
  couponCode: '',
  redeemed: false,
  purchaseDate: '',
};

export default RewardCard;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';
import { RegularText, BoldText } from '../common/Text';
import StyledTappable from '../common/StyledTappable';
import conAssistant from '../../images/policy/con-assistant.svg';
import conOnline from '../../images/policy/con-online.svg';
import conOffline from '../../images/policy/con-offline.svg';
import conLabs from '../../images/policy/con-labs.svg';

const OuterContainer = styled.div`
  margin: 0rem 0.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: space-between;
  align-self: stretch;
`;

const Conatiner = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: stretch;
  justify-content: space-between;
`;

const TimeConatiner = styled(RegularText)`
  display: flex;
  flex: 1;
  margin-top: 0.25rem;
  color: #000;
`;

const VerticalSeperator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0.625rem);
  /* margin-bottom: -3.5rem; */
`;

const CircularSeperator = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.6rem;
  border: 0.05rem solid #818181;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const LineSeperator = styled.div`
  padding: 0.05rem;
  flex: 1;
  align-self: center;
  /* padding: 0.625rem; */
  background-color: RGBA(129, 129, 129, 0.3);
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  margin: 1.25rem;
  margin-top: 0;
  margin-right: 0rem;
  border: 0.05rem solid;
  border-color: RGBA(129, 129, 129, 0.3);
  border-radius: 0.875rem;
  justify-content: space-between;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 1rem;
`;

const TopLabel = styled(BoldText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #57c757;
`;

const BottomLabel = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #4d4d4d;
`;

const DoctorName = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #808080;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;
`;

const DoctorImage = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
`;

const TypeIcon = styled.img`
  width: 2rem;
  height: 2rem;
  margin: 0.625rem;
  resize: contain;
  flex-shrink: 0;
`;

const LoadMoreContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BottomArrow = styled(FaAngleDown)`
  width: 2rem;
  height: 2rem;
`;

class SavingsJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfElements: 0,
      isExpanded: false,
    };
    console.log({ props });
  }

  componentWillMount = () => {
    console.log({ length: this.props }, 'componentWillMount called');
    if (
      !this.state.isExpanded &&
      this.state.noOfElements !== this.props.details.length
    ) {
      console.log({ length: this.props.details });
      this.setState({
        noOfElements:
          this.props.details.length > 5 ? 5 : this.props.details.length,
      });
    }
  };

  loadMore = () => {
    let current = this.state.noOfElements;
    if (this.props.details.length > current + 10) {
      current += 10;
    } else {
      current = this.props.details.length;
    }
    this.setState({ noOfElements: current });
  };

  fetchTypeIcon = type => {
    switch (type) {
      case 'con-assistant':
        return conAssistant;
      case 'con-online':
        return conOnline;
      case 'con-offline':
        return conOffline;
      case 'con-labs':
        return conLabs;
      default:
        return conAssistant;
    }
  };

  render() {
    const renderSavings = () => {
      const elements = [];
      for (let i = 0; i < this.state.noOfElements; i++) {
        // const typeIcon = this.fetchTypeIcon(this.props.details[i].type);
        let statusColor;
        switch (this.props.details[i].requestStatus) {
          case 'pending':
            statusColor = '#fe823a';
            break;
          case 'rejected':
            statusColor = '#ff7448';
            break;
          case 'approved':
            statusColor = '#00b7b7';
            break;
          default:
            break;
        }
        elements.push(
          <Conatiner key={`card_${i}`}>
            <CardContainer>
              <CardDetails>
                <TopLabel>{this.props.details[i].reimbursementType}</TopLabel>
                <BottomLabel>
                  {this.props.details[i].reimbursementDate}
                </BottomLabel>
                <NameContainer>
                  {/* <DoctorImage src={this.props.details[i].profileImg} /> */}
                  <DoctorName>Reimbursement Status: </DoctorName>
                  <BoldText color={statusColor}>
                    {this.props.details[i].requestStatus.toUpperCase()}
                  </BoldText>
                </NameContainer>
              </CardDetails>
              {/* <TypeIcon src={typeIcon} /> */}
            </CardContainer>
          </Conatiner>,
        );
      }
      return elements;
    };
    return (
      <OuterContainer>
        {renderSavings()}
        {this.props.details.length > this.state.noOfElements ? (
          <LoadMoreContainer onTap={() => this.loadMore()}>
            <BottomArrow />
          </LoadMoreContainer>
        ) : null}
      </OuterContainer>
    );
  }
}

SavingsJourney.propTypes = {
  onTap: PropTypes.func,
};

export default SavingsJourney;

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import CardContainer from '../../components/common/CardContainer';
import {
  BoldText,
  RegularText,
  Text,
  LinkText,
} from '../../components/common/Text';
import {
  PrimaryButton,
  SubscriptionButton,
} from '../../components/common/Buttons';
import { FadeInTransition } from '../../components/transitions/transitions';

import corporateIcon from '../../images/onboarding/corporate-icon.svg';
import userIcon from '../../images/onboarding/user-icon.svg';
import { updateLoginState } from '../../actions';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
  padding-bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
`;

const BackIcon = styled(FaArrowLeft)`
  margin-bottom: 2rem;
`;

const HeaderText = styled(BoldText)`
  font-size: ${() => `${1.375 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const BodyContainer = styled.div`
  display: flex;
  padding: 1.25rem 0;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`;

const UserTypeContainer = styled(CardContainer)`
  display: flex;
  padding: 1rem 0.75rem 0.5rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  flex-shrink: 0;
`;

const UserTypeInnerContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: 0.875rem;
`;

const UserTypeIcon = styled.img`
  width: 3.75rem;
  margin-right: 0.625rem;
`;

const UserTypeText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
`;

const StyledRightArrow = styled(FaArrowRight)`
  color: white;
  margin-left: 0.5rem;
`;

const SelectUserType = props => {
  const { history, loginState, dispatch } = props;
  if (loginState === 'LOGGED_OUT') {
    history.push('/');
    return false;
  } else if (loginState === 'SPONSOR_EMAIL') {
    history.push('/corporate-onboard');
    return false;
  } else if (loginState === 'LOGGED_IN') {
    history.push('/home');
    return false;
  } else if (loginState === 'ONE_LAST_STEP') {
    history.push('/one-last-step');
    return false;
  } else {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Login Option Screen',
    });
    logEvent('Onboarding Login Option Screen')
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            <BackIcon />
            <HeaderText>How would you like to proceed?</HeaderText>
          </HeaderContainer>
          <BodyContainer>
            <UserTypeContainer>
              <UserTypeInnerContainer>
                <UserTypeIcon src={corporateIcon} />
                <UserTypeText>
                  Does your organization/corporate offer VISIT Health Benefits?
                </UserTypeText>
              </UserTypeInnerContainer>
              <SubscriptionButton
                onTap={() => {
                  history.push('/corporate-onboard');
                }}
              >
                <ButtonText>I am a Sponsored User</ButtonText>
              </SubscriptionButton>
            </UserTypeContainer>
            <UserTypeContainer>
              <UserTypeInnerContainer>
                <UserTypeIcon src={userIcon} />
                <UserTypeText>
                  Not a sponsored user? Continue using the VISIT App
                </UserTypeText>
              </UserTypeInnerContainer>
              <StyledPrimaryButton
                onTap={() => {
                  dispatch(updateLoginState('ONE_LAST_STEP'));
                  history.push('/one-last-step');
                }}
              >
                <ButtonText>Continue Signup</ButtonText>
                <StyledRightArrow />
              </StyledPrimaryButton>
            </UserTypeContainer>
          </BodyContainer>
          <RegularText>
            By continuing, you agree to our <LinkText>Terms</LinkText> &{' '}
            <LinkText>Policy</LinkText>
          </RegularText>
        </OuterContainer>
      </FadeInTransition>
    );
  }
};

const mapStateToProps = state => ({
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(SelectUserType);

export const defaultState = {
  chatMessages: [],
  chatChannel: null,
  chatName: null,
  receiverId: null,
  interviewId: null,
  flowType: '',
  flowDirective: {
    entryPoint: '',
    inputType: 'keyboardText',
    elements: [],
    keyboardHint: 'Enter your message here...',
  },
};

export default function botChat(state = defaultState, action) {
  switch (action.type) {
    case 'SET_BOT_CHAT_INFO':
      return Object.assign({}, state, {
        chatChannel: action.payload.chatChannel || state.chatChannel,
        chatName: action.payload.chatName || state.chatName,
        receiverId: action.payload.receiverId || state.receiverId,
        interviewId: action.payload.interviewId || state.interviewId,
      });
    case 'SET_BOT_CHAT_MESSAGES': {
      const { chatMessages } = action.payload;
      if (chatMessages.length === 0) {
        return state;
      }
      return Object.assign({}, state, {
        chatMessages: chatMessages,
        flowType:
          chatMessages[chatMessages.length - 1].flowType || state.flowType,
        flowDirective:
          chatMessages[chatMessages.length - 1].flowDirective ||
          state.flowDirective,
      });
    }
    case 'ADD_BOT_CHAT_MESSAGE':
      return Object.assign({}, state, {
        chatMessages: state.chatMessages.concat([action.payload.chatMessage]),
        flowType: action.payload.chatMessage.flowType || state.flowType,
        flowDirective:
          action.payload.chatMessage.flowDirective || state.flowDirective,
      });
    case 'RESET_BOT_CHAT':
      return Object.assign({}, defaultState, {
        chatMessages: [],
        flowDirective: defaultState.flowDirective,
      });
    default:
      return state;
  }
}

import React from 'react';
import styled from 'styled-components';

import {
  BoldText,
  ButtonText,
  RegularText,
  Text,
} from '../../components/common/Text';
import { PrimaryButton } from '../../components/common/Buttons';

import hraIcon from '../../images/hra/hraicon-1.png';
import { fontSize } from '../../utils';
import { connect } from 'react-redux';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Image = styled.img`
  max-width: 100px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(BoldText)`
  font-size: ${fontSize(1.8)};
`;

const CaptionText = styled(RegularText)`
  margin-top: 12px;
  font-size: ${fontSize(0.75)};
`;

const StartScreen = ({ submitResponse, config }) => {
  console.log(submitResponse);
  return (
    <OuterContainer className="font-mont">
      <div className="flex flex-col p-32px overflow-y-scroll flex-1 text-center justify-between">
        {config.hraLogo && config.showUpdatedHra ? (
          <div className="flex flex-row items-center justify-between m-x mr-12px">
            <img
              src={
                'https://api.getvisitapp.com/apiv3/sponsor-images/sponsor-77.png'
              }
              alt={'Visit Health'}
              style={{ width: '30%', maxWidth: '150px' }}
            />
            <img
              src={config.hraLogo}
              style={{ width: '45%', maxWidth: '250px' }}
            />
          </div>
        ) : (
          <div></div>
        )}
        <ColumnContainer>
          <img
            src="hra"
            src={hraIcon}
            className="mb-24px"
            style={{ height: '184px', width: '184px' }}
          />

          <p className="text-title-xl font-bold text-custom3a2ca0">
            Health Risk Assessment
          </p>
          <p
            className="text-body-l mt-16x font-medium text-txtlight"
            style={{
              maxWidth: '500px',
            }}
            id="hra-disclaimer"
          >
            {config.showUpdatedHra && config.hraDisclaimer ? (
              <p dangerouslySetInnerHTML={{ __html: config.hraDisclaimer }}></p>
            ) : (
              'Answer this quick 5 minute questionnaire to understand more about you and your health needs.'
            )}
          </p>
        </ColumnContainer>
        <ColumnContainer>
          <div
            className="bg-primary p-16px rounded-full self-center"
            style={{ width: '50%',background: '#714FFF'}}
            onClick={() => submitResponse('start')}
    
          >
            <ButtonText>Start Here</ButtonText>
          </div>
          <CaptionText>
            *The information that you provide will be treated with absolute
            confidentiality
          </CaptionText>
          {/* <div className="mt-16px flex flex-row items-center justify-center">
            <p className="text-txtlight font-semibold text-body-s text-center mr-8px">
              Powered by
            </p>
            <img
              src={
                'https://api.getvisitapp.com/apiv3/sponsor-images/sponsor-77.png'
              }
              alt={"Visit Health"}
              style={{ height: '16px' }}
            />
          </div> */}
        </ColumnContainer>
      </div>
    </OuterContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    config: state.app.config,
  };
};

export default connect(mapStateToProps)(StartScreen);

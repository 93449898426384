import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ClaimFeedback from './ClaimFeedback';
import ClaimsStart from './ClaimsStart';
import ClaimsSummary from './ClaimsSummary';
const ClaimsNavigator = ({ loginState, authToken, history, location }) => {
  if (loginState !== 'LOGGED_IN' || !authToken) {
    let params = null;
    if (location && location.search) {
      params = new URLSearchParams(location.search);
    }
    if (params && params.get('requestId')) {
      history.push(`/?rt=claims?requestId=${params.get('requestId')}`);
    } else {
      history.push('/');
    }
    return false;
  }
  return (
    <Switch>
      <Route exact path="/claims" component={ClaimsStart} />
      <Route exact path="/claims/summary" component={ClaimsSummary} />
      <Route exact path="/claims/feedback" component={ClaimFeedback} />
      <Redirect to="/claims" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ClaimsNavigator);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background: #f7f7f7;
  border-radius: 0.875rem;
`;

const StyledIframe = styled.iframe`
  border: none;
`;

const BotHtmlMessage = ({ link }) => (
  <OuterContainer>
    <BubbleContainer>
      <StyledIframe src={link} />
    </BubbleContainer>
  </OuterContainer>
);

BotHtmlMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default BotHtmlMessage;

// HOC to show
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const withAuth = Component => {
  const mapStateToProps = state => ({
    loginState: state.app.loginState,
    authToken: state.user.authToken,
  });

  const WrappedComponent = props => {
    const { loginState, authToken, history } = props;
    if (loginState !== 'LOGGED_IN' || !authToken) {
      history.push('/');
      return false;
    }
    return <Component {...props} />;
  };

  return withRouter(connect(mapStateToProps)(WrappedComponent));
};

export default withAuth;

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import MealLogContainer from './MealLogContainer';
import MealSelectContainer from './MealSelectContainer';
import LogMealContainer from './LogMealContainer';

const MealLogNavigator = ({ loginState, authToken, history }) => {
  if (loginState !== 'LOGGED_IN' || !authToken) {
    history.push('/');
    return false;
  }
  return (
    <Switch>
      <Route path="/logmeal" exact component={MealLogContainer} />
      <Route path="/logmeal/select" component={MealSelectContainer} />
      <Route path="/logmeal/log" component={LogMealContainer} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  loginState: state.app.loginState,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(MealLogNavigator);

import React from 'react'
import styled from 'styled-components';

import TappableCard from '../../components/common/TappableCard';
import Header from '../../components/common/Header';
import { Text } from '../../components/common/Text';

import breakfastIcon from '../../images/meal-log/breakfast.svg';
import morningsnacksIcon from '../../images/meal-log/morningsnacks.svg';
import lunchIcon from '../../images/meal-log/lunch.svg';
import eveningsnacksIcon from '../../images/meal-log/eveningsnacks.svg';
import dinnerIcon from '../../images/meal-log/dinner.svg';
import { fontSize } from '../../utils';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 16px;
`;

const TitleText = styled(Text)`
  font-size: 16px;
  margin-bottom: 16px;
`;

const StyledTappableCard = styled(TappableCard)`
  flex-direction: row;
  align-items: center;
  padding: 12px;
  margin: 10px 0;
`;

const MealIcon = styled.img`
  width: 20px;
  height: 28px;
  margin-right: 14px;
`;

const MealText = styled(Text)`
  font-size: 14px;
`;

const MealSelectContainer = ({ history, location }) => {
  const selectMeal = (mealType) => {
    history.replace('/logmeal/log', {
      mealType,
      dayTimestamp: location.state.dayTimestamp,
    });
  };
  return (
    <OuterContainer>
      <Header color="#56c02c" title="Log Meal" />
      <BodyContainer>
        <TitleText>Which do you want to log?</TitleText>
        <StyledTappableCard onTap={() => selectMeal('Breakfast')}>
          <MealIcon src={breakfastIcon} />
          <MealText>Breakfast</MealText>
        </StyledTappableCard>
        <StyledTappableCard onTap={() => selectMeal('Morning Snacks')}>
          <MealIcon src={morningsnacksIcon} />
          <MealText>Morning Snacks</MealText>
        </StyledTappableCard>
        <StyledTappableCard onTap={() => selectMeal('Lunch')}>
          <MealIcon src={lunchIcon} />
          <MealText>Lunch</MealText>
        </StyledTappableCard>
        <StyledTappableCard onTap={() => selectMeal('Evening Snacks')}>
          <MealIcon src={eveningsnacksIcon} />
          <MealText>Evening Snacks</MealText>
        </StyledTappableCard>
        <StyledTappableCard onTap={() => selectMeal('Dinner')}>
          <MealIcon src={dinnerIcon} />
          <MealText>Dinner</MealText>
        </StyledTappableCard>
      </BodyContainer>
    </OuterContainer>
  );
};

export default MealSelectContainer;

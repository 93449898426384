import React from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

export const ExpandTransition = ({ children, ...props }) => (
  <CSSTransition {...props} timeout={300} classNames="expand">
    {children}
  </CSSTransition>
);

ExpandTransition.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FadeInTransition = ({ children, timeout, ...props }) => (
  <CSSTransition {...props} timeout={timeout} classNames="fade-in">
    {children}
  </CSSTransition>
);

// FadeInTransition.propTypes = {
//   children: PropTypes.node.isRequired,
//   timeout: PropTypes.number.isRequired,
// };

export const FadeTransition = ({ children, timeout, ...props }) => (
  <CSSTransition {...props} timeout={timeout} classNames="fade">
    {children}
  </CSSTransition>
);

FadeTransition.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number.isRequired,
};

export const SlideTransition = ({ children, classNames, ...props }) => (
  <CSSTransition {...props} timeout={200} classNames={classNames}>
    {children}
  </CSSTransition>
);

SlideTransition.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string.isRequired,
};

export const FallTransition = ({ children, classNames, ...props }) => (
  <CSSTransition {...props} timeout={400} classNames={classNames}>
    {children}
  </CSSTransition>
);

FallTransition.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string.isRequired,
};

export const FlyInTransition = ({ children, timeout, appear, ...props }) => (
  <CSSTransition
    {...props}
    timeout={timeout || 200}
    classNames="fly-in"
    appear={appear}
  >
    {children}
  </CSSTransition>
);

FlyInTransition.propTypes = {
  children: PropTypes.node.isRequired,
  appear: PropTypes.bool.isRequired,
};
